import React from "react";
import "./MobileHead.scss";
import { withRouter } from 'react-router-dom';
import { ReactComponent as LeftArrow } from "../../assets/icons/left-arrow.svg";

class MobileHead extends React.Component {
    constructor(props) {
        super(props);
        this.unlisten = null;
    }
    componentDidMount() {
        this.unlisten = this.props.history.listen(listen => {
            // console.log('listen', listen);
        });
    }
    componentWillUnmount() {
        if (this.unlisten) this.unlisten();
    }
    render() {
        return (
            <div className="mobile-head">
                {/* <NavLink to="/"><LeftArrow /></NavLink> */}
                <LeftArrow onClick={this.props.history.goBack} />
            </div>
        );
    }
}

export default withRouter(MobileHead);
