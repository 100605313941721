import React from "react";
import "./MobileAbout.scss";
import MobileHead from "../MobileHead";
import About from "../../cms/About.js";

class MobileAbout extends React.Component {
    render() {
        return (
            <>
                <MobileHead />
                <div className="m-legal-wrapper legal-wrapper">
                    <About />
                </div>
            </>
        );
    }
}

export default MobileAbout;
