import React from "react";
import "./ChatLine.scss";
import UserImg from "../../Leaderboard/UserImg/UserImg";
import moment from "moment";

export default class ChatLine extends React.Component {
    constructor(props){
        super(props);
        this.messagesEnd = null;
        this.state = {
            time: moment(this.props.timestamp).fromNow()
        };
    }
    
    componentDidMount() {
        this.interval = setInterval(() => this.setState({ time: moment(this.props.timestamp).fromNow() }), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }
    render() {
        let { message, isUser, user, author} = this.props;
        return (
            <div className={(isUser === true) ? "chatline user" : "chatline help"}>
                {(isUser === true) ? <UserImg className="agentImage" picUrl={user.photo} userName={user.name}/> :
                    <UserImg isChat={true} className="agentImage" userName={author.name}/>}
                <div className="chatline--message">
                    <p>{message}</p>
                    <span>{this.state.time.toUpperCase()}</span>
                </div>
            </div>
        );
    }
}
