import "./UserDial.scss";

import React from "react";

import Dial from "../Dial";
import ModuleError from "../../ModuleError";

const UserDial = (props) => {
    const {
        campaign,
        company,
        currentScore,
        onClick = () => {},
        onRefresh = () => {},
        userScoreError = false,
    } = props;

    return (
        <div className="dial-wrapper">
            <h1>{campaign?.campaignName || "Campaign"}</h1>
            {userScoreError ? (
                <div className="dial">
                    <ModuleError onRefresh={onRefresh} />
                </div>
            ) : (
                <Dial
                    onClick={onClick}
                    challengepts={company}
                    campaign={campaign}
                    currpts={currentScore}
                />
            )}
        </div>
    );
};

export default UserDial;
