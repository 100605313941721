import React from "react";
import "./MobileHome.scss";
import { APIContext, httpVerb } from "../../context/APIProvider";
import { USER_SCORE } from "../../services/endpoints/endpoints";
import UserDial from "../Leaderboard/UserDial";
import { NavLink } from 'react-router-dom';
import { RightArrow } from "../../assets/icons/icons";
import { isStaticEnvironment } from "../../utils/reusableFunctions";

class MobileHome extends React.Component {
    static contextType = APIContext;
    constructor(props) {
        super(props);
        this.unlisten = null;
        this.mount = true;
        this.state = {
            userScoreError: false,
            current_score: 0
        }
    }

    componentDidMount() {
        this.getUserScore();
        this.unlisten = this.props.history.listen();
    }

    getUserScore = () => {
        if (isStaticEnvironment()) {
            return;
        }

        this.context.httpRequest(USER_SCORE, httpVerb.GET).then(res => {
            if (!res || (res.success && res.success === 'false')) {
                if (this.mount) this.setState({ userScoreError: true });

                return;
            }
                
            if (this.mount) this.setState({ current_score: res.current_score || 0, userScoreError: false });
        })
    }

    componentWillUnmount() {
        if (this.unlisten) this.unlisten();
        this.mount = false;
    }

    render() {
        const { current_score, userScoreError } = this.state;
        const { campaign, company } = this.context;
        
        return (
            <div className="mobile-home">
                <h3 className="page-head">Amplifier</h3>
                <UserDial
                    userScoreError={userScoreError} onRefresh={this.getUserScore}
                    onClick={null} company={company} campaign={campaign}
                    currentScore={current_score}
                />
                <ul className="mobile-nav">
                    <NavLink to="/my-actions"><li><p>My Actions</p> <RightArrow /></li></NavLink>
                    <NavLink to="/all-actions"><li><p>All Actions</p> <RightArrow /></li></NavLink>
                    <NavLink to="/leaderboard"><li><p>Leaderboard</p> <RightArrow /></li></NavLink>
                    <NavLink to="/scroing-rules"><li><p>Scoring Rules</p> <RightArrow /></li></NavLink>
                    <NavLink to="/amplifier-help"><li><p>Amplifier Help</p> <RightArrow /></li></NavLink>
                </ul>                
                <NavLink to="/legal"><p className="legal-link">Legal</p></NavLink>
            </div>
        );
    }
}

export default MobileHome;
