import React from "react";
import "./ModalContent.scss";
import ShortcutCard from "../../Shortcuts/ShortcutCard";
import Dial from "../../Leaderboard/Dial";
import { APIContext } from "../../../context/APIProvider";
import { BASE_URL } from "../../../services/process/env";
import { decodeToken } from "../../../utils/Cookies";
import { postMessage } from "../../../utils/reusableFunctions";

class NewUser extends React.Component {
    static contextType = APIContext;
    constructor(props) {
        super(props);

        this.timeOut = null;
        this.state = {
            token: null,
        };
    }

    componentDidMount() {
        postMessage("loaded: ready:amp version date(6/15/22)");
        this.checkProvisioning();
    }

    checkProvisioning = () => {
        const { partialToken } = this.context;
        if (this.timeOut) {
            clearTimeout(this.timeOut);
        }
        fetch(
            `${BASE_URL}/auth/sso/user/token/check?X-PARTIAL-TOKEN=${partialToken}`,
            {
                headers: { "Content-Type": "application/json" },
            },
        )
            .then((res) => res.json())
            .then((res) => {
                if (res.status === "UserId provisioning complete") {
                    this.setState({ token: res.token });
                } else {
                    this.timeOut = setTimeout(() => {
                        this.checkProvisioning();
                    }, 30000);
                }
            })
            .catch((err) => {
                return { api: "partial token", error: err };
            });
    };

    startChallenge = () => {
        const { token } = this.state;
        const decodedToken = decodeToken(token);

        if (token) {
            sessionStorage.setItem("token", token);
            if (decodedToken) {
                this.context.setTokens({ token, decodedToken });
            }
        }
    };

    render() {
        return (
            <div className="newuser">
                <h1 className="title">Welcome to Amplifier</h1>
                {this.state.token ? (
                    <StartPage start={this.startChallenge} />
                ) : (
                    <section className="progress-bar-wrapper">
                        <p className="verticalFlip">
                            Give us a few minutes, we are building your
                            <span>challenge.</span>
                            <span>actions.</span>
                            <span>leaderboard.</span>
                            <span>rules.</span>
                            <span>amplifier.</span>
                        </p>
                        <svg className="spinner" viewBox="0 0 50 50">
                            <circle
                                className="path"
                                cx="25"
                                cy="25"
                                r="20"
                                fill="none"
                                strokeWidth="5"
                            ></circle>
                        </svg>
                    </section>
                )}
            </div>
        );
    }
}

const StartPage = (props) => {
    return (
        <React.Fragment>
            <p className="copy">Learn and get work done by using Actions.</p>
            <p className="copy">
                Complete Actions to get points and reach your Challenge Period
                Goal.
            </p>
            <section className="centersection">
                <div className="demo">
                    <div className="shortcutdemo">
                        <ShortcutCard title={"Create a contact"} points={5} />
                        <p className="absolute subtext">
                            Learn while doing with guided walk-throughs
                        </p>
                    </div>

                    <svg
                        width="71px"
                        height="15px"
                        className="initalArrow"
                        viewBox="0 0 71 15"
                        version="1.1"
                    >
                        <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                        >
                            <g
                                transform="translate(-504.000000, -357.000000)"
                                fill="#979797"
                                fillRule="nonzero"
                            >
                                <g transform="translate(176.000000, 137.000000)">
                                    <g transform="translate(130.000000, 162.000000)">
                                        <path d="M255,66.5 L198,66.5 L198,64.5 L255,64.5 L255,58.5 L269,65.5 L255,72.5 L255,66.5 Z"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>

                    <div className="dialdemo">
                        <Dial challengepts={800} currpts={500} />
                        <p className="absolute subtext">
                            Compete with your peers with role-based scoring
                        </p>
                    </div>
                </div>
            </section>
            <section className="modalfooter">
                <button className="btn btn__primary" onClick={props.start}>
                    START THE CHALLENGE
                </button>
            </section>
        </React.Fragment>
    );
};

export default NewUser;
