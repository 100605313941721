import React from "react";
import "./ChatFooter.scss";
import { SendArrow } from "../../../assets/icons/icons";
const ChatFooter = (props) => (
    <div className="flex-footer">
        <form className="chat--form" onSubmit={props.submit}>
            <input className ="w-100" 
                placeholder="Type something&#8230;" 
                onChange={props.onchange} 
                value={props.value}
            />
            <button className="btn btn__primary m-l-20 relative sendArrow">SEND <SendArrow/></button>
        </form>
    </div>
);

export default ChatFooter;
