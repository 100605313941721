import React from "react";
import "./MobileTerms.scss";
import MobileHead from "../MobileHead";
import TOS from "../../cms/TOS.js";

class MobileTerms extends React.Component {
    render() {
        return (
            <>
                <MobileHead />
                <div className="m-legal-wrapper legal-wrapper">
                    <TOS isMobile={true}/>
                </div>
            </>
        );
    }
}

export default MobileTerms;
