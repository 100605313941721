import "./Dial.scss";

import React from "react";

import CurrentScore from "../CurrentScore";
import Svg from "../Svg";
import Completion from "../Completion";
import Skeleton from "../../Skeleton";

import { formatChallengeDates } from "../../../utils/reusableFunctions";

export default class Dial extends React.Component {
    constructor(props) {
        super(props);
        this.dial = React.createRef();
    }

    getDashoffset = (currpts, challengepts, length) => {
        const numerater = challengepts - currpts;
        if (isNaN((challengepts - currpts) / challengepts)) {
            return length;
        }
        return numerater < 0
            ? length
            : length * ((challengepts - currpts) / challengepts);
    };

    gameDuration = () => {
        const { campaign } = this.props;
        if (campaign && campaign.campaignUTCStart && campaign.campaignUTCEnd) {
            const { startDateFormat, endDateFormat, daysLeft } =
                formatChallengeDates(
                    campaign.campaignUTCStart,
                    campaign.campaignUTCEnd,
                ) || {};

            return (
                <>
                    <p className="ch-countdown">{daysLeft}</p>
                    <p className="ch-duration">
                        {startDateFormat} - {endDateFormat}
                    </p>
                </>
            );
        }
    };

    render() {
        const lengthOfDial = 639.447;
        const challengeComplete =
            (this.props.challengepts - this.props.currpts) /
                this.props.challengepts <
            0;
        const dashOffset = this.getDashoffset(
            this.props.currpts,
            this.props.challengepts,
            lengthOfDial,
        );
        
        return (
            <div className="dial selectable" onClick={this.props.onClick}>
                <Svg>
                    <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                        transform="translate(-33.000000, -33.000000)"
                    >
                        <g ref={this.dial} id={!this.props.noId && "dial"}>
                            <path
                                d="M163.352941,281 C227.27417,281 281,227.27417 281,163.352941 C281,94.72583 227.27417,41 163.352941,41 C94.72583,41 41,94.72583 41,163.352941 C41,188.825833 49.5319718,212.679637 64.1389117,232.159538"
                                id={!this.props.noId && "stroke"}
                                strokeWidth="2"
                                transform="translate(161.000000, 161.000000) scale(1, -1) rotate(154.000000) translate(-161.000000, -161.000000) "
                                className="bg"
                                stroke="#CECECD"
                            />
                            <path
                                d="M163.352941,281 C227.27417,281 281,227.27417 281,163.352941 C281,94.72583 227.27417,41 163.352941,41 C94.72583,41 41,94.72583 41,163.352941 C41,188.825833 49.5319718,212.679637 64.1389117,232.159538"
                                id={!this.props.noId && "bluestroke"}
                                strokeWidth="14"
                                strokeLinecap="round"
                                transform="translate(161.000000, 161.000000) scale(1, -1) rotate(154.000000) translate(-161.000000, -161.000000) "
                                className="meter"
                                strokeDasharray={lengthOfDial}
                                strokeDashoffset={dashOffset}
                            />
                        </g>
                    </g>
                    {challengeComplete ? (
                        ""
                    ) : (
                        <CurrentScore
                            currpts={this.props.currpts}
                            id={!this.props.noId && "amplifier-userPoints"}
                        />
                    )}
                    <foreignObject
                        x="2"
                        y={challengeComplete ? "10%" : "62%"}
                        width="100%"
                        height={challengeComplete ? "250" : "40"}
                    >
                        {this.props.challengepts === null ||
                        this.props.challengepts === undefined ? (
                            <Skeleton
                                className="dial--challengeScore dial--challengeScore__skeleton"
                                height="7px"
                                width="110px"
                            >
                                <Skeleton height="10px" width="60px" />
                            </Skeleton>
                        ) : (
                            <Completion
                                noId={this.props.noId}
                                challengepts={this.props.challengepts}
                                challengecomplete={challengeComplete}
                            />
                        )}
                    </foreignObject>
                </Svg>
                {this.gameDuration()}
            </div>
        );
    }
}
