import "./UserImg.scss";

import React from "react";

import { User } from "../../../assets/icons/icons";
import { ChatPlaceholder, getNameInitials } from "./helper";

export default class UserImg extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            picError: null,
        };
    }

    handleError = () => {
        this.setState({ picError: true });
    };

    render() {
        const { picUrl, isChat, userName } = this.props;

        if (!picUrl || this.state.picError) {
            return (
                <>
                    {isChat ? (
                        <ChatPlaceholder
                            className={"userImg " + this.props.className}
                            userName={getNameInitials(userName)}
                        />
                    ) : (
                        <User />
                    )}
                </>
            );
        }

        return (
            <img
                src={picUrl}
                onError={this.handleError}
                className={"userImg " + this.props.className}
                alt="User's profile pic"
            />
        );
    }
}
