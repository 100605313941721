import React from "react";
import "./NoHelp.scss";
// import API from "../../../utils/API";
import { APIContext, httpVerb } from "../../../context/APIProvider";

class NoHelp extends React.Component {
    static contextType = APIContext;
    constructor() {
        super();
        this.state = {
            chatAvailable: false,
            apiResponse: false
        };
    }

    componentDidMount() {
        this.context.snowHttpRequest(`/api/x_pwcm2_aiq_chat_1/chat/queue_available`, httpVerb.POST, {
            "company": this.props.company,
            "platform": "Amplifier " + this.props.platform,
            "userId": this.props.userId
        }).then(res => {
            // console.log(res, "res");
            this.setState({
                chatAvailable: res,
                apiResponse: true
            });
        });
        // API.getChatAvailability({
        //     "company": this.props.company,
        //     "platform": "Amplifier " + this.props.platform,
        //     "userId": this.props.userId
        // }).then(res => {
        //     // console.log(res, "res");
        //     this.setState({
        //         chatAvailable: res
        //     });
        // });
    }
    render() {
        return (
            <div className="nohelp nohelp--fadein">
                { this.state.apiResponse &&
                <div>
                    <p>Hmmm… it seems we don’t have an answer for you.</p>
                    <p>Submit your question{(this.props.isAdmin && this.state.chatAvailable && (this.context.isChatEnabled === 'Y')) ? " or chat with a Amplifier help team member." :  "."}</p>      
                    <button
                        onClick={this.props.showSubmission}
                        className="btn btn__primary btn__mt">
                        {"Submit".toUpperCase()}
                    </button>

                    { this.props.isAdmin && this.state.chatAvailable && (this.context.isChatEnabled === 'Y') &&
                        <button
                            onClick={this.props.showChat}
                            className={"btn btn__light btn__mt btn__ml"}>{"CHAT"}
                        </button>
                    }
                </div> 
                }
            </div>
        );
    }
}

export default NoHelp;