import { postMessage } from "./reusableFunctions";

/**
 * Loads a guide based on the provided properties or opens a modal if the guide information is not available.
 *
 * @param {Object} props - The properties object containing guide information and other optional data.
 * @param {Object} props.data - The guide data containing 'guideId', 'guideValue', and other optional properties.
 * @param {number} [props.data.guideId] - The ID representing the type of guide.
 * @param {string} [props.data.guideValue] - The value associated with the guide.
 * @param {string} [props.data.name] - The name associated with the guide.
 * @param {number} [props.data.id] - The ID associated with the guide.
 * @param {Object} props.parent - The parent component that manages the state.
 *
 * @example
 * const guideProperties = {
 *   data: { guideId: 1, guideValue: "walkme", name: "WalkMe", id: 123 },
 *   parent: myComponent,
 * };
 * loadGuide(guideProperties);
 * // Loads the WalkMe guide with the provided data or opens a modal if the guide information is not available.
 */
const loadGuide = (props = {}) => {
    try {
        const { data, parent } = props;
        const { guideId, guideValue, name, id } = data;

        const guideMap = {
            1: "Walkme",
            2: "Pendo",
            3: "oracleGuideName",
            4: "jumpSeatName",
            DEFAULT: "oracleGuideName",
        };

        const guideData = {
            to: guideMap[guideId] || guideMap.DEFAULT,
            id: guideValue,
            pid: id,
        };

        if (guideId && guideValue) {
            postMessage(guideData);
            return true;
        }

        parent.setState({
            modalShortcutName: name,
            shortcutModal: !parent.state.shortcutModal,
        });

        return false;
    } catch (e) {
        alert(`loadGuide error: (${e.message})`);
        return null;
    }
};

export { loadGuide };
