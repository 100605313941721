import "./ModalWindow.scss";

import React from "react";
import { createPortal } from "react-dom";
import FocusTrap from "focus-trap-react";
import { isMobile } from "react-device-detect";

const ModalWindow = ({ onEscapeModal, children, className }) => {
    const modal = React.useRef(null);
    
    const handleKeyDown = (event) => {
        if (
            event.type === "keydown" &&
            event.key === "Escape" &&
            onEscapeModal
        ) {
            onEscapeModal();
        }
    };

    const fallbackFocus = (event) => {
        return modal.current;
    };

    return createPortal(
        <FocusTrap focusTrapOptions={{ fallbackFocus }}>
            <div
                className={`modal-container ${className || ""}`}
                onKeyDown={handleKeyDown}
            >
                <div
                    role="dialog"
                    tabIndex="-1"
                    ref={modal}
                    aria-modal="true"
                    className={
                        isMobile
                            ? "mobile-view  modal-content"
                            : "modal-content"
                    }
                >
                    {children}
                </div>
            </div>
        </FocusTrap>,
        document.body,
    );
};

export default ModalWindow;
