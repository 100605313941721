import { massageBreakdown } from "../../../utils/APIHelper";
import { postMessage } from "../../../utils/reusableFunctions";

/**
 * handleHttpResponse
 * @description Handles HTTP response, updating state and triggering a callback if successful.
 * @param {Object} $this - The context or "this" value for the function.
 * @param {Object} response - The HTTP response object.
 * @param {Object} errorObject - The state object to set in case of an error.
 * @param {Function} [callback=() => {}] - Optional callback function to execute on successful response.
 * @returns {boolean}
 */
const handleHttpResponse = (
    $this,
    response,
    errorObject,
    callback = () => {},
) => {
    if (!response || (response.success && response.success === "false")) {
        $this.setState(errorObject, () => {
            postMessage("loaded: ready:amp version date(6/15/22)");
        });

        return false;
    }

    callback && callback($this, response);
    return true;
};

/**
 * handleScoreResponse
 * @description Handles a score-related HTTP response, updating the state.
 * @param {Object} $this - The context or "this" value for the function.
 * @param {Object} response - The HTTP response object.
 * @returns {void}
 */
const handleScoreResponse = ($this, response) => {
    $this.checkCurrentScore(response.current_score);
    $this.setState({
        current_score: response.current_score,
        userScoreError: false,
    });
};

/**
 * handleBreakdownResponse
 * @description Handles a breakdown-related HTTP response, updating the state and triggering a callback.
 * @param {Object} $this - The context or "this" value for the function.
 * @param {Object} response - The HTTP response object.
 * @returns {void}
 */
const handleBreakdownResponse = ($this, response) => {
    $this.setState(
        {
            breakdown: massageBreakdown(response),
            userBreakdownError: false,
        },
        () => {
            postMessage("loaded: ready:amp version date(6/15/22)");
        },
    );
};

export { handleHttpResponse, handleScoreResponse, handleBreakdownResponse };
