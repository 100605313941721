import "./HelpChat.scss";

import React from "react";
import moment from "moment";

import ChatHeader from "../ChatHeader";
import Chat from "../Chat";
import ChatFooter from "../ChatFooter/ChatFooter";

import { APIContext, httpVerb } from "../../../context/APIProvider";

class HelpChat extends React.Component {
    static contextType = APIContext;
    constructor(props){
        super(props);
        this.state = {
            chatInputValue: "Type your message here...",
            chatFeed : [],
            groupId: null,
            poll: null,
            agentName: "Amplifier help desk",
            chatTimeElapsed: null
        };
    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.timer = null;
        clearInterval(this.coutDownTimer);
        this.coutDownTimer = null;
    }

    componentDidMount() {
        this.startchat(this.props.firstMessage);
    }
      
    pollMessage = () => {
        this.timer = setInterval(this.getMessages, 5000);
    }

    handleMessageResponse = (message) => {
        const newMessage = message.map((mapMessage) => {
            return {
                isUser: (mapMessage.author.name === "AIQ Service Account"),
                message: mapMessage.message,
                timestamp: new Date(mapMessage.timestamp+" UTC").toISOString(),
                author: mapMessage.author
            };
        });
        if(this.state.chatFeed.toString() === newMessage.toString()) {
            return;
        }
        if(newMessage.length > 2){
            clearInterval(this.coutDownTimer);
            this.coutDownTimer = null;
        }

        this.setState({
            chatFeed: newMessage
        });
        return;
    }

    getMessages = () => {
        this.context.getNewMessage(this.state.groupId.group).then(message => {
            return this.handleMessageResponse(message);
        });
    }

    handleSubmit = (newLine) => {
        this.setState({
            chatFeed: [...this.state.chatFeed, newLine],
            chatInputValue: ""
        });
    }

    submit = (e) => {
        e.preventDefault();
        if(this.state.chatInputValue.trim().length > 0){
            const newChatLine = {
                isUser: true,
                message: this.state.chatInputValue,
                timestamp: new Date().toISOString()
            };
            this.context.snowHttpRequest(`/api/x_pwcm2_aiq_chat_1/chat/send_message`, httpVerb.POST, {
                groupId: this.state.groupId.group,
                message: this.state.chatInputValue
            }).then(() => {
                this.handleSubmit(newChatLine);
            });
        }   
    }

    change = (e) => {
        this.setState({
            chatInputValue: e.target.value
        });
    }

    startchat = (message) => {
        const newChatLine = {
            isUser: true,
            message: message,
            timestamp: new Date().toISOString()
        };

        //Take user data and add message to it
        const userDetails = this.props.userDetails;
        userDetails.name = `User Submitted question at ${newChatLine.timestamp}`;
        userDetails.short_description = newChatLine.message;
        userDetails.platform = "Amplifier " + userDetails.platform;

        //TODO - This needs current user information
        let groupId = this.props.groupId;
        if(!groupId){
            this.context.snowHttpRequest(`/api/x_pwcm2_aiq_chat_1/chat/create`, httpVerb.POST, userDetails)
                .then(res => {
                    this.setState({
                        groupId: res.groupId,
                        chatInputValue: "",
                        chatFeed: [newChatLine],
                    }, () => {
                        this.props.setGroupID(res.groupId);
                        this.pollMessage();
                        this.startCountDown(true);
                    });

                });
        } else {
            this.setState({
                groupId: groupId,
                chatInputValue: ""
            }, ()=>{
                this.getMessages();
                this.pollMessage();
            });
        }  
    }

    startCountDown = () => {
        let duration = moment.duration({
            "minutes": 0,
            "seconds": 0
        });
        let interval = 1;
        if(!this.props.isActive){
            this.coutDownTimer = setInterval(() => {
                duration = moment.duration(duration.asSeconds() + interval, "seconds");
                let min = duration.minutes();
                let sec = duration.seconds();
                sec += 1;
                if (sec > 59) {
                    min += 1;
                    sec = 0;
                } 
                if (sec < 10 && sec.length !== 2) sec = "0" + sec;
                if (min < 10 && min.length !== 2) min = "0" + min;
                this.setState({
                    chatTimeElapsed: min + ":" + sec
                });
            }, 1000);
        }
    }

    closeChat = ()=>{
        this.context.snowHttpRequest(`/api/x_pwcm2_aiq_chat_1/chat/end_chat`, httpVerb.POST, { "groupId": this.state.groupId.group })
            .then(() => {
                this.props.close();
            }, () => { this.props.close(); });
    }

    render(){
        return (
            <div className="helpchat">
                {(this.state.chatFeed.length > 1) ? (
                    <>
                        <ChatHeader close={this.closeChat} agentName={this.state.agentName}/>
                        <Chat chatFeed={this.state.chatFeed} userName={this.props.userDetails.userName}/>
                        <ChatFooter onchange={this.change} 
                            value={this.state.chatInputValue} 
                            submit={this.submit}
                        />
                    </>
                ) : (
                    <div className="waitBanner">
                        <div>
                            <div>
                                <p>An amplifier team member will be with you shortly.</p>
                                {this.state.chatTimeElapsed && 
                                    <p className="time-elapsed">Chat has been open for {this.state.chatTimeElapsed} minutes.</p>
                                }
                            </div>

                            <button onClick={() => this.closeChat()} className="btn btn__light">Close chat</button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default HelpChat;