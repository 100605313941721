import React from "react";
import "./CurrentScore.scss";
import { leaderboardCopy } from "../../../utils/cms";

// TODO: NEED TO CLEAN
const CurrentScore = (props) => {
    //case for user has null points (loading)
    if (props.currpts === null) {
        return (<React.Fragment>
            <rect className="skeleton--rect" y={"90%"} x={"85%"} height={"28px"} width={"80px"} />
        </React.Fragment>);
        //default case
    } else {
        return (
            <>
                <React.Fragment>
                    <text className="svg--userScore__big" x={"50%"} y={"52%"} id={props.id ? props.id : undefined}>{props.currpts}</text>
                    <text className="svg--userScore__small" x={"50%"} y={"59%"}>{leaderboardCopy.pointsSubHeader.toUpperCase()}</text>
                </React.Fragment>
            </>
        );
    }
};

export default CurrentScore;