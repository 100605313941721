import React from "react";

const TOS = () => (
    <>
        <p>
            <em>Last Updated</em>: June 30, 2022
        </p>
        <h3>
            <strong>PricewaterhouseCoopers</strong>
        </h3>
        <h4>
            <strong>
                Standard Hosted Product License Terms and Conditions
            </strong>
        </h4>
        <p>
            These Standard Hosted Product License Terms and Conditions (
            <span className="legal-bold-italic">
                &ldquo;Standard Terms&rdquo;
            </span>
            ) apply to products made available by the PricewaterhouseCoopers
            entity set forth on the ordering document (
            <span className="legal-bold-italic">&rdquo;Order&rdquo;</span>) into
            which these Standard Terms are incorporated.{" "}
            <span className="legal-bold-italic">&rdquo;PwC&rdquo;</span> means
            the PricewaterhouseCoopers entity named on the Order, and{" "}
            <span className="legal-bold-italic">&rdquo;Client&rdquo;</span>{" "}
            means the client entity named on the Order. The{" "}
            <span className="legal-bold-italic">&rdquo;Agreement&rdquo;</span>{" "}
            means the applicable Order and these Standard Terms (including
            materials incorporated by reference).
        </p>
        <p>
            Pursuant to the Agreement, Client may access and use the offering
            described on an Order, together with Documentation and Software
            Services described here or in the Order (collectively, the{" "}
            <span className="legal-bold-italic">&rdquo;Offering&rdquo;</span>).
            Capitalized terms are defined in <u>Section 14</u> or elsewhere in
            the Agreement.
        </p>
        <ol className="legal-nested-counter-list">
            <li>
                <h1>
                    <strong> OFFERING; LICENSE AND RESTRICTIONS</strong>
                </h1>
                <ol>
                    <li>
                        <strong>Delivery; License Grant.</strong> Subject to the
                        Agreement and during the Term set forth on the
                        applicable Order: (a) PwC will make the Offering set
                        forth on the Order and described on the product overview
                        accessible via the link to the specific Offering posted
                        at{" "}
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://productcentral.products.pwc.com/us/en/products.html"
                        >
                            https://productcentral.products.pwc.com/us/en/products.html
                        </a>{" "}
                        (the{" "}
                        <span className="legal-bold-italic">
                            &rdquo;Offering Overview&rdquo;
                        </span>
                        ) available to Client and its Users; and (b) PwC hereby
                        grants to Client a non-exclusive, non-transferable,
                        non-assignable license to access and use the Offering in
                        accordance with the Agreement and the Documentation for
                        Client&apos;s non-commercial, internal business
                        operations.
                    </li>
                    <li>
                        <strong>Client Parties.</strong> Client may permit other
                        Client Parties to access and use the Offering on behalf
                        of, but only for the benefit of, Client. Client is
                        responsible and liable for all acts and omissions of
                        Client Parties as if such acts and omissions were made
                        directly by Client.
                    </li>
                    <li>
                        <strong>User Accounts.</strong> PwC will establish (or
                        empower Client to establish) User Accounts, where
                        applicable. Client may not exceed limitations or
                        restrictions on User Accounts or Users. Client is
                        responsible for protecting User Account information and
                        will comply with User Account security requirements that
                        PwC reasonably designates. Client will (and will cause
                        each other Client Party and User to) access and use the
                        Offering in accordance with applicable laws, rules,
                        regulations, obligations to third parties, and the
                        Agreement. Promptly upon becoming aware of a User&apos;s
                        non-compliance, Client will notify PwC and take
                        appropriate steps to remedy it (including terminating
                        the User&apos;s access). PwC may monitor access and use
                        of the Offering by Client Parties and Users. Client is
                        fully responsible and liable for unauthorized use of
                        User Accounts.
                    </li>
                    <li>
                        <strong>Restrictions.</strong> The Offering may be
                        accessed and used only through the URL set forth on the
                        Offering Overview or Order (or as otherwise designated
                        by PwC), and only with valid User Accounts. Except as
                        otherwise expressly provided elsewhere in the Agreement,
                        Client will not, and will not permit others to: (a)
                        access or use the Offering other than as expressly
                        permitted under the Agreement and the Documentation, and
                        in accordance with applicable laws; (b) sell, lease,
                        sublicense, assign, transfer, or otherwise distribute or
                        make available any part of the Offering or access
                        thereto to any third party, or attempt to do so; (c)
                        reproduce, create derivative works of, or otherwise
                        modify any part of the Offering; (d) extract ideas,
                        algorithms, procedures, workflows, or hierarchies from
                        any part of the Offering; reverse engineer, decompile,
                        disassemble or otherwise attempt to extract any source
                        code; or otherwise use any part of the Offering to
                        develop another product or service; (e) remove any
                        disclaimer, copyright, trademark, confidentiality, or
                        other legal notice from any copies of the Offering; (f)
                        use any part of the Offering for any fraudulent or
                        unlawful purpose or in any manner that would violate the
                        legal rights of any third party; or in any stock trading
                        or financial transaction processing; or in any High-Risk
                        Activities; (g) circumvent, bypass, or breach any
                        technological controls, usage restrictions, or security
                        device or protection used by any part of the Offering,
                        or otherwise gain access to any service, device, data,
                        or account; or interfere with, impair a third
                        party&apos;s use of, or disrupt any part of the Offering
                        or the servers, software, systems, data, or networks
                        used to make the Offering available, or knowingly
                        violate any requirements, policies, or terms or
                        conditions of any providers thereof; (h) provide any
                        means of access to, or otherwise display or transmit,
                        any part of the Offering from or through any website
                        (including by{" "}
                        <span className="legal-bold-italic">
                            &rdquo;framing&rdquo;
                        </span>{" "}
                        or{" "}
                        <span className="legal-bold-italic">
                            &rdquo;mirroring&rdquo;
                        </span>
                        ) other than the designated URL; (i) perform, or release
                        the results of, benchmark tests or other comparisons of
                        the Offering with other software, platforms, or
                        materials; (j) permit any part of the Offering to be
                        used for or in connection with any facility management,
                        service bureau, or time-sharing purposes, services, or
                        arrangements, or otherwise used for processing data or
                        other information on behalf of any third party; (k)
                        transmit, store, run, or otherwise make available any
                        spam, virus, worm, Trojan horse, time bomb, spyware,
                        ransomware, malware, or other harmful or potentially
                        harmful code, scripts, agents, or other programs or
                        materials; or transmit any Client Materials that are
                        obscene, offensive, or indecent; (l) permit any
                        individual that is an employee or contractor of any
                        entity that provides an offering similar to the Offering
                        or is otherwise a competitor of PwC (including any
                        entity affiliated with Deloitte Touche Tohmatsu Limited,
                        EY, or KPMG) to access or use the Offering; (m) make any
                        representations to Users or other third parties on
                        behalf of PwC without PwC&apos;s prior written consent,
                        or that are not substantiated; (n) use the Offering for
                        or in connection with a United States government
                        contract, or permit access or use by any agency or
                        department of the United States; or (o) use the Offering
                        in a manner that would require the Offering to be
                        distributed or made available for free, or in source
                        code form, or under open source license terms.
                    </li>
                </ol>
            </li>
            <li>
                <h1>
                    <strong>SOFTWARE SERVICES; RESPONSIBILITIES.</strong>
                </h1>
                &nbsp;As part of the Offering and during the Term, PwC will
                provide the various services outlined below (
                <span className="legal-bold-italic">
                    &rdquo;Software Services&rdquo;
                </span>
                ). Any professional, consulting, or other services requires a
                separate&nbsp; agreement.
                <ol>
                    <li>
                        <strong>Hosting.</strong> PwC will (a) host, maintain
                        and make the Offering available pursuant to the
                        Agreement; and (b) employ reasonable administrative,
                        technical, and physical safeguards consistent with
                        industry standards intended to protect Client Data
                        maintained on and processed by the Offering.
                    </li>
                    <li>
                        <strong>Maintenance and Support.</strong> PwC will
                        provide maintenance and support services for the
                        Software as described on the maintenance and support
                        program accessible via the link to the specific Offering
                        posted at &nbsp;
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://productcentral.products.pwc.com/us/en/products.html"
                        >
                            https://productcentral.products.pwc.com/us/en/products.html
                        </a>
                        .
                    </li>
                    <li>
                        <strong>Activation.</strong> If the Offering Overview
                        describes any initial activation services, PwC will
                        provide those services.
                    </li>
                    <li>
                        <strong>Client&apos;s Responsibilities.</strong> Client
                        will reasonably cooperate with PwC, including providing
                        necessary information, instructions, and access to
                        Client Materials. PwC is not responsible for delays or
                        inability to perform resulting from Client&apos;s delay
                        or failure to provide cooperation. Client will obtain
                        and maintain (at its own expense) all Client Systems and
                        other Client Materials necessary to access and use the
                        Offering in accordance with any minimum applicable
                        requirements PwC may provide, including as set forth on
                        the Offering Overview. Where PwC must access or use
                        Client Materials to perform its obligations, Client is
                        responsible for ensuring that such access and use is
                        authorized by the owner/licensor of the applicable
                        Client Materials. Without limiting the foregoing, Client
                        represents and warrants that PwC will not be required to
                        execute any agreements directly with Client&apos;s
                        third-party providers as a condition of such access or
                        use, except as expressly set forth in an Order.
                    </li>
                    <li>
                        <strong>PwC Providers.</strong> PwC is a firm in the
                        global network of separate and independent
                        PricewaterhouseCoopers firms (collectively with their
                        Affiliates,{" "}
                        <span className="legal-bold-italic">
                            &rdquo;Network Firms&rdquo;
                        </span>
                        ). PwC may procure Third-Party Materials from third
                        parties, and may leverage other Network Firms or third
                        parties, to fulfill its obligations under the Agreement
                        (each supplier, Network Firm, or third party, a{" "}
                        <span className="legal-bold-italic">
                            &rdquo;PwC Provider&rdquo;
                        </span>
                        ). PwC may change PwC Providers in its discretion, but
                        is responsible to Client for their acts and omissions.
                    </li>
                </ol>
            </li>
            <li>
                <h1>
                    <strong>FINANCIAL TERMS.</strong>
                </h1>
                &nbsp;Client will pay PwC the amounts set forth on each Order in
                US dollars and in accordance with the payment terms set forth
                thereon. Fees are exclusive of taxes, tariffs, and similar
                charges, other than taxes imposed or based on PwC&apos;s net
                income. Client will not deduct or withhold taxes or other
                amounts.
            </li>
            <li>
                <h1>
                    <strong>TERM AND TERMINATION</strong>
                </h1>
                <ol>
                    <li>
                        <strong>Term; Breach.</strong> The term during which the
                        Offering will be made available (including any renewals)
                        is described on the applicable Order (
                        <span className="legal-bold-italic">
                            &rdquo;Term&rdquo;
                        </span>
                        ). Either party may terminate an Order if the other
                        party breaches the Agreement and fails to cure the
                        breach within thirty (30) days of receipt of written
                        notice. PwC may terminate any Order upon notice to
                        Client (a) if Client breaches, or causes PwC to breach,
                        applicable third-party terms and conditions, or (b) if
                        any Third-Party Materials that are material to the
                        provision of the Offering become unavailable for any
                        reason, and PwC cannot procure an alternative in a
                        timely manner or on commercially reasonable terms.
                    </li>
                    <li>
                        <strong>
                            Termination for Regulatory or Professional Standard.
                        </strong>{" "}
                        PwC may immediately terminate any Order without penalty
                        or liability if continuing to perform could result in
                        PwC&apos;s noncompliance with applicable law, rule, or
                        regulation; or any regulatory guidance, independence
                        rule, professional standard, or self-regulatory rule or
                        policy.
                    </li>
                    <li>
                        <strong>Suspension.</strong> PwC may suspend or restrict
                        access to the Offering where PwC has the right to
                        terminate, or if: (a) PwC reasonably believes that a
                        Client Party or User has violated the Agreement or any
                        applicable law; (b) PwC reasonably believes it is
                        necessary to protect the systems, infrastructure, data,
                        or information of PwC, the PwC Providers, or other
                        Network Firms, or their respective third-party providers
                        or customers, from a denial of service attack, security
                        breach, or similar event; (c) after 15 days&rsquo;
                        notice if fees are not paid when due; (d) requested by
                        law enforcement, government agency, or similar
                        authority; (e) a party fails to cooperate with PwC to
                        investigate suspected violations of the Agreement; or
                        (f) any Third-Party Materials that are material to the
                        provision of the Offering become unavailable for any
                        reason and PwC cannot procure an alternative in a timely
                        manner or on commercially reasonable terms. PwC will
                        endeavor to limit the suspension to the minimum time and
                        extent reasonably required to prevent or cure the issue.
                    </li>
                    <li>
                        <strong>Effect of Termination; Survival.</strong>{" "}
                        Immediately upon termination: all amounts owed to PwC
                        under the Order will be due and payable; the licenses
                        and PwC&apos;s obligation to provide the Offering will
                        end; and Client Parties and Users must discontinue use
                        of the Offering. Except as necessary for a party to
                        continue to exercise its surviving rights, and subject
                        to <u>Section 6</u>, each party will return or destroy
                        (or in the case of electronic materials, erase) all
                        documents and other embodiments (in any form) that
                        contain or constitute the other&apos;s Confidential
                        Information. <u>Sections 1.4,</u> <u>3</u>, <u>4.4</u>,{" "}
                        <u>5</u>, <u>6</u>, <u>7</u>, <u>8.3</u>, <u>9</u>{" "}
                        through <u>13</u>, and each party&apos;s accrued rights
                        or liabilities, will survive expiration or termination.
                    </li>
                </ol>
            </li>
            <li>
                <h1>
                    <strong>OWNERSHIP AND IP.</strong>
                </h1>
                &nbsp;As between the parties, Client owns and will retain all
                right, title, and interest (including all IP Rights) in and to
                the Client Materials.
                <ul className="legal-nested-list">
                    <li>
                        As between the parties, PwC owns and will retain all
                        right, title, and interest (including all IP Rights) in
                        and to PwC Confidential Information, the Offering, and
                        all related materials and derivative works of the
                        foregoing.
                    </li>
                    <li>
                        The Offering includes certain Third-Party Materials,
                        including as set forth in the Offering Overview or the
                        Order. These may include open source software.
                        Client&apos;s use of Third-Party Materials may be
                        subject to additional terms and conditions (as may be
                        modified from time to time), including as set forth in
                        the Offering Overview, the Order, or within the
                        Offering.
                    </li>
                    <li>
                        The Offering is licensed, not sold. There are no implied
                        licenses; PwC and its suppliers reserve all rights not
                        expressly granted in the Agreement.
                    </li>
                    <li>
                        Client hereby grants to PwC and the PwC Providers the
                        right to collect, reproduce, distribute, modify, make
                        derivative works of, perform, display, transmit, and
                        otherwise use (a) the Client Materials in connection
                        with operating and improving the Offering and performing
                        Software Services; and (b) the Client Data in anonymized
                        and aggregated form for analytic, statistical, security,
                        quality control, and similar purposes, and to test,
                        improve, and provide the Offering and other products and
                        services.
                    </li>
                    <li>
                        Client, on behalf of the Client Parties and Users,
                        hereby grants to PwC a worldwide, nonexclusive,
                        royalty-free, transferable, sublicenseable (through one
                        or more tiers), perpetual, and irrevocable license to
                        use and otherwise exploit any suggestions, comments,
                        ideas, test results, and other feedback provided to PwC
                        in any manner that PwC deems necessary or desirable.
                    </li>
                </ul>
            </li>
            <li>
                <h1>
                    <strong>CONFIDENTIALITY</strong>
                </h1>
                <ol>
                    <li>
                        <span className="legal-bold-italic">
                            &rdquo;Confidential Information&rdquo;
                        </span>{" "}
                        means, with respect to a disclosing party (
                        <span className="legal-bold-italic">
                            &rdquo;Discloser&rdquo;
                        </span>
                        ), all non-public information, data, and material
                        disclosed in any form to the other party (
                        <span className="legal-bold-italic">
                            &rdquo;Recipient&rdquo;
                        </span>
                        ) that is marked or described as, contains content that
                        would reasonably be considered to be, or is provided
                        under circumstances reasonably indicating it is,
                        confidential or proprietary. PwC&apos;s Confidential
                        Information includes the Agreement, the Offering, and
                        non-public information and materials related to them.
                    </li>
                    <li>
                        <strong>Obligations.</strong> Recipient will not use,
                        disseminate, or disclose the Discloser&apos;s
                        Confidential Information in any manner, except as
                        permitted under the Agreement and as necessary to
                        exercise its rights and perform its obligations under
                        the Agreement. Recipient may disclose the
                        Discloser&apos;s Confidential Information to its
                        employees, to other Client Parties (in the case of
                        Client), and to PwC Providers and designees (in the case
                        of PwC), where such parties have a legitimate need to
                        know such information in furtherance of the Agreement.
                        Recipient will protect the Discloser&apos;s Confidential
                        Information with the same degree of care as it accords
                        to its own information of a similar nature, and in any
                        event no less than reasonable care. Client represents,
                        warrants, and covenants to PwC that each of the other
                        Client Parties is (or prior to receipt of any PwC
                        Confidential Information, will be) subject to
                        confidentiality terms no less protective of PwC&apos;s
                        Confidential Information than those in the Agreement.
                    </li>
                    <li>
                        <strong>Exclusions.</strong> Recipient&apos;s
                        obligations with respect to Discloser&apos;s
                        Confidential Information will terminate as to an item
                        that: (a) is or becomes generally available to the
                        public through no fault of Recipient; (b) Recipient
                        already rightfully possesses at the time of disclosure
                        without restriction on use or disclosure; or (c) is
                        rightfully received from a third party without
                        restriction on use or disclosure. In addition, Recipient
                        may disclose the Discloser&apos;s Confidential
                        Information if required by applicable law, professional
                        standard, or any government authority (subject to
                        providing the Discloser prompt notice (to the extent
                        permitted by applicable law) and cooperating with
                        Discloser&apos;s attempts to limit the disclosure).
                    </li>
                    <li>
                        <strong>Additional Understandings.</strong>{" "}
                        Notwithstanding the above restrictions on disclosure,
                        but otherwise subject to the confidentiality provisions
                        of the Agreement, Client may discuss information
                        (including PwC Confidential Information), or disclose
                        other materials relating to Client&apos;s use of the
                        Offering with other Client Parties and Professional
                        Advisors (defined below) with the understanding that PwC
                        provides no services and prepares no work product or
                        other materials for their use, benefit, or reliance, and
                        that PwC assumes no duty, liability, or responsibility
                        with respect to such parties, or any use or reliance by
                        them. The Client Parties and Professional Advisors may
                        not further disclose such materials to any third party.{" "}
                        <span className="legal-bold-italic">
                            &rdquo;Professional Advisor&rdquo;
                        </span>{" "}
                        means Client&apos;s third-party professional advisors
                        who (a) are acting solely for Client&apos;s benefit and
                        on Client&apos;s behalf; (b) have a need to know certain
                        information (or access such work product or other
                        materials) to provide advice or services to Client; and
                        (c) are subject to written obligations with Client
                        regarding the protection of PwC&apos;s Confidential
                        Information and IP Rights that are materially consistent
                        with those in the Agreement. &rdquo;Professional
                        Advisors&rdquo; may include auditors, attorneys, and
                        financial and other similar advisors, but exclude
                        parties that provide insurance, financing, capital in
                        any form, or fairness opinions; parties that sell or
                        underwrite securities in connection with a transaction
                        relating to the subject matter hereof; any counterparty
                        to an anticipated transaction or dispute; and parties
                        that have or may obtain a financial interest in Client
                        or an Affiliate, or parties to an anticipated
                        transaction with Client or an Affiliate.
                    </li>
                    <li>
                        <strong>Return; Breach.</strong> Upon Discloser&apos;s
                        request, Recipient will return or destroy (or erase, in
                        the case of electronic materials) all copies of all
                        materials that contain or embody Discloser&apos;s
                        Confidential Information, except that (a) Recipient may
                        retain materials to the limited extent necessary to
                        exercise its rights and perform its obligations under
                        the Agreement, or as required by applicable law or
                        professional standard; and (b) the parties acknowledge
                        that some residual Confidential Information may remain
                        in system backup media (e.g., backup files, deletion
                        logs, archival tapes) or other information systems; all
                        such materials may be retained until destroyed in
                        accordance with Recipient&apos;s regular business
                        processes, but Recipient may not otherwise use them, and
                        they remain subject to the obligations of the Agreement.
                        Any actual or threatened breach of these confidentiality
                        obligations may result in irreparable harm to Discloser
                        with no adequate remedy at law, for which Discloser will
                        be entitled to seek appropriate equitable relief (in
                        addition to other remedies).
                    </li>
                </ol>
            </li>
            <li>
                <h1>
                    <strong>PRIVACY AND DATA.</strong>
                </h1>
                &nbsp;Each party will comply (and cause its agents to comply)
                with all obligations under data protection and privacy laws,
                rules, and regulations applicable to its activities under the
                Agreement. Client will ensure it has made all notifications
                regarding, and has obtained all necessary consents (including
                those required to process personal information of individuals
                whose personal data is included in Client Materials, and to
                provide such information to subprocessors) for the processing
                and use of Client Materials as contemplated in the Agreement.
                Each party will comply (and cause its agents to comply) with the
                requirements of the data processing addendum for the Offering
                accessible via the link to the specific Offering posted at
                &nbsp;
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://productcentral.products.pwc.com/us/en/products.html"
                >
                    https://productcentral.products.pwc.com/us/en/products.html
                </a>{" "}
                with respect to Personal Data (as defined therein). Other than
                Personal Data as contemplated above, Client Parties and Users
                will not input or upload onto the Offering, or otherwise
                provide, any protected health information, confidential
                supervisory information, payment card information, facial
                recognition, critical infrastructure information, or other
                regulated information, unless expressly and unambiguously set
                forth on the Offering Overview or Order. PwC will use reasonable
                efforts to comply with a request by Client to delete Client
                Materials stored on the Offering, subject to the Offering
                Overview, applicable laws, and professional standards.
            </li>
            <li>
                <h1>
                    <strong>REPRESENTATIONS AND WARRANTIES; DISCLAIMER</strong>
                </h1>
                <ol>
                    <li>
                        <strong>By PwC.</strong> PwC represents and warrants to
                        Client that the PwC Software, in the form made available
                        to Client and when accessed and used in accordance with
                        the Agreement, will function in all material respects as
                        set forth in the Documentation.
                    </li>
                    <li>
                        <strong>By Client.</strong> Client represents, warrants,
                        and covenants that: (a) Client Parties have and will
                        have all rights and licenses necessary to provide the
                        Client Materials to PwC and to grant PwC the rights and
                        licenses in the Agreement; (b) the Client Materials do
                        not and will not infringe the IP Rights or other rights
                        of any third party; (c) each Client Party has obtained
                        from its licensors of Client Systems (and during the
                        Term will maintain) all rights necessary for PwC to
                        access and use Client Systems to perform under the
                        Agreement; (d) Client has the authority to bind the
                        other Client Parties and Users and make statements on
                        their behalf as provided in the Agreement; and (e) the
                        Client signatory has the requisite power and authority
                        to execute and deliver the Order on behalf of Client.
                    </li>
                    <li>
                        <strong>Disclaimer.</strong> TO THE FULLEST EXTENT
                        ALLOWED BY APPLICABLE LAW, EXCEPT AS SPECIFICALLY SET
                        FORTH IN <u>SECTION 8.1</u>, THE OFFERING IS PROVIDED
                        &lsquo;AS-IS.&rsquo; PWC AND THE PWC PROVIDERS MAKE NO
                        (AND HEREBY DISCLAIM ALL) REPRESENTATIONS OR WARRANTIES
                        TO ANY PARTY, WHETHER EXPRESS, IMPLIED, OR STATUTORY,
                        INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                        NON-INFRINGEMENT, OR ACCURACY, AND THOSE ARISING FROM
                        COURSE OF DEALING OR PERFORMANCE, OR USAGE OF TRADE. PWC
                        DOES NOT REPRESENT OR WARRANT THAT ACCESS TO OR USE OF
                        THE OFFERING WILL BE UNINTERRUPTED, ERROR-FREE, OR
                        SECURE; OR THAT CLIENT MATERIALS WILL NOT BE SUBJECT TO
                        DAMAGE, CORRUPTION, DESTRUCTION, LOSS, OR DELETION. ALL
                        RESULTS, ANALYSES, AND OUTPUTS GENERATED BY THE OFFERING
                        ARE RELIANT ON CLIENT&apos;S PROVISION OF ACCURATE
                        INFORMATION. PWC IS NOT RESPONSIBLE FOR INACCURATE OR
                        INCOMPLETE RESULTS, ANALYSES, OR OTHER INFORMATION
                        GENERATED BY THE OFFERING, AND PWC MAKES NO GUARANTEE
                        REGARDING THEIR USE, IMPLEMENTATION, OR DEPLOYMENT BY
                        CLIENT PARTIES. PWC DISCLAIMS ALL LIABILITY (INCLUDING
                        DEATH AND BODILY INJURY) RELATED TO DECISIONS, ACTIONS,
                        OR OMISSIONS TAKEN OR NOT TAKEN BY ANY PARTY IN
                        CONNECTION WITH THE USE OF THE OFFERING. THE OFFERING IS
                        NOT DESIGNED, MANUFACTURED, OR INTENDED FOR HIGH-RISK
                        ACTIVITIES. CLIENT MAY NOT MAKE ANY REPRESENTATIONS,
                        WARRANTIES, COVENANTS, OR OTHER PROMISES REGARDING THE
                        OFFERING TO ANY CLIENT PARTY, USER OR THIRD PARTY; IF
                        CLIENT DOES SO, PWC EXPRESSLY DISCLAIMS THEM.
                    </li>
                </ol>
            </li>
            <li>
                <h1>
                    <strong>INDEMNIFICATION</strong>
                </h1>
                <ol>
                    <li>
                        <strong>By PwC.</strong> PwC will indemnify Client and
                        its directors, officers, employees, successors, and
                        assigns, from and against amounts payable under any
                        judgment, verdict, court order, or settlement to the
                        extent related to a claim brought by a third party
                        (excluding Affiliates, Authorized Contractors,
                        Professional Advisors, or Users) that the PwC Software,
                        in the form made available to Client and when accessed
                        and used in accordance with the Agreement, infringes
                        that third party&apos;s copyright, trade secret, patent,
                        or U.S. trademark or trade name.
                    </li>
                    <ul className="legal-nested-list">
                        <li>
                            PwC will have no obligation for any of the
                            following: (i) the claim relates to a modification
                            of the Offering by or at the direction of a Client
                            Party; (ii) a Client Party or User uses the Offering
                            in a manner inconsistent with the Documentation or
                            the Agreement, or in combination with a product or
                            service not provided by PwC (including any Client
                            Materials or Client Systems); (iii) the claim
                            relates to Client Data, other Client Materials, or
                            specifications or instructions provided by or at the
                            direction of a Client Party or User, or the
                            provision of regulated information in breach of{" "}
                            <u>Section 7</u>; (iv) the claim relates to breach
                            of the Agreement by a Client Party or User; or (v) a
                            Client Party or User fails to follow the directions
                            of PwC or PwC Providers.
                        </li>
                        <li>
                            If the Offering is adjudged to be infringing or
                            misappropriating, or its use is enjoined, or if PwC
                            reasonably believes the Offering is or may become
                            the subject of a claim relating to IP Rights, PwC
                            will (at its expense) either procure the right for
                            Client to continue using the Offering, or replace or
                            modify the affected component of the Offering with a
                            non-infringing component of similar functionality.
                            If neither option is available on a commercially
                            reasonable basis, then PwC may terminate the Order.
                            In such case, PwC will refund to Client any prepaid
                            fees for the then-current initial or renewal term of
                            the Order, prorated for the remainder of the
                            then-current initial or renewal term. THIS{" "}
                            <u>SECTION 9.1</u> STATES Client&apos;s SOLE AND
                            EXCLUSIVE REMEDY, AND PWC&apos;s ENTIRE LIABILITY
                            AND OBLIGATION, WITH RESPECT TO ANY CLAIMS, ACTIONS,
                            AND CAUSES OF ACTION ARISING OUT OF OR RELATED TO
                            INFRINGEMENT OF THIRD-PARTY IP RIGHTS. PWC HAS NO
                            LIABILITY UNDER THE AGREEMENT TO ANY OTHER PARTY.
                        </li>
                    </ul>
                    <li>
                        <strong>By Client.</strong> Client will indemnify PwC,
                        the Beneficiaries, and their respective partners,
                        principals, directors, officers, employees, successors
                        and assigns, from and against amounts payable under any
                        judgment, verdict, court order, or settlement (a)
                        relating to claims brought by a third party relating to
                        Client Materials; and (b) relating to claims relating to
                        a breach by a Client Party or User of the terms,
                        restrictions, or obligations applicable to the Offering,
                        including as set forth on the Order and the Offering
                        Overview.
                    </li>
                    <li>
                        <strong>Process.</strong> The party seeking
                        indemnification (
                        <span className="legal-bold-italic">
                            &rdquo;Indemnitee&rdquo;
                        </span>
                        ) must give prompt notice to the other party (
                        <span className="legal-bold-italic">
                            &rdquo;Indemnitor&rdquo;
                        </span>
                        ) of any claim for which indemnity is sought, but
                        failure to do so will only relieve the Indemnitor of its
                        obligations to the extent the Indemnitor is materially
                        prejudiced thereby. If the Indemnitor is required to
                        defend the claim, it will promptly assume the defense
                        (and related settlement discussions) at its expense; if
                        it fails to do so, the Indemnitee may undertake the
                        defense. The Indemnitee must cooperate with the
                        Indemnitor and may participate and appear with the
                        Indemnitor in the claim at its own expense with its own
                        legal counsel. The Indemnitor will not settle a claim
                        that does not fully release the Indemnitee from
                        liability without the Indemnitee&apos;s prior written
                        consent (which may not be unreasonably withheld,
                        delayed, or conditioned).
                    </li>
                </ol>
            </li>
            <li>
                <h1>
                    <strong>LIMITATION OF LIABILITY.</strong>
                </h1>
                &nbsp;TO THE FULLEST EXTENT ALLOWED BY APPLICABLE LAW: (A)
                EXCEPT FOR OBLIGATIONS UNDER <u>SECTION 9</u> OR BREACHES BY A
                CLIENT PARTY OF <u>SECTION 1</u>, IN NO EVENT WILL A PARTY BE
                LIABLE FOR ANY LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF USE OR
                DATA, INTERRUPTION OF BUSINESS, OR FOR ANY INDIRECT, SPECIAL,
                INCIDENTAL, STATUTORY, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY
                KIND, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND
                (B) EXCEPT FOR <u>SECTION 9</u>, IN NO EVENT WILL EITHER
                PARTY&apos;s TOTAL AGGREGATE LIABILITY TO THE OTHER RELATED TO
                AN ORDER EXCEED AN AMOUNT EQUAL TO THE GREATER OF (I) THE
                AGGREGATE AMOUNTS PAID BY CLIENT FOR THAT ORDER IN THE TWELVE
                (12) MONTHS PRECEDING THE FIRST EVENT GIVING RISE TO LIABILITY,
                AND (II) ONE THOUSAND US DOLLARS (US$1,000); IN EACH CASE,
                REGARDLESS OF THE FORM OF ANY CLAIM OR ACTION (WHETHER STATUTORY
                OR COMMON LAW, AND WHETHER SOUNDING IN NEGLIGENCE, STRICT
                LIABILITY, OR OTHERWISE).
            </li>
            <li>
                <h1>
                    <strong>REGULATORY MATTERS.</strong>
                </h1>
                &nbsp;The Agreement is nonexclusive.
                <ul className="legal-nested-list">
                    <li>
                        The Offering is not suitable for maintaining master or
                        original documents or copies of Client Materials. Client
                        is responsible for maintaining master copies and
                        appropriate backups. PwC is not responsible for the
                        content, accuracy, or completeness of Client Materials,
                        for the deletion of Client Materials, or for any use of
                        Client Materials by a third party other than the PwC
                        Providers.
                    </li>
                    <li>
                        PwC will not provide any audit or attest opinion or
                        other form of assurance, and PwC will not verify or
                        audit any information, data, or materials (including
                        Client Materials) provided to it. The Agreement is not
                        an offer to provide, and PwC is not engaged in
                        rendering, legal, accounting, tax, financial, or other
                        advice or services that would otherwise be provided by a
                        certified professional; the Offering is not a substitute
                        for consulting with appropriate professional services
                        providers before taking any action or decision in
                        reliance on reports, results, or other information
                        resulting from use of the Offering.
                    </li>
                    <li>
                        PwC is not being engaged to interact or meet with third
                        parties, including Client&apos;s regulators; Client is
                        solely responsible for communications with its
                        regulators. If PwC is requested or authorized by Client
                        or required by government regulation, regulatory agency,
                        subpoena, or other legal process, to retain or produce
                        materials or to produce personnel for testimony or
                        interview with respect to the Offering, Client will
                        reimburse PwC for PwC&apos;s and its counsels&rsquo;
                        expenses and professional time incurred in complying
                        with and responding to such a request or requirement.
                    </li>
                    <li>
                        Client Parties and Users will comply with all applicable
                        laws, rules, and regulations (including US and other
                        data privacy laws and export control laws and economic
                        sanctions programs). In particular, Client Parties and
                        Users will comply with all applicable US and non-US
                        export control laws and economic sanctions programs
                        (collectively,{" "}
                        <span className="legal-bold-italic">
                            &rdquo;Trade Control Laws&rdquo;
                        </span>
                        ), including comprehensive US sanctions against Cuba,
                        Iran, North Korea, Syria, and the Crimea, Donetsk
                        People&apos;s Republic, and Luhansk People&apos;s
                        Republic regions (collectively,{" "}
                        <span className="legal-bold-italic">
                            &rdquo;Sanctioned Countries&rdquo;
                        </span>
                        ), as well as individuals and entities identified on, or
                        owned or controlled by or acting on behalf of entities
                        identified on, applicable government restricted party
                        lists (collectively,{" "}
                        <span className="legal-bold-italic">
                            &rdquo;Restricted Parties&rdquo;
                        </span>
                        ). Client represents and covenants that the Client
                        Parties and Users (a) are not Restricted Parties; (b)
                        are not located or ordinarily resident in, organized
                        under the laws of, or acting on behalf of a Sanctioned
                        Country; (c) will not access or use the Offering from a
                        Sanctioned Country; (d) will not access or use the
                        Offering to export, re-export, sell, or supply
                        accounting, trust or corporate formation, or management
                        consulting services to any person located in Russia
                        (directly or indirectly), or for any end-uses prohibited
                        under applicable Trade Control Laws; and (e) will not
                        access or use the Offering to export, re-export, store,
                        host, or otherwise transfer data or services subject to
                        the Export Administration Regulations and controlled at
                        a level other than EAR99/AT (or equivalent level of
                        control under non-US Trade Control Laws) or subject to
                        the International Traffic in Arms Regulations (ITAR).
                        The Client Parties and Users are solely responsible for
                        their compliance with Trade Control Laws in relation to
                        the Agreement.
                    </li>
                </ul>
            </li>
            <li>
                <h1>
                    <strong>LAW; DISPUTES; REMEDIES; CLAIMS.</strong>
                </h1>
                &nbsp;The Agreement and disputes relating to it will be governed
                by and construed in accordance with the laws of the State of New
                York, without giving effect to provisions that would require the
                laws of another jurisdiction to apply. UCITA, the UCC, and the
                UN Convention on Contracts for the International Sale of Goods
                will not apply. Any unresolved dispute relating to or arising
                out of the Agreement will be resolved by arbitration, except
                that either party is free to seek equitable relief in a court
                having jurisdiction in the event of a breach or threatened
                breach of a party&apos;s obligations with respect to
                Confidential Information or IP Rights. The arbitration will be
                conducted in accordance with the Rules for Non-Administered
                Arbitration of the International Institute for Conflict
                Prevention and Resolution then in effect (
                <span className="legal-bold-italic">
                    &rdquo;CPR Rules&rdquo;
                </span>
                ). The arbitration will be conducted before a panel of three (3)
                arbitrators selected using the screened process provided for in
                the CPR Rules. The arbitration will be seated and take place in
                New York, New York. The arbitration panel (and not any court or
                agency) (a) will have exclusive authority to resolve any dispute
                relating to the interpretation, applicability, enforceability,
                formation of, or defenses with respect to, the Agreement; and
                (b) will have no power to award non-monetary or equitable relief
                of any sort, or to award damages inconsistent with the
                Agreement, including any provision limiting a party&apos;s
                liability. Judgment on any arbitration award may be entered in
                any court having jurisdiction. All aspects of the arbitration
                will be treated as confidential. Any demand for arbitration by
                Client must be issued within one (1) year from the date that
                Client became aware or should reasonably have become aware of
                the facts that give rise to PwC&apos;s alleged liability and in
                any event no later than two (2) years after the cause of action
                accrued. Except as expressly provided in these Standard Terms,
                the parties&rsquo; rights and remedies are cumulative. The PwC
                Providers and the partners, principals, members, employees, and
                agents of both PwC and the PwC Providers (collectively, the{" "}
                <span className="legal-bold-italic">
                    &rdquo;Beneficiaries&rdquo;
                </span>
                ) will have no liability or obligations to Client or any other
                party arising out of the Agreement. Client agrees to: (a) bring
                any claim or proceeding arising from or related to the Agreement
                against PwC and not against the Beneficiaries; (b) ensure that
                other Client Parties, Client&apos;s other Affiliates, and Users
                do not assert any claim arising from or related to the Agreement
                against PwC or the Beneficiaries; and (c) accept responsibility
                and liability if any such party pursues such a claim. This{" "}
                <u>Section 12</u> is intended for the benefit of the
                Beneficiaries.
            </li>
            <li>
                <h1>
                    <strong> MISCELLANEOUS.</strong>
                </h1>{" "}
                Notices to be given or submitted in connection with the
                Agreement must be in writing and delivered by hand, registered
                mail, or overnight delivery to the recipient at the address set
                forth on the applicable Order (or a later address as may be
                provided in accordance with this Section), and will be deemed
                given on the day they are received. Notwithstanding the
                foregoing, administrative notices that do not purport to alter
                legal or commercial terms may also be sent via email and deemed
                given upon recipient&apos;s confirmation.
                <ul className="legal-nested-list">
                    <li>
                        Neither party may assign, delegate, or otherwise
                        transfer the Agreement or any rights or obligations
                        under the Agreement (voluntarily or involuntarily, by
                        operation of law or otherwise), without the other&apos;s
                        prior written consent (which will not be unreasonably
                        withheld, delayed, or conditioned), and any such
                        assignment or attempt is void; except that PwC may
                        assign or delegate the Agreement or any of its rights or
                        obligations without Client&apos;s consent (a) to another
                        Network Firm or (b) in connection with a corporate
                        transaction such as a merger, reorganization,
                        divestiture, or other separation of all or part of a PwC
                        business. The Agreement will be binding upon and inure
                        to the benefit of the parties&rsquo; permitted
                        successors and assigns.
                    </li>
                    <li>
                        Except as provided below, neither party may use the
                        other&apos;s name, trademarks, or logo, or refer to the
                        other party (directly or indirectly) in any public
                        disclosure, without the other party&apos;s prior
                        consent. PwC may use Client&apos;s and Included
                        Affiliates&rsquo; name on public-facing materials
                        (tangible or digital) for the Offering.
                    </li>
                    <li>
                        The parties are independent, and neither has authority
                        to bind the other. The Agreement does not create any
                        agency, partnership, joint venture, or similar form of
                        association between them. Except with respect to the
                        Beneficiaries, the parties do not intend to confer a
                        third-party beneficiary right of action upon any third
                        party.
                    </li>
                    <li>
                        PwC may modify these Standard Terms from time to time;
                        such updates will apply (I) to all Orders executed after
                        the date of the modified version, and (II) prospectively
                        to the renewal of Orders entered into before the date of
                        the modified version, as of the renewal date unless
                        Client elects not to renew as provided in the applicable
                        Order. Except as otherwise provided, any modification to
                        the Agreement must be in writing and signed by duly
                        authorized signatories of each party. If a provision in
                        the Agreement is determined to be invalid or
                        unenforceable, it will be adjusted rather than voided,
                        if possible, to achieve the intent of the parties, and
                        the remaining provisions will remain fully enforceable.
                        All waivers must be in writing; waiver on one occasion
                        does not waive any other provision or the same provision
                        on a later occasion.{" "}
                        <span className="legal-bold-italic">
                            &rdquo;Including&rdquo;
                        </span>{" "}
                        or{" "}
                        <span className="legal-bold-italic">
                            &rdquo;include(s)&rdquo;
                        </span>{" "}
                        means{" "}
                        <span className="legal-bold-italic">
                            &rdquo;including but not limited to&rdquo;
                        </span>
                        ; and{" "}
                        <span className="legal-bold-italic">
                            &rdquo;or&rdquo;
                        </span>{" "}
                        is not exclusive. No presumption will operate for or
                        against a party as a result of its counsel&apos;s role
                        in drafting.
                    </li>
                    <li>
                        A party&apos;s inability to perform because of
                        conditions beyond its reasonable control will be excused
                        if the affected party uses reasonable measures to
                        mitigate the condition.
                    </li>
                    <li>
                        The Offering Overview, maintenance and support program
                        for the Offering, the data processing addendum for the
                        Offering (each of which can be found via the link to the
                        specific Offering posted at &nbsp;
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://productcentral.products.pwc.com/us/en/products.html"
                        >
                            https://productcentral.products.pwc.com/us/en/products.html
                        </a>
                        ), and the Order are hereby incorporated by reference.
                        PwC reserves the right to change the location of the
                        foregoing websites and links.
                    </li>
                    <li>
                        If there is any conflict between an Order and these
                        Standard Terms, the Order will control. If there is any
                        conflict between a webpage incorporated herein and these
                        Standard Terms, then the referenced webpage will
                        control. If there is a direct conflict between the
                        Agreement and any click-wrap end user license or other
                        agreement between PwC and Client presented as part of
                        the Offering (including where a user must click an{" "}
                        <span className="legal-bold-italic">
                            &rdquo;I Agree&rdquo;
                        </span>{" "}
                        button), the Agreement will control.
                    </li>
                    <li>
                        The Agreement constitutes the entire agreement between
                        the parties concerning its subject matter and supersedes
                        all prior or contemporaneous representations,
                        discussions, proposals, and agreements in any format,
                        relating to its subject matter. The parties object to
                        and expressly reject additional or different terms in
                        any purchase orders, acknowledgements, or similar
                        documents.
                    </li>
                </ul>
            </li>
            <li>
                <h1>
                    <strong> DEFINITIONS</strong>
                </h1>
                <ol>
                    <li>
                        <span className="legal-bold-italic">Affiliate</span>{" "}
                        means an entity that directly or indirectly controls, is
                        controlled by, or is under common control with a party,
                        where{" "}
                        <span className="legal-bold-italic">
                            &rdquo;control&rdquo;
                        </span>{" "}
                        means the direct or indirect ownership of at least fifty
                        percent (50%) of the then-outstanding voting shares or
                        equity interests of that party, or the power to direct
                        or cause the direction of the management and policies of
                        that party, whether by contract or otherwise; for so
                        long as such control relationship exists.
                    </li>
                    <li>
                        <span className="legal-bold-italic">
                            Authorized Contractor
                        </span>{" "}
                        means a third-party contractor performing services on
                        behalf of Client under a separate written agreement with
                        Client, and who(se): (a) has a need to access the
                        Offering, but solely in performance of its duties to
                        Client; (b) is not a competitor of PwC or another
                        Network Firm; (c) use of the Offering is subject to and
                        consistent with the Agreement, including provisions
                        relating to confidentiality and intellectual property;
                        and (d) is named on the Order or is otherwise approved
                        in writing by PwC.
                    </li>
                    <li>
                        <span className="legal-bold-italic">Client Data</span>{" "}
                        means all data (whether owned by Client or a third
                        party) that a Client Party or User inputs, transmits,
                        uploads to, stores, or processes on or through, the
                        Offering.
                    </li>
                    <li>
                        <span className="legal-bold-italic">
                            Client Materials
                        </span>{" "}
                        means Client Data, Client Confidential Information, and
                        any other content, information, technology, or materials
                        (including Client Systems) that a Client Party or User
                        provides or makes available to PwC or a PwC Provider in
                        connection with the Agreement (including for purposes of
                        making them available to Users via the Offering).
                    </li>
                    <li>
                        <span className="legal-bold-italic">Client Party</span>{" "}
                        means Client, Included Affiliates, and Authorized
                        Contractors.
                    </li>
                    <li>
                        <span className="legal-bold-italic">
                            Client Systems
                        </span>{" "}
                        means software, hardware, websites, databases, systems,
                        communications equipment, utilities, and other
                        technology of Client or a Client Party&apos;s
                        third-party technology or service providers.
                    </li>
                    <li>
                        <span className="legal-bold-italic">
                            Confidential Information
                        </span>{" "}
                        is defined in <u>Section 6</u>.
                    </li>
                    <li>
                        <span className="legal-bold-italic">Documentation</span>{" "}
                        means standard technical documentation and user guides
                        for the Offering that PwC generally makes available to
                        its customer base for the Offering.
                    </li>
                    <li>
                        <span className="legal-bold-italic">
                            High-Risk Activities
                        </span>{" "}
                        means activities that could lead to death, personal
                        injury, or environmental damage, including the design,
                        construction, operation, or maintenance of nuclear
                        facilities, the navigation or operation of aircraft, or
                        the operation of life-saving, or life, health-support,
                        or life-critical medical equipment.
                    </li>
                    <li>
                        <span className="legal-bold-italic">
                            Included Affiliate
                        </span>{" "}
                        means an Affiliate of Client who (a) is named on the
                        Order; and (b) is legally bound by Client&apos;s
                        signature or by Client in writing to abide by the terms
                        of the Agreement; provided that such Included
                        Affiliates&rsquo; use of the Offering is subject to and
                        consistent with the Agreement, including provisions
                        relating to confidentiality and intellectual property.
                    </li>
                    <li>
                        <span className="legal-bold-italic">IP Rights</span>{" "}
                        means all rights in and to: copyrights, patents, trade
                        secrets, trademarks, service marks, trade dress, domain
                        names, databases and other compilations and collections
                        of data or information, publicity and privacy, and other
                        intellectual property anywhere in the world, whether
                        statutory, common law or otherwise, now known or later
                        created.
                    </li>
                    <li>
                        <span className="legal-bold-italic">PwC Software</span>{" "}
                        means the portion of the Software other than Third-Party
                        Materials.
                    </li>
                    <li>
                        <span className="legal-bold-italic">Software</span>{" "}
                        means compiled code versions of the software
                        applications described on the Order, which will be
                        hosted and provided as part of the Offering.
                    </li>
                    <li>
                        The <span className="legal-bold-italic">Term</span> of
                        an Order is defined in <u>Section 4.1</u>, and for
                        clarity, includes the initial term stated on the Order,
                        and applicable renewal terms (if any).
                    </li>
                    <li>
                        <span className="legal-bold-italic">
                            Third-Party Materials
                        </span>{" "}
                        means data, software, content, technology and other
                        materials, and services that are sourced from a party
                        other than PwC and that are used in providing,
                        incorporated into, or made available via, the Offering.
                    </li>
                    <li>
                        <span className="legal-bold-italic">User</span> means an
                        employee of a Client Party permitted to access and use
                        the Offering.
                    </li>
                    <li>
                        <span className="legal-bold-italic">User Account</span>{" "}
                        means a unique account associated with a unique username
                        and password for a single individual User designated on
                        the Order, through which the User may access and use the
                        Offering as permitted in the Agreement.
                    </li>
                </ol>
            </li>
        </ol>
    </>
);

export default TOS;
