import React from "react";
import "./WorkSmartHelp.scss";
import Input from "../../components/Input";
import HelpTable from "../../components/Help/HelpTable";
import QA from "../../components/Help/QA";
// import { getQaAPI } from "../../utils/faqHelper";
import { helpCopy } from "../../utils/cms";
import NoHelp from "../../components/Help/NoHelp/NoHelp";
import HelpChat from "../../components/Help/HelpChat";
import HelpSubmit from "../../components/Help/HelpSubmit";
import EndOfHelp from "../../components/Help/EndOfHelp";
import HelpEditSubmit from "../../components/Help/HelpEditSubmit";
// import API from "../../utils/API";
import { APIContext, httpVerb } from "../../context/APIProvider";
import { findUnique, findSpecificTag } from "../../utils/faqHelper";

export default class WorkSmartHelp extends React.Component {
    static contextType = APIContext;
    constructor(props) {
        super(props);
        this.state = {
            inputValue: "",
            submissionModalValue: "",
            timer: null,
            showNoHelp: false,
            showChat: this.props.chatGroupObj != null,
            showSubmission: false,
            showSubmissionModal: false,
            faqs: [],
            categories: []
        };
        this.showSubmission = this.showSubmission.bind(this);
        this.clearHelp = this.clearHelp.bind(this);
    }

    handleFAQs = (QAArray) => {
        // console.log(res, "RES CHECK");
        if (Array.isArray(QAArray)) {
            QAArray.forEach(QA => QA["categories"] = findSpecificTag(QA.tags));
            const returnValue = {
                faqs: QAArray, categories: findUnique(QAArray)
            };
            this.setState({ faqs: returnValue.faqs, categories: returnValue.categories });
        }
    };

    componentDidMount() {
        this.context.getSNOWFAQ(this.props.isAdmin, this.props.userDetails.company, this.props.userDetails.platform).then(res => {
            this.handleFAQs(res);
        });
    }

    filterList = (event) => {
        clearTimeout(this.timer);
        this.setState({
            inputValue: event.target.value,
            showNoHelp: false,
        });
        this.timer = setTimeout(() => this.setState({ showNoHelp: true }), 2000);
    }

    showSubmission = () => {
        this.setState({
            showSubmissionModal: true,
            submissionModalValue: this.state.inputValue
        });
    }

    submitModal = (e) => {
        e.preventDefault();

        //TODO: give email, name, company via this call
        this.context.snowHttpRequest(`/api/now/import/u_intake_submission`, httpVerb.POST, {
            "u_short_description": `${(this.props.isAdmin) ? "ADMIN" : "USER"} - Question from Amplifier`,
            "u_description": this.state.submissionModalValue,
            "u_affected_user": this.props.userDetails.userId,
            "u_caller_name": this.props.userDetails.userName,
            "u_company": this.props.userDetails.company,
            "u_service": "Amplifier " + this.props.userDetails.platform,
            "u_symptom": "Information/How To"
        })
        // API.submitQuestion({
        //     "u_short_description": `${(this.props.isAdmin) ? "ADMIN" : "USER"} - Question from Amplifier`,
        //     "u_description": this.state.submissionModalValue,
        //     "u_affected_user": this.props.userDetails.userId,
        //     "u_caller_name": this.props.userDetails.role,
        //     "u_company": this.props.userDetails.company,
        //     "u_platform": "Amplifier " + this.props.userDetails.platform,
        //     "u_symptom": "Information/How To"
        // });
        this.setState({
            showSubmissionModal: false,
            showSubmission: true,
            submissionModalValue: "",
            inputValue: ""
        });
    }

    clearHelp = () => {
        this.setState({
            inputValue: "",
            timer: null,
            showNoHelp: false,
            showChat: false,
            showSubmissionModal: false,
            showSubmission: false
        });
        this.props.setChatGroupId(null);
    }

    render() {
        const searchValue = this.state.inputValue;
        const filtered = this.state.faqs.filter(item => {
            return (item.question.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) ||
                (item.answer.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);
        });
        const filteredLen = filtered.length;
        return (
            <div className="sidebar--body">
                <main>
                    <div className={(this.state.showChat || this.state.showSubmission) ? "hidden" : "help--top main--content"}>
                        <Input className="main--input__help"
                            placeholder={helpCopy.inputPlaceholder}
                            onchange={this.filterList}
                            value={this.state.inputValue}
                            islarge={true}
                        >
                            <button onClick={() => this.setState({ inputValue: "" })} className={(this.state.inputValue.length > 0) ? "help--clear help--clear__pos" : "help--clear help--clear__pos hidden"}>{helpCopy.inputClear.toUpperCase()}</button>
                        </Input>
                    </div>
                    <div className={(this.state.inputValue.length > 0 && !this.state.showChat && !this.state.showSubmission) ? "help--faq" : "hidden help--faq"}>
                        <h1>{helpCopy.searchHeader.toUpperCase()}</h1>
                        <span className="help--faq__floatRight">{filtered.length} {helpCopy.searchItems}</span>
                        {
                            (filtered.length === 0
                                && this.state.showNoHelp
                                && !this.state.showChat
                                && !this.state.showSubmission) ? (
                                    <NoHelp
                                        showChat={() => this.setState({ showChat: !this.state.showChat })}
                                        isAdmin={this.props.isAdmin}
                                        showSubmission={this.showSubmission}
                                        company={this.props.userDetails.company}
                                        platform={this.props.userDetails.platform}
                                        userId={this.props.userDetails.userId} />

                                ) : filtered.map((qa, index) => {
                                    if (filteredLen === index + 1) {
                                        return (
                                            <React.Fragment
                                                key={"QaResults" + index}>
                                                <QA question={qa.question}
                                                    answer={qa.answer
                                                        // .slice(0, 160)
                                                    }
                                                    truncatedanswer={qa.answer
                                                        // .slice(161)
                                                    }
                                                />
                                                {(this.state.showNoHelp) ? <EndOfHelp submit={this.showSubmission} /> : ""}
                                            </React.Fragment>
                                        );
                                    } else {
                                        return (
                                            <QA question={qa.question}
                                                answer={qa.answer
                                                    // .slice(0, 160)
                                                }
                                                truncatedanswer={qa.answer
                                                    // .slice(161)
                                                }
                                                key={"QaResults" + index}
                                            />
                                        );
                                    }
                                })
                        }
                    </div>
                    {this.state.showSubmissionModal &&
                        <HelpEditSubmit
                            submission={this.state.submissionModalValue}
                            onChangeSub={(e) => this.setState({ submissionModalValue: e.target.value })}
                            onCancel={() => this.setState({ showSubmissionModal: false })}
                            onSubmit={this.submitModal}
                            onEscapeModal={() => this.setState({ showSubmissionModal: false })}
                        />
                    }
                    <HelpTable className={(this.state.inputValue.length === 0 && !this.state.showChat && !this.state.showSubmission) ? "help--bottom main--content" : "help--bottom main--content hidden"}
                        faqs={this.state.faqs}
                        categories={this.state.categories}
                        submission={this.showSubmission}
                    />
                    {(this.state.showChat) ?
                        <HelpChat
                            groupId={this.props.chatGroupObj}
                            setGroupID={this.props.setChatGroupId}
                            close={() => this.clearHelp()}
                            firstMessage={this.state.inputValue}
                            userDetails={this.props.userDetails} /> : ""}
                    {(this.state.showSubmission) ? <HelpSubmit close={() => { this.setState({ showSubmissionModal: false, showSubmission: false }); }} /> : ""}
                </main>
            </div>
        );
    }
}