import React from "react";
import "./Shortcuts.scss";
import MyShortcuts from "../MyShortcuts";
import AllShortcuts from "../AllShortcuts";
import { isMobile } from "react-device-detect";

export default class Shortcuts extends React.Component {  
    render() {
        return (
            <div className={isMobile ? 'mobile-view shortcuts-m' : 'shortcuts'}>
                <MyShortcuts></MyShortcuts>
                <AllShortcuts></AllShortcuts>
            </div>
        );
    }
}