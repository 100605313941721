const massageBreakdown = (breakdown) => {
    if (Array.isArray(breakdown)) {
        let pointsEarned = breakdown.map((el) => {
            return { ...el, pointsEarned: el.points };
        });

        if (pointsEarned.length === 0) {
            pointsEarned = [{ pointsEarned: 0, description: "" }];
        }

        const totalPoints = pointsEarned.reduce(function (a, b) {
            return { pointsEarned: a.pointsEarned + b.pointsEarned };
        });

        const newBreakdown = pointsEarned.map((el) => {
            let value = el.pointsEarned / totalPoints.pointsEarned;
            el.label = `${Math.round(value * 100)}%`;
            return el;
        });

        return newBreakdown;
    }

    return [];
};

const massageLeaderboard = (leaderboard) => {
    if (leaderboard.length === 0) {
        return [
            {
                rank: 0,
                name: "Katharine Tate",
                score: 0,
                last_updated: new Date().toISOString(),
                pointsOnRise: "true",
                isCurrentUser: "true",
                picUrl: null,
            },
        ];
    }

    return leaderboard;
};

const mapDetails = (details) => {
    const returnDetails = {
        company: details.clientName,
        platform: details.enterprisePlatformName,
        userId: details.email,
        role: details.role.value,
        userName: details.name,
    };

    return returnDetails;
};

export { massageBreakdown, massageLeaderboard, mapDetails };
