import React from "react";

import { frequencyNormalize } from "../../../utils/timeHelper";

const RulesTableContent = props => {
    let { element } = props;
    return (
        <tr>
            <td>{element.behavior}</td>
            <td>{element.score} pts</td>
            <td>{frequencyNormalize(element.frequency)}</td>
        </tr>
    );
};

export default RulesTableContent;