export default [
    {
        rank: 17,
        name: "Katharine Tate",
        score: 500,
        last_updated: new Date().toISOString(),
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
    {
        rank: 18,
        name: "Nellie Green",
        score: 490,
        pointsOnRise: "true",
        isCurrentUser: "true",
    },
    {
        rank: 19,
        name: "Jake Johnson",
        score: 487,
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
    {
        rank: 21,
        name: "Sally Tate",
        score: 443,
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
    {
        rank: 22,
        name: "Jorge Bennett",
        score: 409,
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
    {
        rank: 23,
        name: "Ralph Terry",
        score: 389,
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
    {
        rank: 24,
        name: "Hester Morales",
        score: 376,
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
    {
        rank: 25,
        name: "This Guy",
        score: 389,
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
    {
        rank: 26,
        name: "That Other Guy",
        score: 376,
        pointsOnRise: "true",
        isCurrentUser: "false",
    },
];
