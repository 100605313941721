import React from "react";
import "./Legal.scss";
import Privacy from "../../cms/Privacy.js";
import Cookie from "../../cms/Cookie.js";
import TOS from "../../cms/TOS";
import HelpEditSubmit from "../../components/Help/HelpEditSubmit";
import { isAdmin } from "../../utils/reusableFunctions";
import { APIContext, httpVerb } from "../../context/APIProvider";
import ModalWindow from "../../components/ModalWindow/ModalWindow";
import HelpSubmit from "../../components/Help/HelpSubmit";

export default class Legal extends React.Component {
    static contextType = APIContext;
    constructor(props) {
        super(props);

        this.state = {
            selected: 0,
            version: props.version,
            versionDate: props.versionDate,
            question: '',
            helpModal: false,
            thankyouModal: false,
        };
        this.cookieClick = this.cookieClick.bind(this);
        this.privacyClick = this.privacyClick.bind(this);
    }

    changeSelected = (newNumber) => {
        this.setState({ selected: newNumber });
    }

    cookieClick = () => {
        this.setState({
            selected: 1
        });
    }

    privacyClick = () => {
        this.setState({
            selected: 0
        });
    }

    onHelpSubmit = (ev) => {
        ev.preventDefault();
        const { question } = this.state;
        const { userDetails, permissions } = this.context;

        this.context.snowHttpRequest(`/api/now/import/u_intake_submission`, httpVerb.POST, {
            "u_short_description": `${isAdmin(permissions) ? "ADMIN" : "USER"} - Question from Amplifier`,
            "u_description": question,
            "u_affected_user": userDetails.userId,
            "u_caller_name": userDetails.userName,
            "u_company": userDetails.company,
            "u_service": "Amplifier " + userDetails.platform,
            "u_symptom": "Information/How To"
        });

        this.setState({ question: "", helpModal: false, thankyouModal: true });
    }

    closeThankyou = () => {
        this.setState({ thankyouModal: false });
    }
    
    componentDidMount() {
        if (this.props.setLegalSection) {
            this.changeSelected(this.props.setLegalSection);
        }
    }

    render() {
        const selectedArray = [{
            label: "privacy",
            full: "Privacy Policy",
            html: <Privacy cookieClick={this.cookieClick} />
        }, {
            label: "cookie",
            full: "Cookie Statement",
            html: <Cookie changeSelected={this.changeSelected} />
        }, {
            label: "tos",
            full: "Terms of Service",
            html: <TOS privacyClick={this.privacyClick} />
        }];

        return (
            <>
                {this.state.helpModal &&
                    <HelpEditSubmit
                        submission={this.state.question}
                        onChangeSub={(e) => this.setState({ question: e.target.value })}
                        onCancel={() => this.setState({ helpModal: false })}
                        onSubmit={this.onHelpSubmit}
                        onEscapeModal={() => this.setState({ helpModal: false })}
                    />
                }
                {this.state.thankyouModal &&
                    <ModalWindow className="modal-on-legal" onEscapeModal={this.closeThankyou}>
                        <HelpSubmit close={this.closeThankyou} buttonText={"Ok"} />
                    </ModalWindow>
                }
                <aside className="legal--window">
                    <CloseBtn close={this.props.close} />
                    <LegalNav
                        selection={this.state.selected}
                        changeSelected={this.changeSelected}
                        buttons={selectedArray} />
                    <ContentSection
                        selection={selectedArray[this.state.selected].label}
                        html={selectedArray[this.state.selected].html}
                        version={this.state.version}
                        versionDate={this.state.versionDate}
                    />
                </aside>
            </>

        );
    }
}

const LegalNav = (props) => {
    return (
        <nav className="legal--nav">
            <h1 className="legal--title">Legal</h1>
            <ul>
                {props.buttons.map((button, index) => (
                    <li key={index}>
                        <button
                            className={(index === props.selection) ? "legal--btn legal--btn_active" : "legal--btn"}
                            onClick={() => props.changeSelected(index)}
                        >
                            {button.full}
                        </button>
                    </li>
                ))}
            </ul>
        </nav>
    );
};

const CloseBtn = (props) => (
    <button className="flipper--close close--chat clickable close--legal" onClick={props.close}>+</button>
);

class ContentSection extends React.Component {
    constructor(props) {
        super(props);

        this.contentRef = React.createRef();   // Create a ref object 
    }

    componentDidUpdate(nextProps) {
        if (nextProps.selection !== this.props.selection) {
            this.contentRef.current.scrollTo(0, 0);
        }
    }

    render() {
        return (
            <div className="legal--dynamicContent legal-wrapper" ref={this.contentRef}>
                {this.props.html}
            </div>
        );
    }
}