import React from "react";
import "./MobileHelp.scss";
import MobileHead from "../MobileHead";
import { isAdmin } from "../../utils/reusableFunctions";
import { APIContext, httpVerb } from "../../context/APIProvider";
import HelpSubmit from "../Help/HelpSubmit";
import ModalWindow from "../ModalWindow";

class MobileHelp extends React.Component {
    static contextType = APIContext;
    state = {
        question: '',
        thankyouModal: false
    }

    onChangeQuestion = (ev) => this.setState({ question: ev.target.value });

    onSubmit = (ev) => {
        ev.preventDefault();
        const { question } = this.state;
        const { userDetails, permissions } = this.context;

        this.context.snowHttpRequest(`/api/now/import/u_intake_submission`, httpVerb.POST, {
            "u_short_description": `${isAdmin(permissions) ? "ADMIN" : "USER"} - Question from Amplifier`,
            "u_description": question,
            "u_affected_user": userDetails.userId,
            "u_caller_name": userDetails.userName,
            "u_company": userDetails.company,
            "u_service": "Amplifier " + userDetails.platform,
            "u_symptom": "Information/How To"
        })

        this.setState({ question: "", thankyouModal: true });
    }

    closeThankyou = () => this.setState({ thankyouModal: false });

    render() {
        const { question, thankyouModal } = this.state;
        return (
            <>
                <MobileHead />
                <form className="mobile-help-form" onSubmit={this.onSubmit}>
                    <h2>Submit your question</h2>
                    <textarea tabIndex={1} value={question} onChange={this.onChangeQuestion} />
                    <button type="submit" className="btn btn__primary" tabIndex={2}>Submit</button>
                </form>
                {thankyouModal && <ModalWindow onEscapeModal={this.closeThankyou}>
                    <HelpSubmit close={this.closeThankyou} />
                </ModalWindow>}
            </>
        );
    }
}

export default MobileHelp;
