import React from "react";
import "./NewCampaign.scss";
import CurrentScore from "../Leaderboard/CurrentScore";
import Svg from "../Leaderboard/Svg";
import { CompletitionChallenge } from "../../assets/icons/icons";
import moment from "moment";
import * as _ from "lodash";
import { isMobile } from "react-device-detect";
import { formatChallengeDates } from "../../utils/reusableFunctions";
import { APIContext, httpVerb } from "../../context/APIProvider";
import { ACCEPT_TERMS } from "../../services/endpoints/endpoints";

const LENGTH_OF_DIAL = 639.447;

class NewCampaign extends React.Component {
    static contextType = APIContext;

    constructor(props) {
        super(props);

        this.state = {
            height: "0px",
            showTerms: !this.props?.isTermsAndConditionsAccepted,
            isTermsAndConditionsFailed: false,
            isTermsAndConditionsAccepted:
                !!this.props?.isTermsAndConditionsAccepted,
        };
    }

    componentDidMount() {
        this.updateBodyHeight();
        window.addEventListener("resize", this.updateBodyHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateBodyHeight);
    }

    // Helper functions
    //#region
    challengeCompletion = () => {
        const percentage = Math.round(
            (this.props.prevCampaign.score / this.props.prevCampaign.goal) * 100
        );
        const message = () => {
            switch (true) {
                case percentage <= 15:
                    return "Oh! Let’s try again.";
                case percentage > 15 && percentage <= 25:
                    return "Good luck next time.";
                case percentage > 25 && percentage <= 50:
                    return "Almost halfway there. Keep trying!";
                case percentage > 50 && percentage <= 65:
                    return "Great progress!";
                case percentage > 65 && percentage <= 75:
                    return "Keep up the good work!";
                case percentage > 75 && percentage <= 90:
                    return "You’re really close to your goal.";
                case percentage > 90:
                    return "Congratulations, you did it!";
                default:
                    return ``;
            }
        };
        return (
            <>
                <p className="percentage-text">
                    Last period you reached{" "}
                    {percentage < 100 && `${percentage}% of`} your goal with{" "}
                    {this.props.prevCampaign.score} points.
                </p>
                <p className="message-text">{message()}</p>
            </>
        );
    };

    challengeEnd = () => {
        const endDate = moment(this.props.prevCampaign.end_date, "YYYY-MM-DD");
        const endDateFormat = endDate.format("MMM D YYYY");
        return <p className="ch-end-date">Last period ended {endDateFormat}</p>;
    };

    dashOffset = () => {
        return this.getDashoffset(
            this.props.prevCampaign.score,
            this.props.prevCampaign.goal
        );
    };

    getDashoffset = (currpts, challengepts) => {
        const numerater = challengepts - currpts;
        if (isNaN((challengepts - currpts) / challengepts)) {
            return LENGTH_OF_DIAL;
        }
        return numerater < 0
            ? LENGTH_OF_DIAL
            : LENGTH_OF_DIAL * ((challengepts - currpts) / challengepts);
    };

    handleTermsAndConditionsAccept = () => {
        this.setState({ isTermsAndConditionsFailed: false });

        this.context
            .httpRequest(ACCEPT_TERMS, httpVerb.PUT)
            .then((res) => {
                if (!res || (res.success && res.success === "false")) {
                    this.handleTermsAndConditionsAcceptFailed();

                    return;
                }

                this.handleTermsAndConditionsAcceptSuccess();
            })
            .catch(() => {
                this.handleTermsAndConditionsAcceptFailed();
            });
    };

    handleTermsAndConditionsAcceptSuccess = () => {
        this.setState({
            showTerms: false,
            isTermsAndConditionsAccepted: true,
            isTermsAndConditionsFailed: false,
        });
        this.props.setTermsAndConditions(true);
        this.props.close();
    };

    handleTermsAndConditionsAcceptFailed = () => {
        this.setState({
            showTerms: true,
            isTermsAndConditionsAccepted: false,
            isTermsAndConditionsFailed: true,
        });
    };

    handleTermsAndConditionsCheckbox = (event) => {
        this.setState({
            isTermsAndConditionsFailed: false,
            isTermsAndConditionsAccepted: event.target.checked,
        });
    };

    handleStartChellange = () => {
        if (!this.state.showTerms) {
            this.props.close();
            return;
        }

        this.handleTermsAndConditionsAccept();
    };

    handleShowLegalSection = (section) => {
        this.props.setLegalSection(section);
        this.props.showLegal();
    };

    isChallengeComplete = () => {
        return (
            this.props.prevCampaign.goal - this.props.prevCampaign.score <=
                0 && <CompletitionChallenge />
        );
    };

    updateBodyHeight = () => {
        let height = this.divElement?.clientHeight;
        this.setState({ height });
    };
    //#endregion

    // SubComponents
    NewChallengeDetails = () => {
        const { startDateFormat, endDateFormat } = formatChallengeDates(
            this.props.currCampaign.campaignUTCStart || new Date(),
            this.props.currCampaign.campaignUTCEnd || new Date()
        );

        return (
            <>
                <p className="chl-name">
                    {this.props.currCampaign.campaignName &&
                        `Your new challenge is: ${this.props.currCampaign.campaignName}`}
                </p>
                <div className="chl-description">
                    <p>{this.props.currCampaign.challenge_description}</p>
                </div>
                <p className="chl-dates-goal">
                    Date: {startDateFormat} - {endDateFormat} | Goal:{" "}
                    {this.props.currCampaign.campaignChallengeScore || "N/A"}{" "}
                    pts
                </p>
            </>
        );
    };

    render() {
        const initialHeight = this.state.height;
        const styleObj = { height: initialHeight + "px" };

        return (
            <div
                className={`${
                    isMobile ? "mobile-view new-campaign" : "new-campaign"
                }`}
                ref={(divElement) => {
                    this.divElement = divElement;
                }}
            >
                <div className="wrap" style={styleObj}>
                    <h1 className="title">Welcome to Amplifier</h1>
                    {this.props.prevCampaign &&
                        !_.isEmpty(this.props.prevCampaign) && (
                            <>
                                <div className="challenge-content">
                                    {this.challengeCompletion()}
                                    <div className="campaign-dial">
                                        <Svg>
                                            <g
                                                id="Page-1"
                                                stroke="none"
                                                strokeWidth="1"
                                                fill="none"
                                                fillRule="evenodd"
                                                transform="translate(-33.000000, -33.000000)"
                                            >
                                                <g>
                                                    <path
                                                        d="M163.352941,281 C227.27417,281 281,227.27417 281,163.352941 C281,94.72583 227.27417,41 163.352941,41 C94.72583,41 41,94.72583 41,163.352941 C41,188.825833 49.5319718,212.679637 64.1389117,232.159538"
                                                        id="stroke"
                                                        strokeWidth="2"
                                                        transform="translate(161.000000, 161.000000) scale(1, -1) rotate(154.000000) translate(-161.000000, -161.000000) "
                                                        className="bg"
                                                        stroke="#CECECD"
                                                    />
                                                    <path
                                                        d="M163.352941,281 C227.27417,281 281,227.27417 281,163.352941 C281,94.72583 227.27417,41 163.352941,41 C94.72583,41 41,94.72583 41,163.352941 C41,188.825833 49.5319718,212.679637 64.1389117,232.159538"
                                                        id="bluestroke"
                                                        strokeWidth="14"
                                                        strokeLinecap="round"
                                                        transform="translate(161.000000, 161.000000) scale(1, -1) rotate(154.000000) translate(-161.000000, -161.000000) "
                                                        className="meter"
                                                        strokeDasharray={
                                                            LENGTH_OF_DIAL
                                                        }
                                                        strokeDashoffset={this.dashOffset()}
                                                    />
                                                </g>
                                            </g>
                                            <CurrentScore
                                                currpts={
                                                    this.props.prevCampaign
                                                        .score
                                                }
                                            />
                                            <foreignObject
                                                x="1%"
                                                y="62%"
                                                width="100%"
                                                height="40"
                                            >
                                                <div className="dial--challengeScore">
                                                    <h2>PREVIOUS CHALLENGE</h2>
                                                    <h3 id="amplifier-currentChallengeGoal">
                                                        {
                                                            this.props
                                                                .prevCampaign
                                                                .goal
                                                        }
                                                        <span>
                                                            pts{" "}
                                                            {this.isChallengeComplete()}
                                                        </span>
                                                    </h3>
                                                </div>
                                            </foreignObject>
                                        </Svg>
                                        {this.challengeEnd()}
                                    </div>
                                </div>
                                <hr />
                            </>
                        )}
                    <div className="challenge-content">
                        {this.props.currCampaign &&
                            !_.isEmpty(this.props.currCampaign) &&
                            this.NewChallengeDetails()}
                    </div>
                    <button
                        className={`btn btn__primary access${
                            !this.state.isTermsAndConditionsAccepted
                                ? " disabled"
                                : ""
                        }`}
                        onClick={this.handleStartChellange}
                        disabled={!this.state.isTermsAndConditionsAccepted}
                    >
                        START THE CHALLENGE
                    </button>

                    {this.state.showTerms && (
                        <div className="terms-and-conditions">
                            <div className="wrapper">
                                {this.state.isTermsAndConditionsFailed && (
                                    <div className="message">
                                        There was an error, try again.
                                    </div>
                                )}
                                <div className="form-check">
                                    <input
                                        className="checkbox"
                                        type="checkbox"
                                        id="terms-and-conditions"
                                        checked={
                                            this.state
                                                .isTermsAndConditionsAccepted
                                        }
                                        onChange={
                                            this
                                                .handleTermsAndConditionsCheckbox
                                        }
                                    />
                                    <div
                                        className="label"
                                        htmlFor="terms-and-conditions"
                                    >
                                        By clicking here, you confirm that you
                                        have read and agree to the{" "}
                                        <div
                                            className="link"
                                            onClick={() =>
                                                this.handleShowLegalSection(2)
                                            }
                                        >
                                            Terms of Service
                                        </div>{" "}
                                        and that you have read and understand
                                        the{" "}
                                        <div
                                            className="link"
                                            onClick={() =>
                                                this.handleShowLegalSection(0)
                                            }
                                        >
                                            Privacy Statement
                                        </div>
                                        .
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default NewCampaign;
