const MockAPI = {
    userScore: {
        user_id: "0051U000002pZvlQAE",
        client_id: 3,
        current_score: 176,
        platform_id: 1,
    },
    userDetails: {
        email: "max.j.rashes@pwc.com",
        name: "Max Rashes",
        employeenumber: "0051U000001q3wwQAA",
        Platform_Id: 1,
        client_id: 3,
        adoptiquserid: 10,
        permissions: [
            {
                Id: 29,
                Value: "platform",
            },
            {
                Id: 30,
                Value: "game",
            },
            {
                Id: 31,
                Value: "adminPortal",
            },
            {
                Id: 32,
                Value: "adminAnalytics",
            },
        ],
        role: {
            id: 1,
            value: "Associate",
        },
    },

    campaign: [
        {
            campaignName: "Static Data Challenge",
            challenge_description: "Static data, no database challenge demo",
            campaignChallengeScore: 500,
            campaignId: 4,
            campaignUTCEnd: "15-04-2023",
            campaignUTCStart: "16-5-2022",
            gameDuration: 50,
            isCurrent: true,
        },
    ],
    breakdown: [
        {
            event_id: 1,
            description: "Create a Dashboard",
            numberOfInstances: 3,
            points: 145,
        },
        {
            event_id: 2,
            description: "Close an Opportunity",
            numberOfInstances: 4,
            points: 95,
        },
        {
            event_id: 3,
            description: "Convert a Lead",
            numberOfInstances: 3,
            points: 135,
        },
        {
            event_id: 6,
            description: "Create a Chatter Post",
            numberOfInstances: 4,
            points: 99,
        },
        {
            event_id: 7,
            description: "Create a Client",
            numberOfInstances: 1,
            points: 70,
        },
        //5
        {
            event_id: 8,
            description: "Create a Contact",
            numberOfInstances: 2,
            points: 200,
        },
        {
            event_id: 9,
            description: "Create a Lead",
            numberOfInstances: 3,
            points: 135,
        },
        {
            event_id: 10,
            description: "Create a Poll",
            numberOfInstances: 4,
            points: 100,
        },
        {
            event_id: 20,
            description: "Create a Report",
            numberOfInstances: 3,
            points: 145,
        },
        {
            event_id: 2,
            description: "Create a Task",
            numberOfInstances: 4,
            points: 95,
        },
        // 10
        {
            event_id: 3,
            description: "Create an Event",
            numberOfInstances: 3,
            points: 135,
        },
        {
            event_id: 6,
            description: "Create an Opportunity",
            numberOfInstances: 4,
            points: 99,
        },
        {
            event_id: 7,
            description: "Join a Chatter Group",
            numberOfInstances: 1,
            points: 70,
        },
        {
            event_id: 8,
            description: "Log a Call",
            numberOfInstances: 2,
            points: 200,
        },
        {
            event_id: 9,
            description: "Login to Salesforce",
            numberOfInstances: 3,
            points: 135,
        },
        //15
        {
            event_id: 10,
            description: "Progress an Opportunity",
            numberOfInstances: 4,
            points: 100,
        },
        {
            event_id: 1,
            description: "Respond to a Chatter Post",
            numberOfInstances: 3,
            points: 145,
        },
        {
            event_id: 2,
            description: "Respond to a Poll",
            numberOfInstances: 4,
            points: 95,
        },
        {
            event_id: 3,
            description: "View a Dashboard",
            numberOfInstances: 3,
            points: 135,
        },
        {
            event_id: 6,
            description: "View a Report",
            numberOfInstances: 4,
            points: 99,
        },
    ],
    leaderboard: [
        {
            rank: 17,
            name: "Katharine Tate",
            score: 200,
            last_updated: new Date().toISOString(),
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: "https://pbs.twimg.com/profile_images/701887348062167040/XP6YqOK5_400x400.jpg",
        },
        {
            rank: 18,
            name: "Nellie Green",
            score: 390,
            pointsOnRise: "true",
            isCurrentUser: "true",
            picUrl: "https://pbs.twimg.com/profile_images/674061705975087104/j2sUz6pn_400x400.jpg",
        },
        {
            rank: 19,
            name: "Jake Johnson",
            score: 387,
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: null,
        },
        {
            rank: 20,
            name: "Bernice Rutabega",
            score: 176,
            pointsOnRise: "true",
            isCurrentUser: "false",
            rankDiff: 2,
            picUrl: "https://www.pwc.co.za/en/people/photos/bernice-kimacia.jpg.pwcimage.200.252.jpg",
        },
        {
            rank: 21,
            name: "Sally Tate",
            score: 443,
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: "https://pbs.twimg.com/profile_images/562444957563097089/PRp4NhvR.jpeg",
        },
        {
            rank: 22,
            name: "Jorge Bennett",
            score: 409,
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: "",
        },
        {
            rank: 23,
            name: "Ralph Terry",
            score: 389,
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: "https://activategroupinc.com/wp-content/uploads/2017/11/rick-longman.jpg",
        },
        {
            rank: 24,
            name: "Hester Morales",
            score: 376,
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: "https://pbs.twimg.com/profile_images/831676714719834112/Us9xvu44_400x400.jpg",
        },
        {
            rank: 25,
            name: "This Guy",
            score: 389,
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: "https://activategroupinc.com/wp-content/uploads/2017/11/rick-longman.jpg",
        },
        {
            rank: 26,
            name: "That Other Guy",
            score: 376,
            pointsOnRise: "true",
            isCurrentUser: "false",
            picUrl: "https://pbs.twimg.com/profile_images/831676714719834112/Us9xvu44_400x400.jpg",
        },
    ],
    myShortcuts: [
        {
            name: "Create a contact",
            points: 5,
            id: 42,
        },
        {
            name: "Update a record",
            points: 1,
            id: 43,
        },
        {
            name: "Create an event",
            points: 1,
            id: 44,
        },
        {
            name: "Create a chatter post",
            points: 5,
            id: 45,
        },
        {
            name: "Clone a dashboard",
            points: 15,
            id: 46,
        },
        {
            name: "Create a lead",
            points: 5,
            id: 47,
        },
    ],
    shortcuts: [
        {
            name: "Close an opportunity",
            points: 10,
            id: 1,
        },
        {
            name: "Create a chatter post",
            points: 5,
            id: 2,
        },
        {
            name: "Create a client",
            points: 5,
            id: 3,
        },
        {
            name: "Create a contact",
            points: 5,
            id: 4,
        },
        {
            name: "Create a dashboard",
            points: 10,
            id: 5,
        },
        {
            name: "Create a lead",
            points: 5,
            id: 6,
        },
        {
            name: "Join a chatter group",
            points: 1,
            id: 7,
        },
        {
            name: "Log a call",
            points: 5,
            id: 8,
        },
        {
            name: "Progress an opportunity",
            points: 2,
            id: 9,
        },
        {
            name: "Update a record",
            points: 1,
            id: 10,
        },
        {
            name: "Create a dashboard",
            points: 10,
            id: 5,
        },
        {
            name: "Create a lead",
            points: 5,
            id: 6,
        },
    ],
    companyGoal: {
        challengeScore: 800,
    },
    rules: [
        {
            behavior: "Acknowledge Performance Document",
            score: 25,
            frequency: "24:00:00",
        },
        {
            behavior: "Add a Check-in - Employee",
            score: 10,
            frequency: "24:00:00",
        },
        {
            behavior: "Add a Check-in - Manager",
            score: 5,
            frequency: "24:00:00",
        },
        {
            behavior: "Add a Contingent Worker - HR Specialist",
            score: 3,
            frequency: "168:00:00",
        },
        {
            behavior: "Add Assignment - HR Specialist",
            score: 3,
            frequency: "730:00:00",
        },
        {
            behavior: "Add Interactions with Candidate - Manager",
            score: 1,
            frequency: "24:00:00",
        },
        {
            behavior: "Add Interactions with Candidate - HR Specialist",
            score: 1,
            frequency: "24:00:00",
        },
    ],
    message: {
        message: "I'm from the server, friend",
        timestamp: new Date(),
    },
    css: `:root {
        --primary-color : #1273E6;
        --primary-color_dark: #0D67C0;
        --secondary-color : #FAFAF9;
        --background-color: #FFF;
        --text-color : #272625;
        --border-color : #CECECD;
        --text-red : 39;
        --text-green : 38;
        --text-blue : 37;
        --text-color_8 : rgba(var(--text-red), var(--text-green), var(--text-blue), 0.8);
        --text-color_6 : rgba(var(--text-red), var(--text-green), var(--text-blue), 0.6);
        --text-color_5 : rgba(var(--text-red), var(--text-green), var(--text-blue), 0.5);
        --text-color_4 : rgba(var(--text-red), var(--text-green), var(--text-blue), 0.4);
        --text-color_2 : rgba(var(--text-red), var(--text-green), var(--text-blue), 0.2);
        --text-opacity : 0.8;
        --border-red : 206;
        --border-green : 206;
        --border-blue : 205;
        --border-color_6 : rgba(var(--border-red), var(--border-green), var(--border-blue), 0.6);
        --border-color_4 : rgba(var(--border-red), var(--border-green), var(--border-blue), 0.4);
        --arrow-color-up: #5EB400;
        --arrow-color-down: #DA224C;
        --light-gray: #f5f5f5;
        --user-color: #A9A8A8; 
    }`,

    analytics: {
        totalPages: 2,
        firstPage: true,
        lastPage: false,
        numberOfElements: 50,
        number: 0,
        totalElements: 61,
        columns: {
            dimension: {
                id: "variables/daterangeday",
                type: "time",
            },
            columnIds: ["177541d4-d339-4628-98ae-48fca4fde2bd"],
        },
        rows: [
            {
                itemId: "1190301",
                value: "Apr 1, 2019",
                data: [0],
            },
            {
                itemId: "1190302",
                value: "Apr 2, 2019",
                data: [0],
            },
            {
                itemId: "1190303",
                value: "Apr 3, 2019",
                data: [0],
            },
            {
                itemId: "1190304",
                value: "Apr 4, 2019",
                data: [0],
            },
            {
                itemId: "1190305",
                value: "Apr 5, 2019",
                data: [0],
            },
            {
                itemId: "1190306",
                value: "Apr 6, 2019",
                data: [0],
            },
            {
                itemId: "1190307",
                value: "Apr 7, 2019",
                data: [0],
            },
            {
                itemId: "1190308",
                value: "Apr 8, 2019",
                data: [0],
            },
            {
                itemId: "1190309",
                value: "Apr 9, 2019",
                data: [0],
            },
            {
                itemId: "1190310",
                value: "Apr 10, 2019",
                data: [0],
            },
            {
                itemId: "1190311",
                value: "Apr 11, 2019",
                data: [0],
            },
            {
                itemId: "1190312",
                value: "Apr 12, 2019",
                data: [0],
            },
            {
                itemId: "1190313",
                value: "Apr 13, 2019",
                data: [0],
            },
            {
                itemId: "1190314",
                value: "Apr 14, 2019",
                data: [0],
            },
            {
                itemId: "1190315",
                value: "Apr 15, 2019",
                data: [0],
            },
            {
                itemId: "1190316",
                value: "Apr 16, 2019",
                data: [0],
            },
            {
                itemId: "1190317",
                value: "Apr 17, 2019",
                data: [0],
            },
            {
                itemId: "1190318",
                value: "Apr 18, 2019",
                data: [0],
            },
            {
                itemId: "1190319",
                value: "Apr 19, 2019",
                data: [0],
            },
            {
                itemId: "1190320",
                value: "Apr 20, 2019",
                data: [0],
            },
            {
                itemId: "1190321",
                value: "Apr 21, 2019",
                data: [0],
            },
            {
                itemId: "1190322",
                value: "Apr 22, 2019",
                data: [0],
            },
            {
                itemId: "1190323",
                value: "Apr 23, 2019",
                data: [0],
            },
            {
                itemId: "1190324",
                value: "Apr 24, 2019",
                data: [0],
            },
            {
                itemId: "1190325",
                value: "Apr 25, 2019",
                data: [0],
            },
            {
                itemId: "1190326",
                value: "Apr 26, 2019",
                data: [1],
            },
            {
                itemId: "1190327",
                value: "Apr 27, 2019",
                data: [0],
            },
            {
                itemId: "1190328",
                value: "Apr 28, 2019",
                data: [1],
            },
            {
                itemId: "1190329",
                value: "Apr 29, 2019",
                data: [7],
            },
            {
                itemId: "1190330",
                value: "Apr 30, 2019",
                data: [11],
            },
            {
                itemId: "1190401",
                value: "May 1, 2019",
                data: [4],
            },
            {
                itemId: "1190402",
                value: "May 2, 2019",
                data: [12],
            },
            {
                itemId: "1190403",
                value: "May 3, 2019",
                data: [14],
            },
            {
                itemId: "1190404",
                value: "May 4, 2019",
                data: [0],
            },
            {
                itemId: "1190405",
                value: "May 5, 2019",
                data: [2],
            },
            {
                itemId: "1190406",
                value: "May 6, 2019",
                data: [17],
            },
            {
                itemId: "1190407",
                value: "May 7, 2019",
                data: [8],
            },
            {
                itemId: "1190408",
                value: "May 8, 2019",
                data: [20],
            },
            {
                itemId: "1190409",
                value: "May 9, 2019",
                data: [17],
            },
            {
                itemId: "1190410",
                value: "May 10, 2019",
                data: [10],
            },
            {
                itemId: "1190411",
                value: "May 11, 2019",
                data: [0],
            },
            {
                itemId: "1190412",
                value: "May 12, 2019",
                data: [0],
            },
            {
                itemId: "1190413",
                value: "May 13, 2019",
                data: [9],
            },
            {
                itemId: "1190414",
                value: "May 14, 2019",
                data: [14],
            },
            {
                itemId: "1190415",
                value: "May 15, 2019",
                data: [6],
            },
            {
                itemId: "1190416",
                value: "May 16, 2019",
                data: [6],
            },
            {
                itemId: "1190417",
                value: "May 17, 2019",
                data: [8],
            },
            {
                itemId: "1190418",
                value: "May 18, 2019",
                data: [3],
            },
            {
                itemId: "1190419",
                value: "May 19, 2019",
                data: [6],
            },
            {
                itemId: "1190420",
                value: "May 20, 2019",
                data: [9],
            },
        ],
        summaryData: {
            totals: [268],
        },
    },
    analyticsLogins: {
        totalPages: 2,
        firstPage: true,
        lastPage: false,
        numberOfElements: 50,
        number: 0,
        totalElements: 55,
        columns: {
            dimension: {
                id: "variables/daterangeday",
                type: "time",
            },
            columnIds: ["5c156ad3-cd80-407d-8a58-ead2d8b87768"],
        },
        rows: [
            {
                itemId: "1190316",
                value: "Apr 16, 2019",
                data: [0],
            },
            {
                itemId: "1190317",
                value: "Apr 17, 2019",
                data: [0],
            },
            {
                itemId: "1190318",
                value: "Apr 18, 2019",
                data: [0],
            },
            {
                itemId: "1190319",
                value: "Apr 19, 2019",
                data: [0],
            },
            {
                itemId: "1190320",
                value: "Apr 20, 2019",
                data: [0],
            },
            {
                itemId: "1190321",
                value: "Apr 21, 2019",
                data: [0],
            },
            {
                itemId: "1190322",
                value: "Apr 22, 2019",
                data: [0],
            },
            {
                itemId: "1190323",
                value: "Apr 23, 2019",
                data: [0],
            },
            {
                itemId: "1190324",
                value: "Apr 24, 2019",
                data: [0],
            },
            {
                itemId: "1190325",
                value: "Apr 25, 2019",
                data: [0],
            },
            {
                itemId: "1190326",
                value: "Apr 26, 2019",
                data: [0],
            },
            {
                itemId: "1190327",
                value: "Apr 27, 2019",
                data: [0],
            },
            {
                itemId: "1190328",
                value: "Apr 28, 2019",
                data: [0],
            },
            {
                itemId: "1190329",
                value: "Apr 29, 2019",
                data: [0],
            },
            {
                itemId: "1190330",
                value: "Apr 30, 2019",
                data: [0],
            },
            {
                itemId: "1190401",
                value: "May 1, 2019",
                data: [0],
            },
            {
                itemId: "1190402",
                value: "May 2, 2019",
                data: [0],
            },
            {
                itemId: "1190403",
                value: "May 3, 2019",
                data: [0],
            },
            {
                itemId: "1190404",
                value: "May 4, 2019",
                data: [0],
            },
            {
                itemId: "1190405",
                value: "May 5, 2019",
                data: [0],
            },
            {
                itemId: "1190406",
                value: "May 6, 2019",
                data: [0],
            },
            {
                itemId: "1190407",
                value: "May 7, 2019",
                data: [0],
            },
            {
                itemId: "1190408",
                value: "May 8, 2019",
                data: [0],
            },
            {
                itemId: "1190409",
                value: "May 9, 2019",
                data: [0],
            },
            {
                itemId: "1190410",
                value: "May 10, 2019",
                data: [0],
            },
            {
                itemId: "1190411",
                value: "May 11, 2019",
                data: [0],
            },
            {
                itemId: "1190412",
                value: "May 12, 2019",
                data: [0],
            },
            {
                itemId: "1190413",
                value: "May 13, 2019",
                data: [0],
            },
            {
                itemId: "1190414",
                value: "May 14, 2019",
                data: [0],
            },
            {
                itemId: "1190415",
                value: "May 15, 2019",
                data: [0],
            },
            {
                itemId: "1190416",
                value: "May 16, 2019",
                data: [0],
            },
            {
                itemId: "1190417",
                value: "May 17, 2019",
                data: [0],
            },
            {
                itemId: "1190418",
                value: "May 18, 2019",
                data: [0],
            },
            {
                itemId: "1190419",
                value: "May 19, 2019",
                data: [0],
            },
            {
                itemId: "1190420",
                value: "May 20, 2019",
                data: [0],
            },
            {
                itemId: "1190421",
                value: "May 21, 2019",
                data: [0],
            },
            {
                itemId: "1190422",
                value: "May 22, 2019",
                data: [0],
            },
            {
                itemId: "1190423",
                value: "May 23, 2019",
                data: [0],
            },
            {
                itemId: "1190424",
                value: "May 24, 2019",
                data: [0],
            },
            {
                itemId: "1190425",
                value: "May 25, 2019",
                data: [0],
            },
            {
                itemId: "1190426",
                value: "May 26, 2019",
                data: [0],
            },
            {
                itemId: "1190427",
                value: "May 27, 2019",
                data: [0],
            },
            {
                itemId: "1190428",
                value: "May 28, 2019",
                data: [0],
            },
            {
                itemId: "1190429",
                value: "May 29, 2019",
                data: [0],
            },
            {
                itemId: "1190430",
                value: "May 30, 2019",
                data: [0],
            },
            {
                itemId: "1190431",
                value: "May 31, 2019",
                data: [0],
            },
            {
                itemId: "1190501",
                value: "Jun 1, 2019",
                data: [0],
            },
            {
                itemId: "1190502",
                value: "Jun 2, 2019",
                data: [0],
            },
            {
                itemId: "1190503",
                value: "Jun 3, 2019",
                data: [0],
            },
            {
                itemId: "1190504",
                value: "Jun 4, 2019",
                data: [612],
            },
        ],
        summaryData: {
            totals: [612],
        },
    },
    gameRules: {
        additional_details: "No additional details.",
        rule_based_scoring:
            "Each behavior listed below has a point value which is based upon your role in the company. In addition to the point value, the frequency which you can receive points for each action is also specified below.",
        leaderboard_rules:
            "The leaderboard shows your position in relation to other people in the company that share your role. The arrow shows if you have trended up or down since your last login. The leaderboard will be reset at the end of each challenge period.",
    },
};

export default MockAPI;
