export default [
	{
		"event_id" : 1,
		"description" : "Log into Oracle",
		"numberOfInstances" : 1,
		"points" : 1
	},
	{
		"event_id": 10,
		"description": "Progress an Opportunity",
		"numberOfInstances": 4,
		"points": 14
	},
	{
		"event_id": 1,
		"description": "Respond to a Chatter Post",
		"numberOfInstances": 3,
		"points": 50
	},
	{
		"event_id": 2,
		"description": "Respond to a Poll",
		"numberOfInstances": 4,
		"points": 19
	},
	{
		"event_id": 3,
		"description": "View a Dashboard",
		"numberOfInstances": 3,
		"points": 67
	},
	{
		"event_id": 6,
		"description": "View a Report",
		"numberOfInstances": 4,
		"points": 25
	}
]