import React from "react";
import "./MobileVersion.scss";
import MobileHead from "../MobileHead";
import Version from "../../cms/Version.js";
import { APIContext } from "../../context/APIProvider";

class MobileVersion extends React.Component {
    static contextType = APIContext;
    render() {
        return (
            <>
                <MobileHead />
                <div className="m-legal-wrapper legal-wrapper">
                    <Version version={this.context.appVersion} versionDate={this.context.appVersionDate} isMobile={true} />
                </div>
            </>
        );
    }
}

export default MobileVersion;
