import React from "react";
import "./MobileScoringRules.scss";
import MobileHead from "../MobileHead";
import Rules from "../Rules/Rules"

class MobileScoringRules extends React.Component {
    render() {
        return (
            <>
                <MobileHead />
                <div className="m-scoring-rules">
                    <Rules />
                </div>
            </>
        );
    }
}

export default MobileScoringRules;
