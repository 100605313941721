import * as React from "react";
import * as d3 from "d3";
import "./Piechart.scss";
import { leaderboardCopy, colorRange } from "../../../utils/cms";
import CurrentScore from "../CurrentScore";
import Tooltip from "../Tooltip/Tooltip";
import Svg from "../Svg";

export default class D3Piechart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            current_selected: null,
            tooltip_type: "",
            tooltip_left: 230,
            tooltip_top: 155,
            tooltip_display: "none",
            tooltip_text: "",
            overflowAuto: false,
        };
        this.piechart = React.createRef();
    }

    handleHover = () => {
        this.setState({ current_selected: null, tooltip_display: "none" });
    };

    handleMove = (d) => {
        let arc = d3.arc().innerRadius(130).outerRadius(110);
        const xoffset = 110;
        const yoffset = 70;
        const left = xoffset + arc.centroid(d)[0];
        const top = yoffset + arc.centroid(d)[1];
        this.setState({
            current_selected: d.index,
            tooltip_display: "inline",
            tooltip_type: d.data.description,
            tooltip_text: `${d.data.pointsEarned} pts (${d.data.label})`,
            tooltip_left: { left: left, xoffset: xoffset },
            tooltip_top: { top: top, yoffset: yoffset },
        });
    };

    handleMoveKey = (index) => {
        const pie = d3
            .pie()
            .value((d) => {
                return d.pointsEarned;
            })
            .sort(null)(this.props.data);
        this.handleMove(pie[index]);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.overflowAuto !== this.props.overflowAuto) {
            this.setState({ overflowAuto: this.props.overflowAuto });
        }
    }

    render() {
        return (
            <>
                <Svg>
                    <g
                        ref={this.piechart}
                        transform={`translate(${250 / 2},${250 / 2})`}
                    >
                        <Slice
                            pie={d3
                                .pie()
                                .value((d) => {
                                    return d.pointsEarned;
                                })
                                .sort(null)(this.props.data)}
                            hover={this.handleHover}
                            move={this.handleMove}
                            colors={colorRange}
                            current={this.state.current_selected}
                        />
                    </g>
                    <CurrentScore currpts={this.props.currpts} />
                </Svg>
                <Tooltip
                    tooltip_top={this.state.tooltip_top}
                    tooltip_left={this.state.tooltip_left}
                    current_selected={this.state.current_selected}
                    tooltip_display={this.state.tooltip_display}
                    tooltip_text={this.state.tooltip_text}
                    tooltip_type={this.state.tooltip_type}
                />
                <span className="behaviors">
                    {leaderboardCopy.pieHeader.toUpperCase()}
                </span>
                <div
                    className={
                        !this.state.overflowAuto
                            ? "keycontainer overflow-auto"
                            : "keycontainer"
                    }
                >
                    {this.props.data.map((element, i) => {
                        return (
                            <div
                                className="key"
                                onMouseMove={this.handleMoveKey.bind(this, i)}
                                onMouseLeave={this.handleHover}
                                key={"keylog" + i}
                                id={`amplifier-piechartKey${i}`}
                            >
                                <span
                                    className="dot"
                                    style={{ backgroundColor: colorRange[i] }}
                                ></span>
                                <span
                                    className={
                                        this.state.current_selected === i
                                            ? "key-label bold"
                                            : "key-label"
                                    }
                                >
                                    {element.description.toUpperCase()}
                                </span>
                            </div>
                        );
                    })}
                </div>
            </>
        );
    }
}

const Slice = (props) => {
    let { pie, colors, hover, move } = props;

    let arc = d3.arc().innerRadius(130).outerRadius(110);

    return pie.map((slice, index) => {
        return (
            <g key={`slice${index}`}>
                <path
                    d={arc(slice)}
                    id={`amplifier-piechartSlice${index}`}
                    pointerEvents="fill"
                    fill={colors[index]}
                    onMouseLeave={hover}
                    onMouseMove={move.bind(this, slice)}
                />
            </g>
        );
    });
};
