import "./ModuleError.scss";

import React from "react";

import { SomethingWrong } from "../../assets/icons/icons";

const ModuleError = ({ onRefresh }) => {
    return (
        <div className="error-box">
            <SomethingWrong />
            <div className="err-text">
                <p>Sorry… something went wrong.</p>
                <p>Try refreshing in a few minutes.</p>
            </div>
            <button className="err-refresh" onClick={onRefresh}>
                Refresh
            </button>
        </div>
    );
};

export default ModuleError;
