import React from "react";
import "./icons.scss";
import { ReactComponent as AnalyticsSVG } from "./analytics.svg";
import { ReactComponent as RulesSVG } from "./rules.svg";
import { ReactComponent as SpeechSVG } from "./speech.svg";
import { ReactComponent as TriangleSVG } from "./triangle.svg";
import { ReactComponent as WhySVG } from "./why.svg";
import { ReactComponent as ArrowSVG } from "./arrow.svg";
import { ReactComponent as LinkSVG } from "./link.svg";
import { ReactComponent as DurationSVG } from "./duration.svg";
import { ReactComponent as FlashlightSVG } from "./flashlight.svg";
import { ReactComponent as UserSVG } from "./user.svg";
import { ReactComponent as LeaderboardSVG } from "./leaderboard.svg";
import { ReactComponent as SearchSVG } from "./search.svg";
import { ReactComponent as CompletionSVG } from "./completionMark.svg";
import { ReactComponent as TrendingSVG } from "./trendingArrow.svg";
import { ReactComponent as TrendingDownSVG } from "./trendingDown.svg";
import { ReactComponent as TrendingUpSVG } from "./trendingUp.svg";
import { ReactComponent as ScoringSVG } from "./scoringUser.svg";
import { ReactComponent as ErrorSVG } from "./error.svg";
import { ReactComponent as AlertSVG } from "./alert.svg";
import { ReactComponent as CloseSVG } from "./close.svg";
import { ReactComponent as SubmissionSVG } from "./submission.svg";
import { ReactComponent as SendArrowSVG } from "./sendArrow.svg";
import { ReactComponent as CompletitionMarkChallenge } from "./completitionMarkChallenge.svg";
import { ReactComponent as CheckActiveSVG } from "./check-active.svg";
import { ReactComponent as CheckInactiveSVG } from "./check-inactive.svg";
import { ReactComponent as AdminSVG } from "./admin.svg";
import { ReactComponent as SomethingWrongSVG } from "./somethingWrong.svg";
import { ReactComponent as RightArrowSVG } from "./right-arrow.svg";
import { ReactComponent as LeftArrowSVG } from "./left-arrow.svg";

const CheckActive = () => (
    <CheckActiveSVG />
);

const CheckInactive = () => (
    <CheckInactiveSVG />
);


const Error = () => (
    <ErrorSVG />
);

const Alert = () => (
    <AlertSVG />
);

const Close = () => (
    <CloseSVG />
);

const Clock = () => (
    <AnalyticsSVG />
);

const Rules = () => (
    <RulesSVG />
);


const SetSquare = () => (
    <TriangleSVG />
);

const SpeechBubble = () => (
    <SpeechSVG />
);

const Why = () => (
    <WhySVG />
);

const Submission = () => (
    <SubmissionSVG />
);

const Bolt = (props) => (
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" aria-labelledby="title"
        aria-describedby="desc" className={props.className} role="img">
        <title>Thunder</title>
        <desc>A line styled icon from Orion Icon Library.</desc>
        <path d="M41 6L13 34h14.187L23 58l27.998-29.999H37L41 6z"
            strokeWidth="2" strokeMiterlimit="10" stroke="inherit" fill="inherit"
            strokeLinejoin="round" strokeLinecap="round"></path>
    </svg>
);

const Arrow = () => (
    <ArrowSVG />
);

const Link = () => (
    <LinkSVG />
);

const Duration = () => (
    <DurationSVG />
);

const User = () => (
    <UserSVG />
);

const Flashlight = () => (
    <FlashlightSVG />
);

const Leaderboard = () => (
    <LeaderboardSVG />
);

const Search = () => (
    <SearchSVG />
);

const CompletionMark = () => (
    <CompletionSVG />
);

const TrendingArrow = () => (
    <TrendingSVG />
);

const TrendingDown = () => (
    <TrendingDownSVG />
);

const TrendingUp = () => (
    <TrendingUpSVG />
);

const Scoring = () => (
    <ScoringSVG />
);

const SendArrow = () => (
    <SendArrowSVG />
);

const CompletitionChallenge = () => (
    <CompletitionMarkChallenge />
);

const Admin = () => (
    <AdminSVG />
);

const SomethingWrong = () => (
    <SomethingWrongSVG />
);

const RightArrow = () => <RightArrowSVG/>
const LeftArrow = () => <LeftArrowSVG/>

export {
    Clock,
    SpeechBubble,
    Rules,
    SetSquare,
    Bolt,
    Why,
    Arrow,
    Link,
    Duration,
    User,
    Leaderboard,
    Flashlight,
    Search,
    CompletionMark,
    TrendingArrow,
    TrendingUp,
    TrendingDown,
    Scoring,
    Error,
    Alert,
    Close,
    Submission,
    SendArrow,
    CompletitionChallenge,
    CheckActive,
    CheckInactive,
    Admin,
    SomethingWrong,
    RightArrow,
    LeftArrow
};