import React from "react";
import moment from "moment";

import RuleCard from "../RuleCard";
import MockAPI from "../../../mocks/API";
import { Duration, Scoring, Leaderboard } from "../../../assets/icons/icons";
import { rulesCopy } from "../../../utils/cms";
import { APIContext, httpVerb } from "../../../context/APIProvider";
import { GAMERULES } from "../../../services/endpoints/endpoints";
import { isStaticEnvironment } from "../../../utils/reusableFunctions";

export default class Rules extends React.Component {
    static contextType = APIContext;

    constructor(props) {
        super(props);

        this.challenge_description = "";
        this.campaignName = "";
        this.mount = true;
        this.state = {
            ruleCards: [
                {
                    icon: Duration,
                    title: rulesCopy.ruleInstruction1Header,
                    copy: rulesCopy.ruleInstruction1Copy,
                },
                {
                    icon: Scoring,
                    title: rulesCopy.ruleInstruction2Header,
                    copy: rulesCopy.ruleInstruction2Copy,
                },
                {
                    icon: Leaderboard,
                    title: rulesCopy.ruleInstruction3Header,
                    copy: rulesCopy.ruleInstruction3Copy,
                },
            ],
        };
    }

    componentDidMount() {
        const t = this;

        if (isStaticEnvironment()) {
            const campaign = MockAPI.campaign[0];
            const { ruleCards } = this.state;
            const tempEndDate = campaign?.campaignUTCEnd
                ?.split("-")
                ?.reverse()
                ?.join("-");

            t.challenge_description = campaign.challenge_description;
            t.campaignName = campaign.campaignName;

            ruleCards[0].copy = ruleCards[0].copy
                .replace("<days>", campaign.gameDuration)
                .replace("[score]", campaign.campaignChallengeScore)
                .replace(
                    "{end}",
                    `${moment(tempEndDate)?.format("MMM. D, YYYY")}`,
                );

            if (this.mount) this.setState({ ruleCards });

            ruleCards[0].copy =
                ruleCards[0].copy +
                "<br/><br/>" +
                MockAPI.gameRules.additional_details;
            ruleCards[1].copy = MockAPI.gameRules.rule_based_scoring;
            ruleCards[2].copy = MockAPI.gameRules.leaderboard_rules;

            if (this.mount) this.setState({ ruleCards });

            return;
        }

        const { campaign = {} } = t.context || {};
        const { ruleCards } = this.state;
        const tempEndDate = campaign?.campaignUTCEnd
            ?.split("-")
            ?.reverse()
            ?.join("-");

        t.challenge_description = campaign.challenge_description;
        t.campaignName = campaign.campaignName;

        ruleCards[0].copy = ruleCards[0].copy
            .replace("<days>", campaign.gameDuration)
            .replace("[score]", campaign.campaignChallengeScore)
            .replace("{end}", `${moment(tempEndDate)?.format("MMM. D, YYYY")}`);

        if (this.mount) this.setState({ ruleCards });

        try {
            this.context
                .httpRequest(GAMERULES, httpVerb.GET)
                .then((res) => {
                    if (!res || (res.success && res.success === "false")) {
                        return;
                    }

                    ruleCards[0].copy =
                        ruleCards[0].copy +
                        "<br/><br/>" +
                        res.additional_details;
                    ruleCards[1].copy = res.rule_based_scoring;
                    ruleCards[2].copy = res.leaderboard_rules;

                    if (this.mount) this.setState({ ruleCards });
                })
                .catch((err) => {});
        } catch (error) {}
    }

    componentWillUnmount() {
        this.mount = false;
    }

    render() {
        const { ruleCards } = this.state;

        return (
            <div className="scoringrules--top main--content" tabIndex="0">
                <h1>Challenge Name: {this.campaignName}</h1>
                <h2>{this.challenge_description}</h2>
                <div className="rulecard--row">
                    {ruleCards?.map((content, i) => {
                        return (
                            <RuleCard
                                className="rulecard--column"
                                key={"ruleCard" + i}
                                icon={content.icon}
                                title={content.title}
                                copy={content.copy}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}
