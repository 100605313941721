/**
 * handleMaxUsers
 * @description Handles an array of users and returns a subarray based on the position of the current user.
 * @param {Array} array - The array of user objects.
 * @returns {Array} A subarray of users based on the position of the current user.
 */

const handleMaxUsers = (array) => {
    if (Array.isArray(array)) {
        const index = array.findIndex(function (user) {
            return user.isCurrentUser === "true";
        });

        if (index <= 3) {
            return array.slice(0, 7);
        } else if (index >= array.length - 3) {
            return array.slice(array.length - 7, array.length);
        } else {
            return array.slice(index - 3, index + 4);
        }
    }

    return [];
};

export { handleMaxUsers };
