import React from "react";

const Privacy = (props) => {
    return (
        <>
            <h4>
                <strong>Amplifier Privacy Statement</strong>
            </h4>
            <h4>Last updated: May 2023</h4>
            <h4>
                <strong>Introduction</strong>
            </h4>
            <p>
                Amplifier is an offering of PwC Product Sales LLC (“PwC”), a
                member of the PricewaterhouseCoopers global network of firms.
                Each PricewaterhouseCoopers member firm is a separate legal
                entity. For further details, please see{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="http://www.pwc.com/structure"
                >
                    www.pwc.com/structure.
                </a>
            </p>
            <p>
                PwC is committed to protecting your privacy. This privacy
                statement explains our information collection practices with
                respect to Amplifier (the “Application”) and how we use, share,
                and protect that information.{" "}
            </p>
            <p>
                Note, this privacy statement applies solely to the Application
                and does not apply to other PwC websites or applications.
            </p>
            <h4>
                <strong>Information Collection</strong>
            </h4>
            <p>
                When you access or otherwise use our Application, we will
                collect information about you and how you interact with our
                Application. We collect information about you through: (1)
                information you provide; (2) your device; and (3) third parties,
                namely your employer or other company, institution, or
                organization with which you are affiliated, as applicable (your
                “Organization”).
            </p>
            <p>
                <strong>Information You Provide</strong>
            </p>
            <p>
                When you access the Application, you may choose to provide
                information about yourself such as: identifiers and contact
                information including your name, email address, job code,
                business location, and business name. By way of example, you may
                choose to provide your information in the following
                circumstances:
            </p>
            <ul>
                <li>Registration for or otherwise accessing the Application</li>
                <li>Requesting support</li>
            </ul>
            <p>
                PwC does not intend to collect sensitive personal information
                about you through the Application. Sensitive personal
                information includes a number of types of data relating to: race
                or ethnic origin; political opinions; religious or other similar
                beliefs; trade union membership; physical or mental health;
                sexual life or criminal record. Please do not submit sensitive
                personal information through the Application.
            </p>
            <p>
                <strong>Automatically Collected Information</strong>
            </p>
            <p>
                We may use online identification technologies, such as cookies,
                localstorage, or similar technologies (“Cookies”) on our
                Application. The information we collect using these technologies
                includes identifiers and internet activity information such as:
                user information and access time. For more information about the
                Cookies used on the Application, please&nbsp;review our{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.pwc.com/us/en/site-information.html"
                >
                    Cookie Disclosure.
                </a>{" "}
            </p>

            <h4>
                <strong>Information Use</strong>
            </h4>
            <p>
                We use the information we collect as described above for various
                purposes depending on the context in which we collected such
                information. For example, it may be necessary for us to process
                your information in order to process a transaction or provide a
                service you have requested or otherwise in accordance with a
                contract between us, or we may process your information in
                accordance with your prior consent (where you have provided such
                consent).
            </p>
            <p>
                We use the information we collect for the following business
                purposes:
            </p>
            <ul>
                <li>To register to the Application;</li>
                <li>
                    To operate, maintain, customize, analyze and improve the
                    Application;
                </li>
                <li>
                    To maintain and improve the safety and security of the
                    Application, and to run our internal operations;
                </li>
                <li>
                    To prevent and enhance protection against fraud, spam,
                    harassment, intellectual property infringement, crime, and
                    security risks;
                </li>
                <li>
                    To comply with law, rules, regulations, legal obligations,
                    professional and industry standards, as well as respond to
                    and complying with subpoena, search warrant or other legal
                    process, including establishing, exercising, or defending
                    our legal rights;
                </li>
                <li>
                    To fulfill your orders/requests and respond to your
                    inquiries;
                </li>
                <li>
                    To send you service-related notifications or communications.
                </li>
            </ul>
            <p>
                We may also use the information we collect to create aggregate
                or anonymized information, which we may use to build upon and
                improve the Application and our other products and services as
                well as for other lawful business purposes.
            </p>
            <h4>
                <strong>Information Sharing</strong>
            </h4>
            <p>
                As we are administering this Application to you on behalf of
                your Organization, we share the information we collect through
                the Application with your Organization. Each Organization using
                the Application is responsible for its handling of information
                collected by the Application in accordance with its internal
                policies and applicable law.
            </p>
            <p>
                We are part of a global network of firms. In common with other
                professional service providers, PwC may transfer or disclose the
                information it collects, including your information, to third
                party contractors, subcontractors, subsidiaries, and/or other
                PwC firms for the purposes for which you have submitted the
                information and for the administration of our system or
                Application and/or other internal, administrative purposes. We
                transfer your information to third party service providers of
                identity management, website hosting and management, data
                analysis, data backup, security, and storage services.
            </p>
            <p>
                PwC may also disclose information to third parties under the
                following circumstances:
            </p>
            <ul>
                <li>When explicitly requested by a user;</li>
                <li>
                    As necessary to comply with law, rules, regulations, legal
                    obligations, professional and industry standards, as well as
                    respond to and complying with subpoena, search warrant or
                    other legal process, including establishing, exercising, or
                    defending our legal rights;
                </li>
                <li>
                    As necessary in connection with a corporate reorganization,
                    merger, sale, joint venture or other disposition of all or
                    any portion of our business, assets or capital.
                </li>
            </ul>
            <p>
                We may also share aggregate information about Application users
                with third parties for any lawful business purpose. We do not
                sell personal information collected in connection with the
                Application nor do we disseminate personal information collected
                in connection with the Application to outside parties for
                consumer marketing purposes or host mailings on behalf of third
                parties.
            </p>
            <h4>
                <strong>Information Transfer</strong>
            </h4>
            <p>
                Your information may be transferred outside of the U.S. or your
                country of residence. This includes to countries outside the
                European Economic Area (“EEA”) and to countries that do not have
                laws that provide the same degree of protection for personal
                information. In accordance with applicable legal requirements,
                we take appropriate measures to facilitate adequate protection
                for any information so transferred.
            </p>
            <p>
                Where we transfer personal information outside of the EEA to a
                country or framework not determined by the European Commission
                as providing an adequate level of protection for personal
                information, the transfers will be under an agreement which
                covers European Union requirements for such transfers, such as
                standard contractual clauses.{" "}
            </p>
            <p>
                Although not currently relied upon as an authorized mechanism
                for cross border transfers, PricewaterhouseCoopers LLP and its
                affiliated U.S. subsidiaries also adhere to the privacy
                principles in the EU - U.S. Privacy Shield Framework and the
                Swiss - U.S. Privacy Shield Framework as set forth by the U.S.
                Department of Commerce. To learn more, see our{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.pwc.com/us/en/site/privacy-shield.html"
                >
                    Privacy Shield Policy
                </a>
                .
            </p>
            <h4>
                <strong>Information Retention</strong>
            </h4>
            <p>
                We will retain the personal information collected by us through
                the Application for as long as is necessary for the purpose(s)
                for which it was collected as set out in this privacy statement,
                provided that personal information may be held for longer
                periods where extended retention periods are required by law,
                regulation or professional standards, and in order to establish,
                exercise or defend our legal rights.
            </p>
            <h4>
                <strong>Your Choices</strong>
            </h4>
            <p>
                <strong>Unsubscribe</strong>
            </p>
            <p>
                Should you wish to unsubscribe from our mailing list or any
                registrations, you may contact us at:
                us_amplifier_contactus@pwc.com.
            </p>
            <p>
                <strong>Managing Cookies</strong>
            </p>
            <p>
                If you are concerned about cookies, most browsers permit
                individuals to decline cookies. In most cases, you may refuse or
                delete one or more cookies and still access the Application, but
                Application functionality may be impaired. After you finish
                browsing this Application, you may delete Application cookies
                from your system if you wish. For more information about how to
                manage your cookie preferences, please&nbsp;review our{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.pwc.com/us/en/site-information.html"
                >
                    Cookie Disclosure.
                </a>
            </p>
            <p>
                <strong>Do Not Track</strong>
            </p>
            <p>
                Do Not Track (&ldquo;DNT&rdquo;) is a privacy preference that
                you can set in your web browser to send a message to the website
                operator requesting not to be tracked. Currently, we do not
                respond to these DNT signals. For more information about DNT,
                visit{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://allaboutdnt.com/"
                >
                    https://allaboutdnt.com/
                </a>
                .
            </p>
            <p>
                <strong>Rights</strong>
            </p>
            <p>
                Depending on the jurisdiction in which you are located, you may
                have certain rights with respect to your personal information.
                Subject to applicable law, these rights may include the right
                to:
            </p>
            <ul>
                <li>
                    Know what personal information about you we have collected,
                    disclosed, and/or sold, as applicable;
                </li>
                <li>Obtain a copy of your personal information;</li>
                <li>Delete your personal information;</li>
                <li>
                    Direct us not to sell your personal information (but as
                    noted above, we do not sell personal information collected
                    about you in connection with the Application);
                </li>
                <li>Correct or update your personal information;</li>
                <li>Restrict the processing of your personal information;</li>
                <li>Object to the processing of your personal information;</li>
                <li>
                    Withdraw your consent for processing (where processing is
                    based on your consent). Please note that if you withdraw
                    your consent, we may not be able to provide you the services
                    for which it was necessary to process your information based
                    on your consent; and
                </li>
                <li>
                    Exercise your privacy rights without discriminatory
                    treatment from us.
                </li>
            </ul>
            <p>
                To exercise any of the rights described above, please submit a
                verifiable request to us by either using our{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://privacyportal-eu-cdn.onetrust.com/dsarwebform/f13f2198-97ab-4c25-a5cd-0fca8ada2e21/eb60a416-648e-47a1-914f-4dc7fba2ce27.html"
                >
                    request form
                </a>{" "}
                or calling us at 1-888-438-4427.
            </p>
            <p>
                The verifiable request must describe your request with
                sufficient detail that allows us to properly understand,
                evaluate, and respond to it. Further, you must provide us with
                sufficient information that allows us to reasonably verify you
                are the person about whom we collected personal information or
                an authorized representative. Please, however, refrain from
                sending us sensitive personal information. Where permitted by
                law, you may use an authorized agent to submit a request on your
                behalf if you have provided the authorized agent signed
                permission to do so.
            </p>
            <p>
                We may not be able to fulfill your request if we cannot verify
                your identity or authority to make the request and confirm the
                personal information relates to you. Making a verifiable request
                does not require you to create an account with us. We will only
                use personal information provided in a verifiable request to
                verify the requestor’s identity or authority to make the
                request.
            </p>
            <p>
                We will respond to your request within a reasonable timeframe in
                accordance with applicable law. We reserve the right to charge
                an appropriate fee for complying with a request where permitted
                by applicable law, and to deny a request where it may be
                manifestly unfounded, excessive, or unwarranted under applicable
                law.
            </p>
            <p>
                We hope that you won’t ever need to, but if you do want to
                complain about our use of personal information, please send us a
                message with the details of your complaint using our{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.pwc.com/us/en/forms/us-privacy-office-contact.html"
                >
                    general contact form.
                </a>{" "}
                You may also contact our EU Representative, PwC Malta, by
                completing our{" "}
                <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.pwc.com/us/en/forms/contact-eu-representative.html"
                >
                    EU Representative contact form.
                </a>{" "}
                Applicable laws may also give you the right to lodge a complaint
                with the data protection authority in your country.
            </p>
            <h4>
                <strong>Security</strong>
            </h4>
            <p>
                PwC has implemented generally accepted standards of technology
                and operational security designed to protect personal
                information from unauthorized access, loss, misuse, alteration,
                or destruction. Only authorized PwC personnel and the third
                parties described in this privacy statement are provided access
                to personal information and these employees and third parties
                have agreed to maintain the confidentiality of this information.
            </p>
            <h4>
                <strong>Third Party Sites</strong>
            </h4>
            <p>
                This Application may link to other websites which do not operate
                under PwC's privacy practices. When you link to other websites,
                PwC's privacy practices no longer apply. We encourage you to
                review the privacy statement of each website you visit before
                disclosing any personal information.
            </p>
            <h4>
                <strong>Children</strong>
            </h4>
            <p>
                PwC understands the importance of protecting children's privacy,
                especially in an online environment. The Application is not
                intentionally designed for or directed at children under the age
                of 13 years. It is PwC's policy never to knowingly collect or
                maintain personal information about children under the age of
                13.
            </p>
            <h4>
                <strong>Modifications</strong>
            </h4>
            <p>
                PwC may update this privacy statement at any time by publishing
                an updated version here. You can access the most current version
                of this privacy statement at any time on this Application.
            </p>
            <h4>
                <strong>Contact Us</strong>
            </h4>
            <p>
                If you have questions about this privacy statement or about our
                privacy practices, please contact us at{" "}
                <strong>us_amplifier_contactus@pwc.com</strong>.
            </p>
        </>
    );
};

export default Privacy;
