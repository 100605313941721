import React from "react";
import "./Input.scss";
import { Search } from "../../assets/icons/icons";

const Input = props => 
    <div className="relative">
        {props.children}
        <input placeholder={props.placeholder} 
            aria-label={props.placeholder}
            onChange={props.onchange} 
            className={"main--input " + props.className}
            value={props.value}/>
        <label className={(props.islarge) ? "input--magnifying input--magnifying__big" : "input--magnifying"}><Search/></label>
    </div>;

export default Input;