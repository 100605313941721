import React from "react";
import numberWithCommas from "../../../utils/numberWithCommas";
import { TrendingDown } from "../../../assets/icons/icons";
import { TrendingUp } from "../../../assets/icons/icons";
import Skeleton from "../../Skeleton/Skeleton";
import UserImg from "../UserImg/UserImg";


const RankingLine = (props) => {
    let { user, index } = props;
    let isCurrUser = user.isCurrentUser === "true";

    return (
        <li className={(isCurrUser) ? "rankingTable--row rankingTable--row__current" : "rankingTable--row"} 
            key={"user"+index}>
            {
                isCurrUser && user.rankDiff > 0 &&
                <div className="arrow--position arrow--up"><TrendingUp /></div>
            }
            {
                isCurrUser && (user.rankDiff < 0) &&
                <div className="arrow--position arrow--down"><TrendingDown /></div>
            }
            {(user.rank === null) ? <span style={{width: "30px", marginLeft: "15px"}}><Skeleton height="10px"/></span>: <span className="rankingTable--row__first">{numberWithCommas(user.rank)}</span>}

            <UserImg picUrl={user.picUrl}/>
            
            {/* {(user.picUrl === null || user.picUrl === undefined) ? <User/> : <img src={user.picUrl} alt="users profile pic"/>} */}
            <span>
                {(isCurrUser) ? "YOU" : (user.name === null) ? 
                    <span><Skeleton height="10px" width="90px"/></span> : 
                    <span>{user.name}</span>}
            </span>
            {(user.rank === null) ? 
                <span style={{float: "right", marginTop: "7px"}}><Skeleton height="10px" width="48px"/></span> : 
                <span className="rankingtable--points" id={isCurrUser ? "amplifier-leaderboardUserPoints" : undefined}>
                    {numberWithCommas(user.score)} pts
                </span>}
        </li>
    );
};

export default RankingLine;
