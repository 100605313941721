import React from "react";
import './comingsoon.scss'

const ShortcutsComingSoon = (props) => (
    <div className="comingsoon newuser">
        <Title />
        <div className="shortcutscomingsoon">
            <LoadingText shortcutName={props.shortcutName}/>
            <button className="btn btn__primary access" onClick={props.onClick}>Back</button>
        </div>
    </div>
);

const Title = () => {
    return <h1 className="title">Action Coming Soon</h1>;
};

const LoadingText = (props) => {
    return(
        <p className="comingsooncopy">The guide for {props.shortcutName} is currently being setup, please check back later.</p>
    );
};

export default ShortcutsComingSoon;