import React from "react";
import "./MobileAllActions.scss";
import MobileHead from "../MobileHead";
import AllShortcuts from "../../components/Shortcuts/AllShortcuts";

class MobileMyActions extends React.Component {
    render() {
        return (
            <>
                <MobileHead />
                <div className='mobile-view shortcuts-m'>
                    <AllShortcuts></AllShortcuts>
                </div>
            </>
        );
    }
}

export default MobileMyActions;
