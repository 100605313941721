import React from "react";
import  "./Svg.scss";

const Svg = (props) => {
    // let { children } = props;
    return (
        <svg className="svg" viewBox={"0 0 250 250"}>
            {props.children}
        </svg>
    );
};

export default Svg;