import { isStaticEnvironment } from "./reusableFunctions";

/**
 * hasPermissions
 * @description Checks if has permission
 * @param {array} permissions - Array of permissions
 * @param {string} value - Permission name
 * @param {string} key - Key of the permission in object
 * @returns {boolean}
 */
const hasPermissions = (permissions, value, key = "Value") => {
    if (isStaticEnvironment()) return true;

    if (Array.isArray(permissions)) {
        const hasPermission = permissions.findIndex(p => p[key] === value);
        return !!(hasPermission !== -1);
    }

    return false;
};

/**
 * isRestrictedRoute
 * @description Returns true if has permission for the route, otherwise false.
 * @param {array} permissions - Array of permissions
 * @param {array} routes - Array of routes
 * @param {string} route - Current route (path)
 * @returns {boolean}
 */
const isRestrictedRoute = (permissions, routes, route) => {
    if (isStaticEnvironment()) return false;

    const routeData = routes.find(r => r.items.some(item => item.path === route));

    if (!routeData) {
        // If the route is not found in the routes data, it's not restricted
        return false;
    }

    const requiredPermission = routeData.permissionName;
    const hasPermission = permissions.some(p => p.Value === requiredPermission);

    return !hasPermission;
};

/**
 * checkIsNoscoreRedirect
 * @description Checks if redirection should be done depending on Noscore status
 * @param {string} isNoscore Y / N
 * @param {string} route Current route
 * @returns {boolean}
 */
const checkIsNoscoreRedirect = (isNoscore, route) => {
    return (isNoscore === "N" && route === '/dashboard')
}

export {
    checkIsNoscoreRedirect,
    hasPermissions,
    isRestrictedRoute,
}