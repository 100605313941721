import React from "react";
import "./TitleCard.scss";
import {Arrow} from "../../../assets/icons/icons";

const TitleCard = props => 
    <h1 className="title-card h1">
        { (props.canGoBack) && 
            <button onClick={props.navigateback} title="Hide Amplifier"><Arrow/></button>
        }
        {props.children}
    </h1>;

export default TitleCard;