import React from "react";
import "./AllShortcuts.scss";
import Input from "../../Input/Input";
import { shortcutsCopy } from "../../../utils/cms";
import { APIContext, httpVerb } from "../../../context/APIProvider";
import { createShortcuts } from "../../../utils/shortcutFunctions";
import { loadGuide } from "../../../utils/guideFunctions";
import { SHORTCUTS } from "../../../services/endpoints/endpoints";
import ModuleError from "../../ModuleError";
import { isMobile } from "react-device-detect";
import ModalWindow from "../../ModalWindow";
import ShortcutsComingSoon from "../ShortcutsComingSoon";
import { isStaticEnvironment } from "../../../utils/reusableFunctions";
import MockShortcutsAll from "../../../mocks/shortcuts-all";

const shortcutsMock = {
    name: "",
    points: 0,
};

export default class Shortcuts extends React.Component {
    static contextType = APIContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            shortcuts: new Array(8).fill(shortcutsMock),
            shortcutsError: false,
            searchedShortcuts: [],
            inputValue: "",
            shortcutModal: false,
            modalShortcutName: "",
        };
        this.filterListShortcuts = this.filterListShortcuts.bind(this);
        this.triggerModal = this.triggerModal.bind(this);
        this.infoGuide = this.infoGuide.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.getShortcuts();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getShortcuts = () => {
        const t = this;

        if (isStaticEnvironment()) {
            t.setState({ shortcutsError: false });
            t.setState({ shortcuts: MockShortcutsAll });

            return;
        }

        t.context
            .httpRequest(SHORTCUTS, httpVerb.GET)
            .then((res) => {
                if (t._isMounted) {
                    if (!res || (res.success && res.success === "false")) {
                        t.setState({ shortcutsError: true });
                    } else {
                        t.setState({ shortcutsError: false });
                        t.setState({ shortcuts: res });
                    }
                }
            })
            .catch((err) => {
                t.setState({ shortcutsError: true });
                return err;
            });
    };

    filterListShortcuts = (event) => {
        clearTimeout(this.timer);

        if (event.target.value.length === 0) {
            this.setState({
                searchedShortcuts: this.state.shortcuts,
                inputValue: "",
            });
            return;
        }
        let updatedList = Object.assign(this.state.shortcuts, []);
        updatedList = updatedList.filter(function (item) {
            return (
                item.name
                    .toLowerCase()
                    .search(event.target.value.toLowerCase()) !== -1
            );
        });

        this.setState({
            searchedShortcuts: updatedList,
            inputValue: event.target.value,
        });
    };

    triggerModal = (displayValue = "") => {
        try {
            this.setState({
                modalShortcutName: displayValue,
                shortcutModal: !this.state.shortcutModal,
            });
        } catch (e) {
            alert(`triggerModal error: (${e.message})`);
        }
    };

    infoGuide = (props) => {
        props.parent = this;
        loadGuide(props);
    };

    render() {
        return (
            <div className="allshortcuts">
                {this.state.shortcutModal && (
                    <ModalWindow
                        onEscapeModal={() => {
                            this.setState({ shortcutModal: false });
                        }}
                    >
                        <ShortcutsComingSoon
                            percentage={this.state.loadpercent}
                            onClick={this.triggerModal}
                            shortcutName={this.state.modalShortcutName}
                        />
                    </ModalWindow>
                )}

                <div className="searchTable p-t-10 searchTable--search">
                    <Input
                        placeholder={shortcutsCopy.inputPlaceholder}
                        onchange={this.filterListShortcuts}
                        value={this.state.inputValue}
                    />
                </div>
                <div
                    className={
                        isMobile
                            ? "searchTable--table-m"
                            : "searchTable searchTable__fullTable searchTable--table"
                    }
                >
                    {this.state.shortcutsError ? (
                        <ModuleError onRefresh={this.getShortcuts} />
                    ) : (
                        <table>
                            <tbody>
                                {createShortcuts(
                                    this.state.inputValue.length === 0
                                        ? this.state.shortcuts
                                        : this.state.searchedShortcuts,
                                    this.triggerModal,
                                    this.infoGuide
                                )}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        );
    }
}
