import React from "react";
import "./ShortcutCard.scss";
import Skeleton from "../../Skeleton/Skeleton";
import Checkbox from "../../Checkbox/Checkbox";

const ShortcutCard = (props) => (
    <button
        type="button"
        className="shortcut"
        title={props.title || "loading shortcut"}
        onClick={props.checkboxOnClick}
    >
        {props.checkbox ? <Checkbox selected={props.selected} /> : ""}
        {props.title === null || props.title === "" ? (
            <Skeleton className="shortcut--title" width="100px" height="10px" />
        ) : (
            <p
                className={
                    props.checkbox ? "shortcut--title super" : "shortcut--title"
                }
            >
                {props.title}
            </p>
        )}
        {props.points === null ? (
            "\u00A0"
        ) : (
            <p className="shortcut--points">{props.points} pts</p>
        )}
    </button>
);

export default ShortcutCard;