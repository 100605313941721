export default [
	{
		id: 138,
		name: "Acknowledge Performance Document",
		description: `When the employee acknowledges the performance document, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "x5fda46b",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 150,
		name: "Add a Check-in - Employee",
		description: `When an employee creates a check-in for himself, the employee should be rewarded with points. Points are only rewarded once for each check-in.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "mvv06nf1",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 149,
		name: "Add a Check-in - Manager",
		description: `When a manager creates a check-in for his direct reports, the manager should be rewarded with points. Points are only rewarded once for each check-in.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "pfldj92n",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 180,
		name: "Add a Contingent Worker - HR Specialist",
		description: `When an HR Specialist hires a contingent worker, the HR should be rewarded with points. Points are rewarded once for each worker.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "rdxzqk2w",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 187,
		name: "Add a Global Temporary Assignment - HR Specialist",
		description: `When an HR Specialist adds a global temporary assignment for an employee, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "9cuozqfs",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 179,
		name: "Add a Nonworker - HR Specialist",
		description: `When an HR Specialist adds a nonworker, the HR should be rewarded with points. Points are rewarded once for each worker.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "m0vg2yj0",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 181,
		name: "Add a Pending Worker - HR Specialist",
		description: `When an HR Specialist hires a pending worker, the HR should be rewarded with points. Points are rewarded once for each worker.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "35usunnq",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 185,
		name: "Add a Responsibility - HR Specialist",
		description: `When an HR Specialist adds an area of responsibility for an employee, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "qtsbmu3s",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 170,
		name: "Add Assignment - HR Specialist",
		description: `When an HR Specialist adds additional assignment to an employee, the HR should be rewarded with points. Points are rewarded once for each assignment.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "ho10a2qo",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 160,
		name: "Add Candidate to Candidate Pool - Manager",
		description: `When a manager adds a candidate to a candidate pool, the manager should be rewarded with points.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "aflgauv9",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 159,
		name: "Add Candidate to Job Requisition - Manager",
		description: `When a manager adds a candidate to a requisition, the manager should be rewarded with points. Points are rewarded for each application.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "6ary6jz4",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 145,
		name: "Add Development Goal for Direct Reports",
		description: `When a manager creates a new development goal for his direct reports, the manager should be rewarded with points. The points can only be rewarded once for each goal.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "nlbinrt8",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 19,
		name: "Add Document Record",
		description: `When the user adds a new document record of any document type, they should be rewarded with points. Points are only rewarded for new document uploads, not updates to existing documents`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 55,
		name: "Add Emergency Contact",
		description: `When an employee adds an emergency contact, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "e8gdo9g3",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 200,
		name: "Add Interactions with Candidate - Manager",
		description: `User logs into Oracle and adds interactions with candidate`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "hsz1uges",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 157,
		name: "Add Measurements and Success Criteria to Goal",
		description: `When an employee adds a measurement to the goal, they should be rewarded with points. Points are only rewarded for a new measurement creation.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "mts3r17d",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 195,
		name: "Add new Task for Employee - HR Specialist",
		description: `When an HR Specialist adds a new task for an employee, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "lnu0qf1w",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 133,
		name: "Add Performance Goal for Direct Reports",
		description: `When a manager adds a performance goal to his direct reports, they should be rewarded with points.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "ejo8wv5z",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 131,
		name: "Add Performance Goal From Scratch",
		description: `When an employee adds a performance goal, they should be rewarded with points. Points are only rewarded for a new performance goal creation by the employee.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "5iz9l408",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 62,
		name: "Add Personal Payment Method",
		description: `When an employee adds their personal payment method, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "wq0i1pcw",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 140,
		name: "Add Target Outcome To Goal",
		description: `When an employee adds target outcomes to a development goal, they should be rewarded with points. Points are only rewarded once for a development goal.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "khygaeme",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 178,
		name: "Add Temporary Assignment - HR Specialist",
		description: `When an HR Specialist adds temporary assignment to an employee, the HR should be rewarded with points. Points are rewarded once for each assignment.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "a57mewf1",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 165,
		name: "Allocate and Publish Budgets",
		description: `When a manager allocates budgets to his reports, the manager should be rewarded with points. Points are rewarded once for each pool per plan per period.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "ccx7o0l7",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 53,
		name: "AP Invoice Validation",
		description: `When the Uservalidates a new AP Invoice`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 29,
		name: "Apply to Internal Job Posting",
		description: `When the employee applies to a job through the internal posting site they should be rewarded with points.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 22,
		name: "Approve Absence within 48 Hours",
		description: `Encourage managers to approve absence requests quickly. Award is given only when the approver is the employee's manager.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 81,
		name: "Approve AP Invoices",
		description: `When the User approves a AP Invoice`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 43,
		name: "Approve Job Offer within 12 Hours",
		description: `Encourages managers to approve job offers quickly so that the next approver on the chain can start their approval. The award is given to all approvers in the approval chain if they approve within 12 hours after the worklist is assigned to them.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 135,
		name: "Approve Performance Goal Submitted by Direct Reports",
		description: `When a manager approves a performance goal submitted by his direct reports, they should be rewarded with points. `,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "bajs8lo9",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 36,
		name: "Approve Requisition within 36 Hours",
		description: `Approver logs into Oracle PRC and opens their notifications to approve a pending requisition. They click approve and submit approval. Once the requisition record is submitted, the user will be awarded points. A Requester will be granted points for this tas`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 30,
		name: "Approve Time Card within 24 Hours",
		description: `Encourage managers to approve time cards quickly.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 58,
		name: "Assign an Onboarding Task",
		description: `When a manager assigns a new task to an employee in their team, they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "ss9bi0jn",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 162,
		name: "Attach Interview Questionnaire",
		description: `When a recruiter add a question to a requisition questionnaire, the recruiter should be rewarded with points. Points are rewarded each time adding questions for each requisition.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "zdn332dd",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 166,
		name: "Award Compensation - Manager",
		description: `When a manager adds an individual compensation plan to his direct reports, the manager should be rewarded with points. Points are rewarded for each award.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "ufpjol2u",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 80,
		name: "Cancel AP Invoice",
		description: `When the User cancels a AP Invoice`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 206,
		name: "Capture Response - Recruiter",
		description: `When a recruiter captures response for an offer with extend status, the recruiter should be rewarded with points. Points are rewarded for each response.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "fdop4w7k",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 56,
		name: "Change Contact to Emergency Contact",
		description: `When an employee updates an existing contact to be an emergency contact, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "jpopxl8l",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 5,
		name: "Clear Worklist Item",
		description: `When the Employee Clears Worklist Item they should be rewarded with points`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: "waz91xtm",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 28,
		name: "Collaborate on Job Requisition",
		description: `When the employee is added as a collaborator to the requisition they will be rewarded with points.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 11,
		name: "Complete an Onboarding Task",
		description: `When an employee completes an onboarding task, they should be rewarded with points and in case of completion before due date, they should be rewarded with extra points `,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "4qeb2ae0",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 69,
		name: "Complete Development Goal",
		description: `after an employee changes the development goal status to Completed, the employee should be rewarded with points. The points can only be rewarded once for each goal.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "y3zmv156",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 15,
		name: "Complete Learning Course",
		description: `When the  users complete  a learning course they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 20,
		name: "Complete New-Hire Benefits Selections",
		description: `When an new-hire employee enrolls in a benefits plan within 30 days of joining, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 139,
		name: "Complete Participant Feedback Evaluation",
		description: `When the participant completes self evaluation and submits the performance document, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "sjlhfekd",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 132,
		name: "Complete Performance Goal",
		description: `When an employee changes the performance goal status to Completed, the employee should be rewarded with points. The points can only be rewarded once for each goal.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "qzwfryzw",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 134,
		name: "Complete Worker Self Evaluation",
		description: `When the employee completes self evaluation and submits the performance document, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "acvz5o9q",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 198,
		name: "Confirm Review Meeting Held - Manager",
		description: `When a manager confirms review meeting held for direct reports, the manager should be rewarded with points. Points are rewarded once for each person.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "inadha9o",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 177,
		name: "Convert a Pending Worker - HR Specialist",
		description: `When an HR Specialist converts a pending worker to an employee, the HR should be rewarded with points. Points are rewarded once for each employee.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "s9wcdyr2",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 188,
		name: "Create a Job Succession Plan - HR Specialist",
		description: `When an HR Specialist creates a job succession plan, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "u7sedocu",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 3,
		name: "Create Absence Record",
		description: ` When an employee creates absence records, they should be rewarded with points`,
		points: 2,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: "rua8s8ha",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 73,
		name: "Create Absence Record for Direct Reports",
		description: `When a manager creates absence records for his direct reports, he should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "e32heorc",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 176,
		name: "Create an Absence Record - HR Specialist",
		description: `When an HR Specialist adds an absence record to an employee, the HR should be rewarded with points. Points are rewarded once for each absence record.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "i6c1xo2i",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 194,
		name: "Create an Action Plan in a Talent Review Meeting",
		description: `When an HR Specialist creates an action plan in a talent review meeting, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "e72s0v7r",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 154,
		name: "Create an Anytime Performance Document for Direct Reports",
		description: `When the manager creates an anytime performance document for direct reports, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "9vcl56go",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 189,
		name: "Create an Incumbent Succession Plan - HR Specialist",
		description: `When an HR Specialist creates an incumbent succession plan, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "gufnxkw2",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 144,
		name: "Create Anytime Performance Document for Self",
		description: `When the employee creates an anytime performance document for himself, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "ccsa8ec2",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 77,
		name: "Create AP Credit Memo",
		description: `When the User creates a CreditMemo AP Invoice`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 78,
		name: "Create AP Invoices via ADFdi Spreadsheet",
		description: `When the User creates a AP Invoice via ADFdi Spreadsheet`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 76,
		name: "Create AP Prepayment",
		description: `When the User creates a Prepayment AP Invoice`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 39,
		name: "Create Candidate",
		description: `Manager logs into HCM an manually creates a candidate. Recruiters will also receive points for creating a candidate. Use case is to encourage managers/recruiters to do proactive sourcing, such as on Linkedin or calling/emailing their network.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "hm1bbo9j",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 163,
		name: "Create Candidate Pool - Manager",
		description: `When a manager creates a candidate pool, the manager should be rewarded with points. Points are rewarded for each candidate pool.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "fuprfv1d",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 105,
		name: "Create Catalog Requisition",
		description: `When the User Creates Catalog Requisition`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 111,
		name: "Create Change Order",
		description: `When the User Creates Change Order`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 209,
		name: "Create Feedback for Candidate - Recruiter",
		description: `When a recruiter creates Feedback for Candidate, the recruiter should be rewarded with points. Points are rewarded for each feedback.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "1xx6b8ba",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 4,
		name: "Create Feedback Note",
		description: `When the user creates a new person note requesting feedback from a peer`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 2,
		name: "Create Home Address",
		description: `When the user creates their home address they should be rewarded with points`,
		points: 5,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: "he2havrt",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 12,
		name: "Create Home Email",
		description: `When the user creates their home email address they should be rewarded with points`,
		points: 5,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 7,
		name: "Create Home Phone",
		description: `When the user creates their home phone they should be rewarded with points`,
		points: 5,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 27,
		name: "Create Job Offer",
		description: `When the Manager creates a job offer they should be rewarded with points.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: "52gehu9r",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 164,
		name: "Create Job Offer - Manager",
		description: `When a manager creates an offer for a job application, the manager should be rewarded with points. Points are rewarded for each offer.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "52gehu9r",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 25,
		name: "Create Job Requisition",
		description: `When the Hiring Manager creates the requisition they should be rewarded with points.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 161,
		name: "Create Job Requisition - Manager",
		description: `When a manager creates a new requisition, the manager should be rewarded with points. Points are rewarded for each requisition.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "lb46tmml",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 38,
		name: "Create Manual Non-PO Invoice",
		description: `Payable Clerks log into Oracle Finance Cloud and create a new AP invoice. Once the invoice is created, payable clerk will be awarded points.`,
		points: 10,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 82,
		name: "Create Manual Payment",
		description: `When the User creates Manual Payment`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 75,
		name: "Create Manual PO Invoice",
		description: `When the User creates a PO related AP Invoice`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 104,
		name: "Create Non-Catalog Requisitions",
		description: `When the User Creates Non-Catalog Requisition`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 16,
		name: "Create Original Photo",
		description: `When the users create an Original photo they should be rewarded with points`,
		points: 5,
		frequency: "8760:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 83,
		name: "Create Payment Process Request Template",
		description: `When the User creates Payment Process Request Template`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 109,
		name: "Create Purchase Order",
		description: `When the User Creates Purchase Order`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 23,
		name: "Create Safety Incident",
		description: `When the user creates a new incident they should be rewarded with points`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 101,
		name: "Create Supplier",
		description: `When the User Creates Supplier`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 10,
		name: "Create Vacation Record over 1 Month in Advance",
		description: `When an employee creates a Vacation Record over 1 Month in Advance, they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 192,
		name: "Create Work Relationship  - HR Specialist",
		description: `When an HR Specialist creates an work relationship for a worker, the HR should be rewarded with points. Points are rewarded once for each worker.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "gm1rahp1",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 31,
		name: "Create Work Visa",
		description: `When the work visa is created the employee should be rewarded with points.  The employee will be awarded points if they create the visa themselves or it is created by admin.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 197,
		name: "Create Worker Mass Transfer - HR Specialist",
		description: `When an HR Specialist creates worker mass transfer for many employees, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "2qkvks6o",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 47,
		name: "Employee Adds Development Goal",
		description: `When an employee adds a development goal, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "mua7hxvk",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 49,
		name: "Employee Adds Role of Interest",
		description: `When an employee adds a profile to their careers of interest, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "9bt7fz25",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 48,
		name: "Employee Updates Career Statement",
		description: `When an employee updates their career statement, they should be rewarded with points`,
		points: 2,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 46,
		name: "Employee Updates Performance Document",
		description: `When the Employee Updates the Performance Document, they should be rewarded with points`,
		points: 5,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 50,
		name: "Employee Updates Talent Profile",
		description: `When an employee updates any section on their talent profile, they should be rewarded with points.`,
		points: 15,
		frequency: "720:00:00",
		guideId: 3,
		guideValue: "047vldo1",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 21,
		name: "Enroll  Dependent",
		description: `When the user enrolls a new dependent they should be awarded with point.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 18,
		name: "Enroll in Benefits Plan",
		description: ` When an employee enrolls in a benefits plan, after the first 7 days of open enrollment period start, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 64,
		name: "Enter Salary Details for my Team",
		description: `Manager logs into Oracle HCM and submits a salary change for one of their direct reports.  Once HR has approved the request and the transaction is saved the manager will be awarded points.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "my84d1mr",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 207,
		name: "Extend Job Offer - Recruiter",
		description: `When a recruiter extends an offer for a job application, the recruiter should be rewarded with points. Points are rewarded for each offer.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "3pywe31m",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 26,
		name: "Fill Job Requisition",
		description: ` When a Job requisition is filled within 30 days from the requisition creation the Recruiter that is assigned to the requisition should be rewarded with points.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: "03zjtwyd",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 186,
		name: "Global Transfer - HR Specialist",
		description: `When an HR Specialist submits a global transfer for an employee, the HR should be rewarded with points. Points are rewarded once for each change.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "0bfhjf1l",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 171,
		name: "Hire an Employee - HR Specialist",
		description: `When an HR Specialist hires a new employee, the HR should be rewarded with points. Points are rewarded once for each employee.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "cm3fgobl",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 68,
		name: "Inactivate Development Goal",
		description: `after an employee changes the active flag of a development goal to No, the employee should be rewarded with points. The points can only be rewarded once for each goal.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "g1npvj77",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 203,
		name: "Invite Candidate - Recruiter",
		description: `When a recruiter sends invitation to prospect candidate, the recruiter should be rewarded with points. Points are rewarded once for each invitation.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "hf75qu5y",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 1,
		name: "Log into Oracle",
		description: `When a user logs into Oracle HCM they should be rewarded points in the system`,
		points: 1,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 102,
		name: "Manage Supplier",
		description: `When the User updates Supplier details`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 147,
		name: "Manager Evaluation of the Anytime Performance Document",
		description: `When the manager completes evaluation and submits the anytime performance document, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "xv3hi340",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 45,
		name: "Manager Updates Performance Document",
		description: `When the Manager Updates the Performance Document, they should be rewarded with points`,
		points: 5,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 41,
		name: "Manually Created Candidate Applies to Job",
		description: `After the Manager/Recruiter has created an external candidate they can add the candidate to a job requisition. An email is then sent out to the candidate inviting them to apply to the job. If the candidate  then applies to the job give Manager/Recruiter po`,
		points: 20,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 79,
		name: "Manually Validate AP Invoices",
		description: `When the User validates a new AP Invoice manually`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 66,
		name: "Mark Task as Complete for Direct Reports",
		description: `When a manager changes the task status to complete for an employee in their team, they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "lolqe1sn",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 67,
		name: "Mark Task as Not Applicable for Direct Reports",
		description: `When a manager changes the task status to not applicable for an employee in their team, they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "97jrd4gf",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 167,
		name: "Model Budgets: Updates to the budgets",
		description: `When a manager creates a model, applies it to the worksheet and submits it for approval, the manager should be rewarded with points. Points are rewarded once for each model per period.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "g1bcxt32",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 208,
		name: "Move Candidate - Recruiter",
		description: `When a recruiter moves candidate from a Candidate Pool, the recruiter should be rewarded with points. Points are rewarded for each moving.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "tnniqlw6",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 204,
		name: "Move Job Requisition to Posting - Recruiter",
		description: `When a recruiter moves a job requisition to posting, the recruiter should be rewarded with points. Points are rewarded once for each movement.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "i0loeel9",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 54,
		name: "Non-PO AP Invoice Creation",
		description: `When the User creates a non-PO AP Invoice`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 210,
		name: "Open Job Requisition for Sourcing - Recruiter",
		description: `When a recruiter opens a job requisition for sourcing, the recruiter should be rewarded with points. Points are rewarded once for each open.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "5y46bl3f",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 90,
		name: "Open/Close Periods - Payables",
		description: `When the User opens or closes AP Periods`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 34,
		name: "Participate in first Wellness Competition",
		description: `The first time an employee participates in a competition they will be rewarded with points.Since there is not a unique competition ID identifier the employee will only get points for the first time they join a competition. Any subsequent competitions they `,
		points: 20,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 136,
		name: "Perform Manager Evaluation",
		description: `When the manager completes evaluation and submits the performance document, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "1ov4ha9o",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 89,
		name: "Perform Payables to Ledger Reconciliation",
		description: `When the User performs Payables to Ledger Reconciliation`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 201,
		name: "Post a Requisition - Recruiter",
		description: `When a recruiter posts a requisition, the recruiter should be rewarded with points. Points are rewarded once for each post.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "89vnozf4",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 202,
		name: "Prepare a Talent Review Meeting - Manager",
		description: `When a manager prepares a talent review meeting, the manager should be rewarded with points. Points are rewarded once for each submission.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "jjgdc0hd",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 110,
		name: "Process Requisition Line as Order",
		description: `When the User Processes Requisition Line as Order`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 172,
		name: "Promote a Worker - HR Specialist",
		description: `When an HR Specialist promotes an employee, the HR should be rewarded with points. Points are rewarded once for each promotion.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "yr4ls7en",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 42,
		name: "Promote Employee",
		description: `Manager logs into Oracle HCM and submits a promotion for one of their direct reports. Once HR has approved the promotion and the transaction is save the manager will be awarded points`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: "q34xoyta",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 184,
		name: "Reassign Responsibility - HR Specialist",
		description: `When an HR Specialist reassigns an area of responsibility from an employee to another, the HR should be rewarded with points. Points are rewarded once for each update.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "smpapc2z",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 141,
		name: "Recommend Role for Direct Reports",
		description: `When a manager recommends a role to his direct reports in Career Development, they should be rewarded with points. `,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "i7xgk25a",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 14,
		name: "Register for Learning Course",
		description: `When the  users register to a learning course they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 190,
		name: "Rehire an Employee - HR Specialist",
		description: `When an HR Specialist rehires a new employee, the HR should be rewarded with points. Points are rewarded once for each employee.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "uiazp1ky",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 156,
		name: "Request a New Position - Manager",
		description: `When a manager creates a new position, the manager should be rewarded with points. Points are rewarded for each position.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "9fs4v7y1",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 142,
		name: "Request Feedback for Direct Reports",
		description: `When the manager requests feedback about his direct reports from others, they should be rewarded with points. Points are only rewarded once for each request.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "kroi6p9i",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 143,
		name: "Request Feedback from Others",
		description: `When the employee requests feedback about himself from others, they should be rewarded with points. Points are only rewarded once for each request.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "fu5q5iah",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 127,
		name: "Review Customer Balances",
		description: `When the User Reviews Customer Balances`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 86,
		name: "Run Create Accounting - Payables",
		description: `When the User runs Create Accounting`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 153,
		name: "Run Create Accounting - Receivables",
		description: `When the User runs Create Accounting for Receivables`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 92,
		name: "Run ESS Job to Validate Invoice",
		description: `When the User runs ESS Job to Validate Invoice`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 87,
		name: "Run Invoice Register",
		description: `When the User runs Invoice Register`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 84,
		name: "Run Payment Process Request Template",
		description: `When the User runs Payment Process Request Template`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 88,
		name: "Run Payment Register",
		description: `When the User runs Payment Register`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 128,
		name: "Run Receivables Aging Report",
		description: `When the User Runs Receivables Aging Report`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 91,
		name: "Run Sweep Transactions",
		description: `When the User runs Sweep Transactions`,
		points: 5,
		frequency: null,
		guideId: null,
		guideValue: null,
		guideType: null,
	},
	{
		id: 193,
		name: "Schedule a Talent Review Meeting - HR Specialist",
		description: `When an HR Specialist schedules a talent review meeting, the HR should be rewarded with points. Points are rewarded once for each creation.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "wm11qr6r",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 71,
		name: "Send a Task Reminder to Direct Reports",
		description: `When a manager send a task reminder for a task in the checklist for an employee in their team, they should be rewarded with points. For one employee, each task can only be rewarded once.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "klujo8pj",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 211,
		name: "Send Correspondence to Candidate - Manager",
		description: `When a manager sends correspondence to candidate, the manager should be rewarded with points. Points are rewarded once for each operation.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "w1tp3mne",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 148,
		name: "Share Anytime Performance Document",
		description: `When the manager shares and releases the anytime performance document to direct reports, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "zoghvez3",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 137,
		name: "Share Performance Document",
		description: `When the manager shares and releases the performance document to direct reports, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "bkxr4i1f",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 158,
		name: "Submit a Job Requisition - Manager",
		description: `When a manager submits a requisition, the requisition status will be changed to Job Formatting - In Progress and the manager should be rewarded with points. Points are rewarded for each requisition.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "ootnkswk",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 191,
		name: "Submit Assignment Change - HR Specialist",
		description: `When an HR Specialist submits an assignment change for an employee, the HR should be rewarded with points. Points are rewarded once for each change.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "24095gu8",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 51,
		name: "Submit Employee Assignment Change",
		description: `Manager logs into Oracle HCM and submits an assignment change for one of their direct reports. The use case covers all assignment change actions, including job change and position change.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 65,
		name: "Submit Employee Job Change",
		description: `Manager logs into Oracle HCM and submits a job change for one of their direct reports.  Once HR has approved the request and the transaction is saved the manager will be awarded points.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "7682mi9i",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 52,
		name: "Submit Employee Location Change",
		description: `Manager logs into Oracle HCM and submits a location change for one of their direct reports.  Once HR has approved the request and the transaction is saved the manager will be awarded points.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: "83w222gp",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 44,
		name: "Submit Employee Working Hours Change",
		description: `Manager logs into Oracle HCM and submits a working hours change for one of their direct reports. Once HR has approved the request and the transaction is saved the manager will be awarded points.`,
		points: 10,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 173,
		name: "Submit Job Change - HR Specialist",
		description: `When an HR Specialist submits a job change for an employee, the HR should be rewarded with points. Points are rewarded once for each change.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "of8ir4yz",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 205,
		name: "Submit Job Offer - Recruiter",
		description: `When a recruiter submits an offer for a job application, the recruiter should be rewarded with points. Points are rewarded for each offer.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "h8hnbkkd",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 183,
		name: "Submit Location Change - HR Specialist",
		description: `When an HR Specialist submits a location change for an employee, the HR should be rewarded with points. Points are rewarded once for each change.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "zhf5n3tl",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 174,
		name: "Submit Manager Change - HR Specialist",
		description: `When an HR Specialist submits a manager change for an employee, the HR should be rewarded with points. Points are rewarded once for each change.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "vriukdi9",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 182,
		name: "Submit Position Change - HR Specialist",
		description: `When an HR Specialist submits a position change for an employee, the HR should be rewarded with points. Points are rewarded once for each change.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "gaolynmh",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 24,
		name: "Submit Time Entry",
		description: `When an employee enters and submits a timecard entry, they should be rewarded with points.`,
		points: 5,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 60,
		name: "Submit Worker Transfer",
		description: `Manager logs into Oracle HCM and submits a transfer for one of their direct reports.  Once HR has approved the request and the transaction is saved the manager will be awarded points.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "j1ev5sf0",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 37,
		name: "Terminate Employee",
		description: `Manager logs into Oracle HCM and submits a termination for one of their direct reports. The use case is to encourage managers to submit the termination in Manager Self Service themselves. Once HR has approved the termination and the transaction is saved th`,
		points: 10,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: "thynpw7c",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 175,
		name: "Transfer a Worker - HR Specialist",
		description: `When an HR Specialist transfers an employee, the HR should be rewarded with points. Points are rewarded once for each promotion.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "w4pz2z16",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 72,
		name: "Update Absence Record",
		description: `When an employee updates absence records, they should be rewarded with points.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "rua8s8ha",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 74,
		name: "Update Absence Record for Direct Reports",
		description: `When a manager updates absence records for their direct reports, they should be rewarded with points.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "e32heorc",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 168,
		name: "Update Allocations",
		description: `When a manager submits the compensation worksheet for approval, the manager should be rewarded with points. Points are rewarded once for each plan per period.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "3peu5nap",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 59,
		name: "Update Document Delivery Preferences",
		description: `When an employee updates their document delivery preferences, they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "g76blh09",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 70,
		name: "Update Due Date of Allocated Task",
		description: `When a manager changes the due date of allocated tasks in the checklist for an employee in their team, they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "lqaouwtw",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 6,
		name: "Update Home Address",
		description: `When the user updates their home address they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "jpzmyc22",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 13,
		name: "Update Home Email",
		description: `When the user updates their home email address they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 8,
		name: "Update Home Phone",
		description: `When the user updates their home phone they should be rewarded with points`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 61,
		name: "Update My Bank Account Details",
		description: `When an employee adds/updates their bank account, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "41cg4378",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 199,
		name: "Update My Contacts",
		description: `When an employee updates personal contact info, the employee should be rewarded with points. Points are rewarded once for each update.`,
		points: 2,
		frequency: "168:00:00",
		guideId: 3,
		guideValue: "sz7eq42w",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 57,
		name: "Update My Marital Status",
		description: `When an employee updates their marital status, they should be rewarded with points`,
		points: 2,
		frequency: "168:00:00",
		guideId: 3,
		guideValue: "vepmgyzs",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 63,
		name: "Update Personal Payment Method",
		description: `When an employee updates their existing personal payment method, they should be rewarded with points`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "ohs0ihr9",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 17,
		name: "Update Photo",
		description: `When the users update an Original photo they should be rewarded with points`,
		points: 2,
		frequency: "24:00:00",
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 196,
		name: "Update Skills and Qualifications details for Direct Reports",
		description: `When a manager updates skills and qualifications for direct reports, the manager should be rewarded with points. Points are rewarded once for each update.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "c0owrvtp",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 169,
		name: "Update Tax Withholding Card",
		description: `When an employee updates tax withholding information, the employee should be rewarded with points. Points are rewarded each time for each tax card.`,
		points: 2,
		frequency: null,
		guideId: 3,
		guideValue: "7rmu0iug",
		guideType: "Oracle Guided Learning",
	},
	{
		id: 32,
		name: "Update Work Visa",
		description: `When and existing work visa is updated the employee should be rewarded with points.  The employee will be awarded points if they update the visa themselves or it is updated by an admin. `,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 40,
		name: "Validate Invoices",
		description: `Payables Clerk logs into Oracle FIN and validates any outstanding invoices by running the ESS Job "Validate Paybles Invoices". Once the job is submitted, the user will be awarded points. A Payables Clerk will be granted points for this task as many times a`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: null,
		guideType: "Oracle Guided Learning",
	},
	{
		id: 146,
		name: "Worker Self-Evaluation for the Anytime Performance Document",
		description: `When the employee completes self evaluation and submits the anytime performance document, they should be rewarded with points. Points are only rewarded once for each performance document.`,
		points: 5,
		frequency: null,
		guideId: 3,
		guideValue: "ewzblatk",
		guideType: "Oracle Guided Learning",
	},
];
