import React from "react";
import "./ChatHeader.scss";

const ChatHeader = (props) => (
    <div className="chat-header">
        <span className="flipper--close close--chat clickable" onClick={props.close}>+</span>
        <span className="bodytext">CHATTING WITH</span>
        <h1>{props.agentName}</h1>
    </div>
);

export default ChatHeader;