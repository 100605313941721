import "./NavButton.scss";

import React from "react";
import { NavLink } from 'react-router-dom';

export default class NavButton extends React.Component {
    render() {
        const {
            analyticsTag,
            change,
            className,
            index,
            navinfo = {},
        } = this.props;

        const {
            icon = <></>,
            navText = "",
            navRoute = "",
            navRouter = "",
        } = navinfo || {};

        const navTo = navRoute === '/admin'
            ? window.location.pathname
            : navRoute;

        const NavButtonIcon = () => icon();

        const NavButtonDetails = () => (
            <span className={`nav-button ${className}`}>
                <span className="nav-icon">
                    <NavButtonIcon />{navRouter}
                </span>
                <span className="nav-text">
                    {navText}
                </span>
            </span>
        );

        return (
            <NavLink
                to={navTo}
                onClick={() => change(index, navText, analyticsTag)}
            >
                <NavButtonDetails />
            </NavLink>
        );
    }
}