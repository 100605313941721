import "./App.scss";
import "./assets/styles/animations.scss";
import "react-toastify/dist/ReactToastify.css";

import React, { Component } from "react";
import { BrowserRouter as Router, withRouter } from "react-router-dom";

import AppContent from "./AppContent";
import Session from "./components/Session";
import { checkToken } from "./utils/Cookies";
import { isInIframe, isStaticEnvironment } from "./utils/reusableFunctions";
import { APIProvider, APIContext } from "./context/APIProvider";
import {isMobile} from 'react-device-detect';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: true,
            session: false
        };
    }

    componentDidMount() {
        if (isStaticEnvironment()) {
            this.setState({ redirect: false, session: true });
            return;
        }

        const { doRedirect, redirectType } = this.redirectParams();

        if (checkToken(doRedirect, redirectType)) {
            this.setState({ redirect: false, session: true });
        }
    }

    // Helper functions
    //#region
    handleSession = (session) => {
        this.setState({ session });
    };

    redirectParams = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const isIframe = isInIframe();
        const redirectType = "dashboard";
        // For redirect to dashboard or platform use: isIframe ? "dashboard" : "login";

        let doRedirect = this.state.redirect;

        if (queryParams.has("logout") || !isIframe) {
            doRedirect = true;
        }

        this.setState({ redirect: doRedirect });

        return {
            doRedirect,
            redirectType,
        };
    };
    //#endregion

    render() {
        const { redirect, session} = this.state;

        if (redirect || isMobile === true) {
            return <></>;
        }

        return (
            <Router>
                <>
                    {!session || session === "expired" ? (
                        <Session
                            title={
                                session === "expired"
                                    ? "Session expired"
                                    : undefined
                            }
                            message={
                                session === "expired"
                                    ? "Your session is getting refreshed, please wait"
                                    : undefined
                            }
                        />
                    ) : (
                        <APIProvider
                            redirect={redirect}
                            session={session}
                            handleSession={this.handleSession}
                            redirectType={"dashboard"}
                        >
                            <APIContext.Consumer>
                                {withRouter(AppContent)}
                            </APIContext.Consumer>
                        </APIProvider>
                    )}
                </>
            </Router>
        );
    }
}

export default App;
