import "./HomePage.scss";

import React from "react";

import Leaderboard from "../../components/Leaderboard/Leaderboard";
import Shortcuts from "../../components/Shortcuts/Shortcuts";
import { APIContext } from "../../context/APIProvider";
import { isStaticEnvironment } from "../../utils/reusableFunctions";
import MockAPI from "../../mocks/API";

export default class HomePage extends React.Component {
    render() {
        const company = isStaticEnvironment() ? "200" : null;
        const campaign = isStaticEnvironment() ? MockAPI.campaign[0] : null;

        return (
            <div className="sidebar--body">
                <main>
                    <APIContext.Consumer>
                        {(context) => (
                            <Leaderboard
                                company={company || context?.company}
                                campaign={campaign || context?.campaign}
                            />
                        )}
                    </APIContext.Consumer>
                    <Shortcuts />
                </main>
            </div>
        );
    }
}
