import React from "react";
import "./MobilePrivacyPolicy.scss";
import MobileHead from "../MobileHead";
import Privacy from "../../cms/Privacy.js";

class MobilePrivacyPolicy extends React.Component {
    render() {
        return (
            <>
                <MobileHead />
                <div className="m-legal-wrapper legal-wrapper">
                    <Privacy isMobile={true}/>
                </div>
            </>
        );
    }
}

export default MobilePrivacyPolicy;
