import React from "react";
import "./Navbar.scss";
import NavButton from "../NavButton";
import TitleCard from "../TitleCard";
import { APIContext } from "../../../context/APIProvider";
import { ReactComponent as AdminAlert } from "../../../assets/icons/admin-alert.svg";
import GuidedLearningSelect from "../../../components/Help/GuidedLearningSelect";
import { SHOW_SETTINGS } from "../../../services/process/env";
import { postMessage } from "../../../utils/reusableFunctions";

/**
 * @prop navarray - Array of nav Objects - [{icon: url, navText: md}, ...]
 *
 * @export
 * @class Navbar
 * @extends {React.Component}
 */
export default class Navbar extends React.Component {
    static contextType = APIContext;
    constructor(props) {
        super(props);
        this.navigateback = this.navigateback.bind(this);
        this.state = {
            showWhatFix: false,
        };

        this.whatFixClick = this.whatFixClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener("message", this.sandboxMessage);
        postMessage({ sandbox: "oracle" });
    }

    sandboxMessage = (event) => {
        if (event.data.sandbox === "eewo-dev-whatfix") {
            this.setState({ showWhatFix: true });
        }
    };

    navigateback = () => {
        const { navigateback, canGoBack } = this.props;

        if (canGoBack) {
            postMessage("back");

            setTimeout(() => {
                navigateback();
            }, 1500);
        }
    };

    whatFixClick = () => {
        const whatFixMsg = {
            to: "whatFixId",
            id: "2f4114a0-b255-11ea-be2e-bacbbe07b880",
        };
        postMessage(whatFixMsg);
        return postMessage("back");
    };

    render() {
        let {
            navarray,
            selection,
            changeselection,
            canGoBack,
            title,
            adminPortal,
            adminElement,
            guidedLearningElement,
            guidedLearningSelection,
        } = this.props;
        return (
            <div className="navbar relative">
                <TitleCard
                    navigateback={this.navigateback}
                    canGoBack={canGoBack}
                    className="header"
                >
                    {title}
                </TitleCard>
                <nav className="content">
                    <ul>
                        {navarray.map((element, i) => {
                            return (
                                <li key={i}>
                                    <NavButton
                                        isAdmin={this.props.isAdmin}
                                        index={i}
                                        key={"NavButton" + i}
                                        navinfo={element}
                                        className={
                                            selection === i
                                                ? "navbar--active"
                                                : "navbar--inactive"
                                        }
                                        change={changeselection}
                                        analyticsTag={element.analyticsTag}
                                    />
                                    {element.showAlert && (
                                        <AdminAlert className="alert-svg" />
                                    )}
                                </li>
                            );
                        })}

                        {this.state.showWhatFix && (
                            <li>
                                <button className="nav-button">
                                    <span
                                        className="nav-text"
                                        style={{ color: "#1273E6" }}
                                        onClick={this.whatFixClick}
                                    >
                                        WhatFix POC
                                    </span>
                                </button>
                            </li>
                        )}
                    </ul>
                    {adminPortal && (
                        <React.Fragment>
                            <p className="adminblock">Activation Portal</p>
                            <ul>
                                {this.props.isAdmin && (
                                    <li index={"admin"}>
                                        <NavButton
                                            index={"admin"}
                                            navinfo={adminElement}
                                            className={
                                                selection === "admin"
                                                    ? "navbar--active"
                                                    : "navbar--inactive"
                                            }
                                            change={changeselection}
                                            analyticsTag={"admin-analytics"}
                                        />
                                    </li>
                                )}
                            </ul>
                            <ul>
                                {adminPortal && SHOW_SETTINGS && (
                                    <li index={"admin"}>
                                        <NavButton
                                            index={"admin"}
                                            navinfo={guidedLearningElement}
                                            className={
                                                selection === "admin"
                                                    ? "navbar--active"
                                                    : "navbar--inactive"
                                            }
                                            change={guidedLearningSelection}
                                            analyticsTag={"admin-analytics"}
                                        />
                                    </li>
                                )}
                            </ul>
                        </React.Fragment>
                    )}
                </nav>
                {guidedLearningSelection && (
                    <div>
                        <GuidedLearningSelect onSubmit={this.submitModal} />
                    </div>
                )}
                <button
                    onClick={() => this.props.showLegal()}
                    className="legal clickable"
                >
                    Legal
                </button>
            </div>
        );
    }
}
