import "./Rankingtable.scss";

import React from "react";
import RankingLine from "../RankingLine";
import { handleMaxUsers } from "./helper";

const RankingTable = (props) => {
    const { users = [] } = props;
    const ranks = users?.length <= 8 ? users : handleMaxUsers(users);

    return (
        <div className="rankingTable">
            <ol className="p-t-10 h100">
                {ranks?.map((element, index) => (
                    <RankingLine
                        user={element}
                        index={index}
                        key={"RankingLine" + index}
                    />
                ))}
            </ol>
        </div>
    );
};

export default RankingTable;