/**
 * rulesFrequencyNormalize
 * @description Normalizes the frequency of rules in the provided array of rules.
 * @param {Array<Object>} rules - An array of rules, where each rule is an object with a 'frequency' property.
 * @returns {Array<Object>} - A new array of rules with normalized frequencies.
 *
 * @example
 * const originalRules = [
 *   { name: 'Rule1', frequency: 50 },
 *   { name: 'Rule2', frequency: 75 },
 *   ...
 * ];
 *
 * const normalizedRules = rulesFrequencyNormalize(originalRules);
 * // normalizedRules will contain rules with normalized frequencies.
 */

const rulesFrequencyNormalize = (rules) => {
    const newRules = rules.map((rule) => {
        rule.frequency = frequencyNormalize(rule.frequency);
        return rule;
    });

    return newRules;
};

/**
 * frequencyNormalize
 * @description Normalizes a given time frequency string into a human-readable format.
 * @param {string | null} frequency - The frequency string representing time (e.g., "03:30") or null for unlimited.
 * @returns {string} - A human-readable representation of the normalized frequency.
 *
 * @example
 * const originalFrequency = "12:30";
 * const normalizedFrequency = frequencyNormalize(originalFrequency);
 * // normalizedFrequency will be a string like "Once per month" based on the input frequency.
 */
const frequencyNormalize = (frequency) => {
    if (frequency === null) return "Unlimited";

    const frequencyTypes = ["day", "week", "month", "challenge period"];
    const breakpoints = [24, 168, 720, 8760];
    const timeString = frequency;
    const hourString = timeString.split(":")[0];
    const hourInt = parseInt(hourString);

    let frequencyReadable;

    for (let i = 0; i < breakpoints.length; i++) {
        if (breakpoints[i] <= hourInt) {
            frequencyReadable = "Once per " + frequencyTypes[i];
        }
    }

    return frequencyReadable;
};

export { rulesFrequencyNormalize, frequencyNormalize };
