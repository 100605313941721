import React from "react";
import "./Session.scss";

const Session = (props) => {  
    const {
        title = "Session ended",
        message = "You have securely signed off",
    } = props;

    return (
        <div className="session">
            <div className="wrapper">
                <h2>{title}</h2>
                <p>{message}</p>
            </div>
        </div>
    );
}

export default Session;
