import React from "react";
import "./HelpSubmit.scss";
import { Submission } from "../../../assets/icons/icons";

const HelpSubmit = (props) => (
    <div className="helpsubmit">
        <Submission />
        <h3 className="h3 center">Thank you for submitting your question.</h3>
        <h3 className="h3 center">We'll get back to you with an answer as soon as possible.</h3>
        <button onClick={props.close} className="help--clear btn--blank">{props.buttonText ? props.buttonText : "Ok".toUpperCase()}</button>
    </div>
);

export default HelpSubmit;
