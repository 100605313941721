import React from 'react';
import { Alert } from "../../assets/icons/icons";

import "./Notification.scss";

const Notification = ({ message }) => {
    return (
        <div className="notify-box">
            <Alert />
            <div className="notify-text">
                <p>{message}</p>
            </div>
        </div>
    )
}

export default Notification;