import React from "react";
import HomePage from "../../../views/HomePage";
import ScoringRules from "../../../views/ScoringRules";
import WorkSmartHelp from "../../../views/WorkSmartHelp";
import { isAdmin, isStaticEnvironment } from "../../../utils/reusableFunctions";
import { APIContext } from "../../../context/APIProvider";

import { Route, Switch } from 'react-router-dom';

let NavResults
if (!isStaticEnvironment()) {
    NavResults = (props) => (
        <APIContext.Consumer>
            {(datacontext) => {
                const Router = [];
                Router.push(<Route key="Scoring Rules" path="/rules" name="Scoring Rules" render={(routeProps) => (<ScoringRules {...routeProps} />)} />)
                if (datacontext?.isNoscore === "N") {
                    Router.push(<Route key="Home" path="/" exact={true} name="Home" component={HomePage} />)
                }
                if (datacontext?.enterprisePlatformName !== 'Oracle HCM') {
                    Router.push(
                        <Route
                            key="Amplifier Help"
                            path="/help"
                            name="Amplifier Help"
                            render={(routeProps) => (
                                <WorkSmartHelp
                                    {...routeProps}
                                    setChatGroupId={datacontext.setChatObj}
                                    chatGroupObj={datacontext.chatGroupObj}
                                    userDetails={datacontext.userDetails}
                                    isAdmin={isAdmin(datacontext.permissions)}
                                />
                            )}
                        />
                    )
                }                
                return (
                    <React.Fragment>
                        <Switch>
                            {Router}
                        </Switch>
                    </React.Fragment>
                );
            }}
        </APIContext.Consumer>
    );
} else {
    NavResults = (props) => {
        const Router = [];
        Router.push(<Route key="Home" path="/" exact={true} name="Home" component={HomePage} />)
        Router.push(<Route key="Amplifier Help" path="/help" name="Amplifier Help" render={(routeProps) => (<WorkSmartHelp {...routeProps} setChatGroupId={true} chatGroupObj={true} userDetails={true} isAdmin={true} />)} />)
        Router.push(<Route key="Scoring Rules" path="/rules" name="Scoring Rules" render={(routeProps) => (<ScoringRules {...routeProps} />)} />)
        return (
            <React.Fragment>
                <Switch>
                    {Router}
                </Switch>
            </React.Fragment>
        );
    }
}

export default NavResults;