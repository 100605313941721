import React from "react";

const About = () => (
    <>
        <h3>
            <strong>About Us</strong>
        </h3>
        <p>
            Enterprise applications help organizations stay relevant, but these
            solutions are only successful if employees adopt them. PwC Amplifier
            is an adoption platform that gives you the confidence to accelerate
            change, and gamify employee actions to meet your business
            initiatives. Our ultimate objective is to empower your team to spend
            less time training, so they can focus on what matters.
        </p>
        <p>
            In 2017, PwC embarked on one of the largest digital CRM
            transformations in US history. We originally created Amplifier to
            support that initiative. It was so successful, we made the decision
            to bring it to other organizations.
        </p>
        <p>
            In 2017, PwC embarked on a massive global enterprise transformation,
            one of the largest our platform vendor had ever done. We originally
            created Amplifier to support that initiative which consisted of over
            250,000+ people in 150+ countries. It was so successful, we made the
            decision to bring it to other organizations.&nbsp;
        </p>
        <p>
            For more information, visit{" "}
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.pwc.com/us/en/products/amplifier.html"
            >
                Amplifier.
            </a>
        </p>
        <p>
            Click{" "}
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.pwc.com/us/en/products.html"
            >
                HERE{" "}
            </a>
            to to learn more about PwC products
        </p>
        <br />
        <br />
        <p>
            &copy;2020 PwC. All rights reserved. PwC refers to the US member
            firm of the PwC network or one of its subsidiaries or affiliates.
        </p>
    </>
);

export default About;
