import React, { useContext } from "react";
import { NavLink } from "react-router-dom";

import { APIContext } from "../context/APIProvider";
import { isStaticEnvironment } from "../utils/reusableFunctions";

const Version = (props) => {
    const context = useContext(APIContext);
    const platform = isStaticEnvironment()
        ? "platform"
        : context?.enterprisePlatformName;

    return (
        <>
            <p>
                This iteration has the objective to support organizations drive
                continuous adoption and enterprise initiative transformation.
            </p>
            {platform !== "Oracle HCM" && (
                <p>
                    For feedback, please feel free to share your ideas{" "}
                    {props.isMobile ? (
                        <NavLink to="/amplifier-help">
                            <button>submit question option</button>{" "}
                        </NavLink>
                    ) : (
                        <button onClick={props.supportClick}>
                            submit question option
                        </button>
                    )}
                    .
                </p>
            )}
            <ul>
                <li>
                    <strong>What&rsquo;s New</strong>
                    <ul>
                        <li>
                            <strong>Adoption Panel ( Employees).</strong>
                            <ol>
                                <li>
                                    Gamification component that gets your
                                    workforce engaged in the system via time
                                    based challenge periods
                                </li>
                                <li>
                                    Leaderboard and Point Dial - Personalized
                                    view of where employees rank in comparison
                                    to their colleagues along with transparency
                                    on how many points they have and how many
                                    points they have achieved.
                                </li>
                                <li>
                                    Challenge Actions - Guided, role-based,
                                    point-of-need learning overviews. Objective
                                    is that employees learn within the
                                    enterprise platform versus relying on
                                    traditional training mechanisms
                                </li>
                                <li>
                                    Game Rules - Overview of the challenge
                                    period objectives, timing and rules along
                                    with transparency around what you will get
                                    points for along with frequency.&nbsp;
                                </li>
                                <li>
                                    Amplifier Help - quick access to frequently
                                    asked questions and product support ( idea
                                    submission and online chat)
                                </li>
                            </ol>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <strong>Activation Portal ( Admins)</strong>
                            <ol>
                                <li>
                                    Analytics and business lever gamification
                                    engine
                                </li>
                                <li>
                                    Active Analytics - source of automated data
                                    reporting that highlights change
                                </li>
                                <li>
                                    Behavior Activator -Centralized portal to
                                    manage the continuous improvement and
                                    customization of the enterprise adoption to
                                    perform ongoing calibration to help
                                    accomplish business initiatives.
                                </li>
                            </ol>
                        </li>
                    </ul>
                </li>
                <li>
                    <strong>Recent Improvements!</strong>
                    <ul>
                        <li>Coming soon, looking for your feedback.</li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                    <strong>Hot fixes</strong>
                    <ul>
                        <li>
                            Nothing yet, but I am sure there are areas to
                            improve.
                        </li>
                    </ul>
                </li>
            </ul>
            <ul>
                <li>
                    <strong>Other/Operations</strong>
                    <ul>
                        <li>NA</li>
                    </ul>
                </li>
            </ul>
        </>
    );
};

export default Version;
