import React from "react";
import ShortcutCard from "../components/Shortcuts/ShortcutCard";
import { guideTrigger } from "./reusableFunctions";

/**
 * createShortcuts
 * @description Creates a table of ShortcutCard components based on the provided array of shortcuts.
 * @param {Array<Object>} arrayOfShortcuts - An array of shortcut objects.
 * @param {function} errorTrigger - Function to trigger on error.
 * @param {function} infoGuide - Function to provide information guide.
 * @returns {Array<ReactElement>} - An array of React elements representing the table structure.
 */

// eslint-disable-next-line no-undef
const createShortcuts = (
    arrayOfShortcuts,
    errorTrigger,
    infoGuide
) => {
    if (!Array.isArray(arrayOfShortcuts)) {
        return [];
    }

    let table = [];
    
    for (let i = 0; i < arrayOfShortcuts?.length; i += 2) {

        let children = [];

        for (let j = 0; j < 2; j++) {
            let number = i + j;
            if (arrayOfShortcuts[number]) {
                children.push(
                    <td key={"tableData" + number}>
                        <ShortcutCard
                            shortcut={arrayOfShortcuts[number]}
                            title={arrayOfShortcuts[number].name}
                            points={arrayOfShortcuts[number].points}
                            walkmeId={arrayOfShortcuts[number].id}
                            checkboxOnClick={() => {
                                arrayOfShortcuts[number].guideValue !== null
                                    ? guideTrigger({
                                          swtId: parseInt(
                                              arrayOfShortcuts[number]
                                                  .guideValue
                                          ),
                                      })
                                    : errorTrigger(
                                          arrayOfShortcuts[number].name
                                      );
                                infoGuide({ data: arrayOfShortcuts[number] });
                            }}
                        />
                    </td>
                );
            } else {
                children.push(<td key={"tableData" + number}>&nbsp;</td>);
            }
        }
        
        table.push(<tr key={"tableRow" + i}>{children}</tr>);
    }

    return table;
};

export { createShortcuts };
