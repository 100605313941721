import React from "react";
import "./MobileLegal.scss";
import MobileHead from "../MobileHead";
import { NavLink } from 'react-router-dom';
import { RightArrow } from "../../assets/icons/icons";

class MobileLegal extends React.Component {

    render() {
        return (
            <>
                <MobileHead />
                <div className="mobile-legal">
                    <h2>Legal</h2>
                    <ul className="mobile-nav">
                        <NavLink to="/legal/privacy-policy"><li><p>Privacy Policy</p> <RightArrow /></li></NavLink>
                        <NavLink to="/legal/cookie-statement"><li><p>Cookie Statement</p> <RightArrow /></li></NavLink>
                        <NavLink to="/legal/terms-of-service"><li><p>Terms of Service</p> <RightArrow /></li></NavLink>
                        <NavLink to="/legal/about-us"><li><p>About Us</p> <RightArrow /></li></NavLink>
                        <NavLink to="/legal/version"><li><p>Version</p> <RightArrow /></li></NavLink>
                    </ul>
                </div>
            </>
        );
    }
}

export default MobileLegal;
