import React from "react";

import { CompletionMark } from "../../../assets/icons/icons";
import { leaderboardCopy } from "../../../utils/cms";

const Completion = (props) => {
    if (props.challengecomplete) {
        return (
            <div className="dial--congrats">
                <CompletionMark />
                <h1 className="dial--header">Congratulations</h1>
                <h3 className="dial--copy">
                    You’ve reached your current challenge
                </h3>
            </div>
        );
    }

    return (
        <div className="dial--challengeScore">
            <h2>
                <span>
                    {leaderboardCopy.challengePointsSubHeader.toUpperCase()}
                </span>
            </h2>
            <h3 id={props?.noId && "amplifier-currentChallenge"}>
                {props?.challengepts} <span>pts</span>
            </h3>
        </div>
    );
};

export default Completion;
