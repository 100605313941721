import API from "./API";

const findUnique = (array) => {
    let fullCategories = [];

    array.forEach((QA) => {
        const QAcategories = QA.categories.split(",");
        QAcategories.forEach((category) => fullCategories.push(category));
    });

    const unique = fullCategories.filter((v, i, a) => a.indexOf(v) === i);

    return unique;
};

const findSpecificTag = (tags) => {
    let categories = [];

    tags.forEach((tag) => {
        const cleanedTag = JSON.stringify(tag).replace(/[^a-zA-Z0-9:]+/g, "");
        const splitTag = cleanedTag.split(":");

        if (splitTag[0] === "Category") {
            categories.push(splitTag[1]);
        }
    });

    return categories.toString();
};

const handleFAQs = (QAArray) => {
    QAArray.forEach((QA) => (QA["categories"] = findSpecificTag(QA.tags)));

    const returnValue = {
        faqs: QAArray,
        categories: findUnique(QAArray),
    };

    return returnValue;
};

const getQaAPI = (isAdmin = false, clientName = "", platformName = "") => {
    return API.getSNOWFAQ(isAdmin, clientName, platformName).then((res) => {
        return handleFAQs(res);
    });
};

export { getQaAPI, findUnique, handleFAQs, findSpecificTag };
