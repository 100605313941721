import "./ErrorModal.scss";

import React from "react";
import { Error } from "../../assets/icons/icons";
import ModalWindow from "../ModalWindow";

export default class ErrorModal extends React.Component {
    constructor() {
        super();
        this.onlinePing = null;
        this.state = {
            isDisconnected: false,
        };
    }

    componentDidMount() {
        if (!this.onlinePing) {
            this.onlinePing = setInterval(() => {
                this.handleConnectionChange();
            }, 5000);
        }
    }

    componentWillUnmount() {
        if (this.onlinePing) {
            clearInterval(this.onlinePing);
        }
    }

    handleConnectionChange = () => {
        const isOnline = (navigator.onLine || document.body.onLine);

        this.setState({
            isDisconnected: !isOnline,
        });
    }

    handleModalDisconnected = () => {
        clearInterval(this.onlinePing);

        this.setState({
            isDisconnected: false,
        });
    }

    errorReset = () => {
        this.props.errorReset();
    }

    render() {
        const {
            error,
            errorMessage = "Sorry.. something went wrong.",
        } = this.props;
        const {
            isDisconnected,
        } = this.state;

        return (
            <div>
                {(error) && (
                    <ModalWindow onEscapeModal={this.errorReset}>
                        <div className="error--box">
                            <Error />
                            <p>{errorMessage}</p>
                            <button onClick={this.errorReset}>
                                OK
                            </button>
                        </div>
                    </ModalWindow>
                )}
                {(isDisconnected && !error) && (
                    <ModalWindow onEscapeModal={this.handleModalDisconnected}>
                        <div className="error--box">
                            <Error />
                            <p>Please check your internet connection.</p>
                            <button onClick={this.handleModalDisconnected}>
                                OK
                            </button>
                        </div>
                    </ModalWindow>
                )}
            </div>
        );
    }

}
