import "./Leadboard.scss";

import React from "react";

import RankingTable from "../RankingTable";
import ModuleError from "../../ModuleError";
import { leaderboardCopy } from "../../../utils/cms";

const Leadboard = ({ leaderboardError, onRefresh, leaderboard }) => {
    return (
        <div className="leaderboard--bottom">
            <div className="leaderboard-title">
                <hr />
                <h2>{leaderboardCopy.leaderboardTitle}</h2>
                <span>{leaderboardCopy.leaderboardSubheader}</span>
            </div>
            {leaderboardError ? (
                <div className="min-h-300">
                    <ModuleError onRefresh={onRefresh} />
                </div>
            ) : (
                <>
                    <div className="user-count">
                        <span>
                            {leaderboard?.length || 0}{" "}
                            {leaderboardCopy?.leaderboardUsers}
                        </span>
                    </div>
                    <RankingTable users={leaderboard} />
                </>
            )}
        </div>
    );
};

export default Leadboard;
