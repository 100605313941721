import React, { Component } from "react";
import Error from "../components/Error";
import moment from "moment";

import { getStorage, setStorage } from "../services/window/localStorage";
import {
    ANALYTICS,
    USER,
    CAMPAIGN,
    CHALLENGEPERIOD_PREV,
    WIDGET_DISPLAY,
} from "../services/endpoints/endpoints";
import {
    API_URL,
    SNOW_URL,
    SNOW_USER,
    SNOW_FAQ,
} from "../services/process/env";

import MockAPI from "../mocks/API";
import {
    isInIframe,
    isStaticEnvironment,
    postMessage,
} from "../utils/reusableFunctions";
import { mapDetails } from "../utils/APIHelper";
import { checkToken, decodeToken, getToken } from "../utils/Cookies";

const APIContext = React.createContext();

const httpVerb = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
};

class APIProvider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            apiStatus: "",
            appVersion: "",
            appVersionDate: "",
            chatGroupObj: null,
            campaign: [
                {
                    campaign_description: "",
                    campaignChallengeScore: 0,
                    campaignId: 0,
                    campaignUTCEnd: "",
                    campaignUTCStart: "",
                    gameDuration: 0,
                    isCurrent: 1,
                },
            ],
            company: null,
            errorMsg: "",
            haveSetup: false,
            haveToken: false,
            initialRoute: "/",
            isNewCampaign: undefined,
            isNewUser: undefined,
            isNoscore: "N",
            isTermsAndConditionsAccepted: true,
            partialToken: null,
            permissions: [{}],
            prevCampaign: null,
            resetCampaignEndDate: this.resetCampaignEndDate,
            rsid: null,
            sandboxStillListening: true,
            setChatObj: this.setChatObj,
            showError: false,
            storedReferrerUrl: "",
            style: MockAPI.css,
            tenant: null,
            termsAcceptedTimestamp: null,
            token: null,
            userDetails: {},
            userId: null,
        };
    }

    componentDidMount() {
        this.getAuth();
    }

    // Helper functions
    //#region
    activeAnalyticsCall = (bodyObj) => {
        bodyObj.rsid = this.state.rsid;
        return this.httpRequest(ANALYTICS, httpVerb.POST, bodyObj, {
            "Content-Type": "application/json",
        });
    };

    closeError = () => {
        this.setState({ showError: false });
    };

    checkNoShow = () => {
        //Below is where it is signed in
        this.httpRequest(WIDGET_DISPLAY, httpVerb.GET)
            .then((res) => {
                if (!res || (res.success && res.success === "false")) {
                    return;
                }

                if (res.showWidget === "Y") {
                    postMessage("unhide: ready:guided_learning:jumpseat");
                    this.userCampaign();
                }
            })
            .catch((completeError) => {
                this.handleError(`handleError checkNoShow ${completeError}`);
            });
    };

    getAuth = () => {
        if (isStaticEnvironment()) {
            this.setState({
                enterprisePlatformName: "Oracle HCM",
                haveSetup: true,
                haveToken: true,
                isNewUser: false,
                partialToken: null,
                platformName: "amplifierdev",
                rsid: "amplifierdev",
                tenant: "eewo-dev6",
                token: "test",
                decodeToken: {},
                userDetails: MockAPI.userDetails,
                userId: "mock@data.com",
            });

            sessionStorage.setItem("referrerUrl", "eewo-dev6");

            return;
        }

        const token = getToken();

        if (!token) return;

        const decodedToken = decodeToken(token);

        if (!decodedToken) return;

        if (decodedToken.is_partial && decodedToken.is_partial === "true") {
            this.setState({ partialToken: token, isNewUser: true });
            return;
        }

        this.setTokens({ token, decodedToken });
    };

    getNewMessage = (groupId) => {
        return fetch(
            `${SNOW_URL}/api/x_pwcm2_aiq_chat_1/chat/messages?groupId=${groupId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Basic " + SNOW_USER,
                },
            },
        )
            .then((response) => response.json())
            .then((json) => {
                if (json.success === "false") return json;
                return json.result.messages;
            })
            .catch((err) => {
                if (!this.state.showError && err.result) {
                    this.setState({
                        showError: true,
                        errorMsg: err.result[0].error_message,
                    });
                }
                this.handleError(`handleError getNewMessage: ${err}`);
                return err;
            });
    };

    getRsid = () => {
        return this.state.rsid;
    };

    getSNOWFAQ = (isAdmin, clientName, platform) => {
        return fetch(
            `${SNOW_FAQ}/api/x_pwcm2_aiq_chat_1/faq/questions?tags=Category:Technology,Category:Actions,Category:Scoring,Platform:${platform}${
                isAdmin ? ",Category:Admin" : ""
            }&company=${clientName}`,
            {
                headers: {
                    "Content-Type": "application/json",
                },
            },
        )
            .then((response) => response.json())
            .then((json) => {
                if (json.success === "false") return json;
                return json.result.questions;
            })
            .catch((err) => {
                if (!this.state.showError && err.result) {
                    this.setState({
                        showError: true,
                        errorMsg: err.result[0].error_message,
                    });
                }
                this.handleError(`handleError getSNOWFAQ: ${err}`);
                return err;
            });
    };

    handleError = (completeError) => {
        //console.log(`completeError, APIProvider: $${JSON.stringify(completeError)}`)
        return;
    };

    handleSession(state) {
        this.props.handleSession(state);
    }

    handleSessionRefresh() {
        checkToken(true, "dashboard");
        this.handleSession("expired");
    }

    httpFileDownload = (url, method, payload) => {
        if (!checkToken()) {
            if (isInIframe()) {
                this.handleSessionRefresh();
                return;
            }

            this.handleSession(false);
            return { success: "false" };
        }

        if (this.state.token && this.state.tenant) {
            let config = {
                method: method,
                headers: {
                    Authorization: this.state.token,
                    "X-TENANT-ID": this.state.tenant,
                },
            };

            return fetch(`${API_URL}${url}`, config)
                .then((res) => {
                    if (res && res.status === 401) {
                        if (isInIframe()) {
                            this.handleSessionRefresh();
                            return;
                        }

                        this.handleSession(false);
                        this.setState({ token: null, tenant: null });
                    }
                    this.setState({ apiStatus: res.status });

                    return Promise.resolve(res);
                })
                .catch((err) => {
                    const { apiStatus } = this.state;

                    if (!this.state.showError && apiStatus !== 401) {
                        this.setState({
                            showError: true,
                            errorMsg: err.user_msg,
                        });
                    }
                    this.handleError(`handleError httpFileDownload: ${err}`);

                    return { success: "false", error: err };
                });
        }

        return { success: "false" };
    };

    httpFileUpload = (url, method, payload, headers = {}) => {
        if (!checkToken()) {
            if (isInIframe()) {
                this.handleSessionRefresh();
                return;
            }

            this.handleSession(false);
            return { success: "false" };
        }

        if (this.state.token && this.state.tenant) {
            let config = {
                method: method,
                headers: (headers = {
                    Authorization: this.state.token,
                    "X-TENANT-ID": this.state.tenant,
                    ...headers,
                }),
                body: payload,
            };

            return fetch(`${API_URL}${url}`, config)
                .then((res) => {
                    if (res && res.status === 401) {
                        if (isInIframe()) {
                            this.handleSessionRefresh();
                            return;
                        }

                        this.handleSession(false);
                        this.setState({ token: null, tenant: null });
                    }
                    this.setState({ apiStatus: res.status });

                    return Promise.resolve(res);
                })
                .then((response) => response.json())
                .then((json) => {
                    if (json.success === "false") return json;
                    return json.output;
                })
                .catch((err) => {
                    const { apiStatus } = this.state;

                    if (!this.state.showError && apiStatus !== 401) {
                        this.setState({
                            showError: true,
                            errorMsg: err.user_msg,
                        });
                    }
                    this.handleError(`handleError httpFileUpload: ${err}`);

                    return { success: "false", error: err };
                });
        }

        return { success: "false" };
    };

    httpRequest = (url, method, payload, headers = {}) => {
        if (isStaticEnvironment()) return;

        return new Promise((resolve, reject) => {
            if (!checkToken() || !this.state.token || !this.state.tenant) {
                // If is an iframe must be an active session in parent platform, so by using
                // checkToken it will do the propper redirection to generate the token again and return
                // to the dashboard
                if (isInIframe()) {
                    this.handleSessionRefresh();
                    return;
                }

                this.handleSession(false);
                reject({ success: "false" });
            }

            let config = {
                method: method,
                headers: {
                    Authorization: this.state.token,
                    "X-TENANT-ID": this.state.tenant,
                    "Content-Type": "application/json",
                    ...headers,
                },
            };

            if (method === httpVerb.POST || method === httpVerb.PUT) {
                config.body = JSON.stringify(payload);
            }

            fetch(`${API_URL}${url}`, config)
                .then((res) => {
                    // Unauthorized (token expired)
                    if (res && res.status === 401) {
                        // Trigger token refresh
                        if (isInIframe()) {
                            this.handleSessionRefresh();
                            return;
                        }

                        this.setState({ token: null, tenant: null });
                        this.handleSession(false);

                        reject({ success: "false", status: 401 });
                    }

                    this.setState({ apiStatus: res.status });

                    resolve(res);
                })
                .catch((err) => {
                    reject({ success: "false", error: err });
                });
        })
            .then((response) => {
                if (response.status === 204) {
                    return { success: true, status: 204 };
                }
                return response ? response.json() : { success: "false" };
            })
            .then((json) => {
                if (json) {
                    if (json.status === 204) return json;
                    if (json.success === "false") return json;
                    if (
                        json.output &&
                        (json.output.error ||
                            json.output.error_code ||
                            json.output.err ||
                            json.output.err_code)
                    ) {
                        return { ...json, success: "false" };
                    }
                    return json.output;
                }

                return { success: "false" };
            })
            .catch((err) => {
                const { apiStatus } = this.state;

                if (!this.showError && this.isApiError(apiStatus)) {
                    this.setState({ showError: true, errorMsg: err.user_msg });
                }
                this.handleError(`handleError httpRequest: ${err}`);

                return { success: "false", error: err };
            });
    };

    initiateCalls = () => {
        if (isStaticEnvironment()) return;

        if (this.props.redirectType === "admin") {
            this.userData();
        } else {
            this.checkNoShow();
        }
    };

    isApiError = (apiStatus) => {
        const statusCode = apiStatus.toString();
        return statusCode.startsWith("4") || statusCode.startsWith("5");
    };

    resetCampaignEndDate = () => {
        setStorage("campaignEndDate", this.state.campaign.campaignUTCEnd);
        this.setState({
            isNewCampaign: false,
            prevCampaign: null,
        });
    };

    setChatObj = (chatGroupObj) => {
        this.setState({ chatGroupObj: chatGroupObj });
    };

    setTermsAndConditions = (boolean) => {
        this.setState({ isTermsAndConditionsAccepted: boolean });
    };

    /**
     * setTokens
     * @description Function to set the tokens in the context and initiate calls
     * @param {object} tokens
     * @param {string|null} tokens.token
     * @param {object|null} tokens.decodedToken
     * @returns
     */
    setTokens = ({ token = null, decodedToken = {} }) => {
        if (!token || !decodedToken || Object.keys(decodedToken).length === 0)
            return;

        const tenantDetails = decodedToken.details;

        setStorage("platformName", tenantDetails.platformName);

        this.setState(
            {
                isNewUser: false,
                partialToken: null,
                token,
                rsid: tenantDetails.rsId,
                platformName: tenantDetails.platformName,
                userId: decodedToken.userDetails.aiqUserDetails.email,
                enterprisePlatformName:
                    decodedToken.userDetails.aiqUserDetails
                        .enterprisePlatformName,
                tenant: tenantDetails.tenantId,
                haveSetup: true,
                haveToken: token ? true : this.state.haveToken,
            },
            () => {
                this.initiateCalls();
            },
        );
    };

    snowHttpRequest = (url, method, payload) => {
        let config = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + SNOW_USER,
            },
        };
        if (method === httpVerb.POST || method === httpVerb.PUT) {
            config.body = JSON.stringify(payload);
        }
        return fetch(`${SNOW_URL}${url}`, config)
            .then((response) => {
                if (method === httpVerb.PUT) {
                    return {};
                } else {
                    return response.json();
                }
            })
            .then((json) => {
                if (json.success === "false") return json;
                return json.result;
            })
            .catch((err) => {
                if (!this.state.showError && err.result) {
                    this.setState({
                        showError: true,
                        errorMsg: err.result[0].error_message,
                    });
                }
                this.handleError(`handleError snowHttpRequest: ${err}`);
                return err;
            });
    };

    userCampaign = () => {
        return Promise.all([
            this.httpRequest(USER, httpVerb.GET),
            this.httpRequest(CAMPAIGN, httpVerb.GET),
        ])
            .then((res) => {
                if (
                    !res[0] ||
                    (res[0].success && res[0].success === "false") ||
                    !res[1] ||
                    (res[1].success && res[1].success === "false")
                ) {
                    return;
                }

                setStorage("UserInfo", JSON.stringify(res[0]));
                const currCampaign = res[1].filter(
                    (campaign) => campaign.isCurrent,
                )[0];
                const isNoscore = res[0]?.isNoscore === "Y" ? true : false;
                const campaignEndDate = getStorage("campaignEndDate");
                const getPrevChallenge = (isNewCampaign) => {
                    this.httpRequest(CHALLENGEPERIOD_PREV, httpVerb.GET)
                        .then((res) => {
                            if (
                                !res ||
                                (res.success && res.success === "false")
                            ) {
                                return;
                            }

                            this.setState({
                                isNewCampaign: isNoscore
                                    ? false
                                    : isNewCampaign,
                                prevCampaign: res,
                            });
                        })
                        .catch((completeError) => {
                            this.handleError(
                                `handleError userCampaign: ${completeError}`,
                            );
                            return completeError;
                        });
                };
                if (!campaignEndDate) {
                    getPrevChallenge(true);
                } else {
                    const isNewCampaign = moment(
                        campaignEndDate,
                        "DD-MM-YYYY",
                    ).isBefore(
                        moment(currCampaign.campaignUTCEnd, "DD-MM-YYYY"),
                    );

                    if (isNewCampaign) {
                        getPrevChallenge(isNewCampaign);
                    } else {
                        this.setState({ isNewCampaign });
                    }
                }

                setStorage("UserDetails", JSON.stringify(res[1]));
                postMessage("loaded: ready:amp version date(6/15/22)");

                const cssText =
                    res[0].cssVars === null || res[0].cssVars === ""
                        ? this.state.style
                        : res[0].cssVars;

                this.setState({
                    appVersion: res[0].appVersion,
                    appVersionDate: res[0].appVersionDate,
                    campaign: currCampaign,
                    company: currCampaign.campaignChallengeScore,
                    isChatEnabled: res[0].isChatEnabled,
                    isNoscore: res[0].isNoscore,
                    isTermsAndConditionsAccepted:
                        !!res[0].termsAcceptedTimestamp,
                    permissions: res[0].permissions,
                    style: cssText,
                    termsAcceptedTimestamp: res[0].termsAcceptedTimestamp,
                    userDetails: mapDetails(res[0]),
                });
            })
            .catch((completeError) => {
                this.handleError(`handleError userCampaign: ${completeError}`);
            });
    };

    userData = () => {
        this.httpRequest(USER, httpVerb.GET)
            .then((res) => {
                if (!res || (res.success && res.success === "false")) {
                    return;
                }

                const cssText =
                    res.cssVars === null || res.cssVars === ""
                        ? this.state.style
                        : res.cssVars;
                this.setState({
                    appVersion: res.appVersion,
                    appVersionDate: res.appVersionDate,
                    isTermsAndConditionsAccepted: !!res.termsAcceptedTimestamp,
                    permissions: res.permissions,
                    style: cssText,
                    termsAcceptedTimestamp: res.termsAcceptedTimestamp,
                });
            })
            .catch((completeError) => {
                this.handleError(`handleError userData: ${completeError}`);
            });
    };
    //#endregion

    render() {
        return (
            <>
                <APIContext.Provider
                    value={{
                        getRsid: this.getRsid,
                        httpRequest: this.httpRequest,
                        httpFileUpload: this.httpFileUpload,
                        httpFileDownload: this.httpFileDownload,
                        activeAnalyticsCall: this.activeAnalyticsCall,
                        getSNOWFAQ: this.getSNOWFAQ,
                        getNewMessage: this.getNewMessage,
                        snowHttpRequest: this.snowHttpRequest,
                        initiateCalls: this.initiateCalls,
                        setTokens: this.setTokens,
                        setTermsAndConditions: this.setTermsAndConditions,
                        ...this.state,
                    }}
                >
                    {(this.state.haveToken && this.state.haveSetup) ||
                    this.state.isNewUser ? (
                        this.props.children
                    ) : (
                        <div />
                    )}
                </APIContext.Provider>
                <Error
                    error={this.state.showError}
                    errorMessage={this.state.errorMsg}
                    errorReset={this.closeError}
                />
                <style>{this.state.style}</style>
            </>
        );
    }
}

export { APIContext, APIProvider, httpVerb };
