import { frequencyNormalize } from "../../utils/timeHelper";

/**
 * sortRules
 * @description Sorts an array of rules based on the specified type and order.
 * @param {Array<Object>} array - The array of rules to be sorted.
 * @param {string} type - The type by which the rules should be sorted ("score", "behavior", or other property).
 * @param {boolean} reverse - If true, the sorting order is reversed; otherwise, it is ascending.
 * @returns {Array<Object>} - The sorted array of rules.
 *
 * @example
 * const originalRules = [
 *   { name: 'Rule1', score: 50, behavior: 'TypeA', frequency: '12:30' },
 *   { name: 'Rule2', score: 75, behavior: 'TypeB', frequency: '08:45' },
 *   // ...
 * ];
 *
 * const sortedRules = sortRules(originalRules, "score", true);
 * // sortedRules will contain rules sorted by score in descending order.
 */
const sortRules = (array, type, reverse) => {
    if (!Array.isArray(array)) return [];
    
    if (!type) return array;

    if (type === "score") {
        return array.sort((a, b) => {
            const score = a.score - b.score;
            return reverse ? -score : score;
        });
    }

    if (type === "behavior") {
        return array.sort((a, b) => {
            return sortString(a[type], b[type], reverse);
        });
    }

    return array.sort((a, b) => {
        const nameA = frequencyNormalize(a[type]).toUpperCase();
        const nameB = frequencyNormalize(b[type]).toUpperCase();

        return sortString(nameA, nameB, reverse);
    });
};

/**
 * sortString
 * @description Comperes strings and returns a value depending on sorting
 * @param {string} stringA 
 * @param {string} stringB 
 * @param {boolean} reverse 
 * @returns {number} 
 */
const sortString = (stringA, stringB, reverse) => {
    if (stringA < stringB) {
        return reverse ? 1 : -1;
    }

    if (stringA > stringB) {
        return reverse ? -1 : 1;
    }

    return 0;
};

export {
    sortRules,
    sortString,
}