/*eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";

const Cookie = (props) => (
    <>
        <h3>
            <strong>Cookie information</strong>
        </h3>
        <p>
            This cookie information is relevant for visitors to Amplifier. For
            more information about how we process your personal information
            collected through Amplifier, please visit our Amplifier{" "}
            <a href="#" onClick={() => props.changeSelected(0)}>
                privacy statement
            </a>
            .
        </p>
        <p>
            Cookies are small text files that are placed on your device by the
            websites and applications that you visit. They are widely used in
            order to make websites and apps work, or work more efficiently, as
            well as to provide information to the owners of the site or app. The
            use of cookies and similar technologies is now standard for most
            websites and apps.
        </p>
        <h3>
            <strong>Managing cookies on your device</strong>
        </h3>
        <p>
            You can control and manage cookies using your browser settings as
            well as certain opt&#45;out tools (see below). Please note that if
            you are accessing this application using multiple browsers or
            devices, you will need to manage cookies on each browser and device,
            as applicable. Please also note that removing or blocking cookies
            can impact your user experience and some functionality may no longer
            be available.
        </p>
        <h3>
            <strong>Using your browser to control cookies</strong>
        </h3>
        <p>
            Most browsers allow you to view, manage, delete and block cookies
            for a website or application. Be aware that if you delete all
            cookies then any preferences you have set will be lost, including
            the ability to opt&#45;out from cookies as this function itself
            requires placement of an opt out cookie on your device. Guidance on
            how to control cookies for common browsers is linked below.
        </p>
        <p>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
            >
                <strong>Google Chrome</strong>
            </a>
        </p>
        <p>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
            >
                <strong>Mozilla Firefox</strong>
            </a>
        </p>
        <p>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
            >
                <strong>MacOS Safari</strong>
            </a>
        </p>
        <p>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
            >
                <strong>Microsoft Internet Explorer</strong>
            </a>
        </p>
        <p>
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.microsoft.com/en-us/help/4027947/microsoft-edge-delete-cookies"
            >
                <strong>Microsoft Edge</strong>
            </a>
        </p>
        <h3>
            <strong>Opt&#45;out tools to control cookies</strong>
        </h3>
        <p>
            Some of the third parties that collect information from or about you
            on PwC sites or apps may participate in the Digital Advertising
            Alliance&#39;s (&quot;DAA&quot;) self-regulatory program. To make
            choices about the use of information from or about you for online
            behavioral or interest-based advertising on websites (desktop and
            mobile browsers), please visit the{" "}
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://optout.aboutads.info/"
            >
                DAA
            </a>
            , and within mobile applications, you can download the DAA&#39;s
            AppChoices application from your mobile device at{" "}
            <a
                rel="noopener noreferrer"
                target="_blank"
                href="http://www.aboutads.info/appchoices"
            >
                www.aboutads.info/appchoices
            </a>{" "}
            or from your device&#39;s app store.
        </p>
        <p>
            You can set your browser to block or alert you about these cookies,
            but some parts of the application may no longer work.
        </p>
        <h3>
            <strong>Use of cookies</strong>
        </h3>
        <p>
            Below is a detailed list of the cookies we use on this application.
            We classify the use of all Amplifier cookies as necessary. You can
            find more information about each category in the relevant sections
            below. For each cookie listed below, we also note the duration of
            the cookie and identify whether it is a first party or third party
            cookie. Please note:
        </p>
        <p>
            <strong>&#39;First party&#39;</strong> cookies are directly stored
            by the website (or domain) you visit. These cookies allow website
            owners to collect analytics data, remember language settings, and
            perform other useful functions that provide a good user experience.
        </p>
        <p>
            <strong>&#39;Third party&#39;</strong> cookies are created by
            domains that are not the website (or domain) that you are visiting.
            These are usually used for analytics and/or online-advertising
            purposes and placed on a website through scripts or tags. A
            third&ndash;party cookie is accessible on any website that loads the
            third party server&#39;s code.
        </p>
        <p>
            <strong>
                <i>Necessary cookies</i>
            </strong>
        </p>
        <p>
            These cookies are necessary for the website to operate. Our website
            cannot function without these cookies and they can only be disabled
            by changing your browser preferences.
        </p>
        <div className="res-table">
            <table>
                <tbody>
                    <tr>
                        <td>
                            <p>
                                <strong>Cookie Name</strong>
                            </p>
                        </td>
                        <td>
                            <p>
                                <strong>Host</strong>
                            </p>
                        </td>
                        <td>
                            <p>
                                <strong>Cookie Type</strong>
                            </p>
                        </td>
                        <td>
                            <p>
                                <strong>Life Span</strong>
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>incap_ses*</p>
                        </td>
                        <td>
                            <p>amplifier.pwc.com</p>
                        </td>
                        <td>
                            <p>First Party</p>
                        </td>
                        <td>
                            <p>Session</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Visid_incap*</p>
                        </td>
                        <td>
                            <p>amplifier.pwc.com</p>
                        </td>
                        <td>
                            <p>First Party</p>
                        </td>
                        <td>
                            <p>1 year</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>isNoscore</p>
                        </td>
                        <td>
                            <p>pwc.com</p>
                        </td>
                        <td>
                            <p>First Party</p>
                        </td>
                        <td>
                            <p>Persistent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>PlatformName</p>
                        </td>
                        <td>
                            <p>pwc.com</p>
                        </td>
                        <td>
                            <p>First Party</p>
                        </td>
                        <td>
                            <p>Persistent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>UserInfo</p>
                        </td>
                        <td>
                            <p>pwc.com</p>
                        </td>
                        <td>
                            <p>First Party</p>
                        </td>
                        <td>
                            <p>Persistent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>UserDetails</p>
                        </td>
                        <td>
                            <p>pwc.com</p>
                        </td>
                        <td>
                            <p>First Party</p>
                        </td>
                        <td>
                            <p>Persistent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>Jumpseat_session</p>
                        </td>
                        <td>
                            <p>jumpseat.io</p>
                        </td>
                        <td>
                            <p>Third Party</p>
                        </td>
                        <td>
                            <p>Persistent</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>XSRF-TOKEN</p>
                        </td>
                        <td>
                            <p>jumpseat.io</p>
                        </td>
                        <td>
                            <p>Third Party</p>
                        </td>
                        <td>
                            <p>Session</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>user</p>
                        </td>
                        <td>
                            <p>jumpseat.io</p>
                        </td>
                        <td>
                            <p>Third Party</p>
                        </td>
                        <td>
                            <p>1 year</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p>campaignEndDate</p>
                        </td>
                        <td>
                            <p>pwc.com</p>
                        </td>
                        <td>
                            <p>First Party</p>
                        </td>
                        <td>
                            <p>Persistent</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </>
);

export default Cookie;
