import "./Skeleton.scss";

import React from "react";

const Skeleton = (props) => (
    <div
        style={{ height: props.height, width: props.width }}
        className={"skeleton " + props.className}
    >
        <span>&nbsp;</span>
        {props.children}
    </div>
);

export default Skeleton;
