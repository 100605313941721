import "./ScoringRules.scss";

import React from "react";

import MockAPI from "../../mocks/API";
import Rules from "../../components/Rules/Rules";
import RulesTableContent from "../../components/Rules/RulesTableContent/RulesTableContent";
import { APIContext, httpVerb } from "../../context/APIProvider";
import { SCORING_RULES } from "../../services/endpoints/endpoints";
import { rulesTableCopy } from "../../utils/cms";
import { isStaticEnvironment } from "../../utils/reusableFunctions";
import { sortRules } from "./helper";

export default class ScoringRules extends React.Component {
    static contextType = APIContext;

    constructor(props) {
        super(props);

        this.selectRef = React.createRef();
        this.state = {
            sortType: "score",
            reverse: true,
            rules: [],
            height: 0,
        };
        this.getScoringRules = this.getScoringRules.bind(this);
        this.updateTableBodyHeight = this.updateTableBodyHeight.bind(this);
    }

    componentDidMount() {
        this.getScoringRules();
        this.updateTableBodyHeight();

        window.addEventListener("resize", this.updateTableBodyHeight);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateTableBodyHeight);
    }

    updateTableBodyHeight = () => {
        const height = this.divElement?.clientHeight || 0;

        this.setState({ height });
    };

    setScoringRulesData = (data) => {
        this.setState({ rules: data }, this.updateTableBodyHeight());
    };

    getScoringRules = () => {
        if (isStaticEnvironment()) {
            this.setScoringRulesData(MockAPI.rules);

            return;
        }

        this.context
            .httpRequest(SCORING_RULES, httpVerb.GET)
            .then((res) => {
                if (!res || (res.success && res.success === "false")) {
                    return;
                }

                this.setScoringRulesData(res);
            })
            .catch((err) => {
                return err;
            });
    };

    updateTypeChange = (newSortType) => {
        newSortType === this.state.sortType
            ? this.setState({ reverse: !this.state.reverse })
            : this.setState({ reverse: true, sortType: newSortType, });
    };

    handleOptionsEvents = (typeChange, event) => {
        if (event.type === "click" || (event.type === "keydown" && (event.key === "Enter" || event.key === "ArrowDown" || event.key === "ArrowUp"))) {
            event.preventDefault();

            this.updateTypeChange(typeChange);

            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
                this.handleRefFocus();
            }
        }
    };

    handleRefFocus = () => {
        this.selectRef.current.tabIndex = 0;
        this.selectRef.current.focus();
    }

    render() {
        const initialHeight = this.state.height - 90;

        const styleObj = {
            height: initialHeight + "px",
        };

        return (
            <div className="sidebar--body">
                <Rules />
                <div className="rulestable">
                    <h4>{rulesTableCopy.rulesTableHeader}</h4>
                    <div
                        className="rulestable--padding"
                        ref={(divElement) => {
                            this.divElement = divElement;
                        }}
                    >
                        <div className="rulestable--psides">
                            <table>
                                <thead>
                                    <tr className="rulestable--headers">
                                        <th>
                                            <button
                                                onClick={(event) =>
                                                    this.handleOptionsEvents(
                                                        "behavior",
                                                        event,
                                                    )
                                                }
                                                onKeyDown={(event) =>
                                                    this.handleOptionsEvents(
                                                        "behavior",
                                                        event,
                                                    )
                                                }
                                            >
                                                {rulesTableCopy.rulesTableCol1.toUpperCase()}
                                                <span
                                                    className={
                                                        this.state?.sortType ===
                                                            "behavior" &&
                                                        this.state?.reverse
                                                            ? "rulestable--triangle"
                                                            : "rulestable--triangle rotate180"
                                                    }
                                                ></span>
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                onClick={(event) =>
                                                    this.handleOptionsEvents(
                                                        "score",
                                                        event,
                                                    )
                                                }
                                                onKeyDown={(event) =>
                                                    this.handleOptionsEvents(
                                                        "score",
                                                        event,
                                                    )
                                                }
                                            >
                                                {rulesTableCopy.rulesTableCol2.toUpperCase()}
                                                <span
                                                    className={
                                                        this.state?.sortType ===
                                                            "score" &&
                                                        this.state?.reverse
                                                            ? "rulestable--triangle"
                                                            : "rulestable--triangle rotate180"
                                                    }
                                                ></span>
                                            </button>
                                        </th>
                                        <th>
                                            <button
                                                onClick={(event) =>
                                                    this.handleOptionsEvents(
                                                        "frequency",
                                                        event,
                                                    )
                                                }
                                                onKeyDown={(event) =>
                                                    this.handleOptionsEvents(
                                                        "frequency",
                                                        event,
                                                    )
                                                }
                                            >
                                                {rulesTableCopy.rulesTableCol3.toUpperCase()}
                                                <span
                                                    className={
                                                        this.state?.sortType ===
                                                            "frequency" &&
                                                        this.state?.reverse
                                                            ? "rulestable--triangle"
                                                            : "rulestable--triangle rotate180"
                                                    }
                                                ></span>
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                {this.state.rules?.length > 0 && (
                                    <tbody
                                        style={styleObj}
                                        ref={this.selectRef}
                                    >
                                        {sortRules(
                                            this.state.rules,
                                            this.state.sortType,
                                            this.state.reverse,
                                        ).map((element, i) => (
                                            <RulesTableContent
                                                element={element}
                                                key={"rule" + i}
                                            />
                                        ))}
                                    </tbody>
                                )}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
