import React from "react";
import "./Chat.scss";
import ChatLine from "../ChatLine";

export default class Chat extends React.Component {
    constructor(props){
        super(props);
        
        this.messagesEnd = null;
    }

    scrollToBottom = () => {
        if(this.messagesEnd) {
            this.messagesEnd.scrollIntoView({behavior: "smooth"});
        }
    }

    render() {
        const { chatFeed = [] } = this.props;
        const reversed = chatFeed?.slice()?.reverse();

        return (
            <div className="flex-content chat">
                {reversed.map((instance, index) => (
                    <div key={"chat"+index}
                        ref={(el) => {
                            if(index === 0){
                                this.messagesEnd = el; 
                                this.scrollToBottom();
                            }
                        }} >
                        <ChatLine
                            message={instance.message} 
                            isUser={instance.isUser} 
                            author = {instance.author}
                            user={{photo: "", name: this.props.userName}}
                            timestamp={instance.timestamp}
                        />
                    </div>
                ))}
                <div style={{ 
                    float:"left", 
                    clear: "both" 
                }}
                />
            </div>
        );
    }
}
