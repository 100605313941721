import React from "react";
import "./HelpEditSubmit.scss";
import ModalWindow from "../../ModalWindow";

const HelpEditSubmit = (props) => {
    let {
        submission,
        onChangeSub,
        onCancel,
        onSubmit,
        onEscapeModal
    } = props;
    return (
        <ModalWindow className={'help-edit'} onEscapeModal={onEscapeModal}>
            <form className="helpSubmitModal" onSubmit={onSubmit}>
                <button className="close" onClick={onCancel} tabIndex={4}>+</button>
                <h2>Submit or edit your question</h2>
                <textarea onChange={onChangeSub} value={submission} tabIndex={1} />
                <div>
                    <button type="button" className="btn btn__light btn__mt" onClick={onCancel} tabIndex={2}>Cancel</button>
                    <button type="submit" className="btn btn__light btn__mt btn__ml" tabIndex={3}>Submit</button>
                </div>
            </form>
        </ModalWindow>
    );
};



export default HelpEditSubmit;