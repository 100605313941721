import React from "react";
import "./EndOfHelp.scss";

const EndOfHelp = (props) => (
    <div className="EndOfHelp nohelp--fadein">
        <p className="center">Don’t see what you’re looking for?</p>
        <p className="center">Submit your question.</p>
        <button onClick={props.submit} className="center btn btn__primary btn__mt">{"Submit".toUpperCase()}</button>
    </div>
);

export default EndOfHelp;