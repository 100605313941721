import React from "react";
import "./HelpTable.scss";
import QA from "../QA";
import { helpCopy } from "../../../utils/cms";

const Category = props =>
    <button
        className={`helptable--category ${props.active ? "helptable--category__active selectable" : "helptable--category__default selectable"}`}
        onClick={() => {
            return props.click(props.title);
        }}>
        <span>{props.title.toUpperCase()}</span>
        <span className="helptable--total">{props.total}</span>
    </button>
    ;

export default class HelpTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: "All"
        };
    }

    _click = (category) => {
        this.setState({ active: category });
    }


    render() {
        let { className, faqs, categories, submission } = this.props;
        return (
            <div className={className}>
                <div className="header-div">
                    <h1 className="h1 helptable--copy">{helpCopy.helpTableHeader}</h1>
                    <div><span className="submission-text">{helpCopy.submitText}</span><button className="submit-link" onClick={submission}>{helpCopy.submitQuestion}</button></div>
                </div>
                <div className="relative helptable">
                    <div className="helptable--left">
                        <Category click={this._click} title={"All"} active={(this.state.active === "All")} total={faqs.length} />
                        {

                            categories.map((category, i) => {
                                // console.log(faqs[0].category);
                                const filtered = faqs.filter((bigElement) => bigElement.categories.split(",").indexOf(category) !== -1).length;
                                return <Category key={"category" + i} click={this._click} title={category} active={(this.state.active === category)} total={filtered} />;
                            })
                        }
                    </div>
                    <div className="helptable--right" tabIndex="0">
                        {faqs.filter((qa) => (this.state.active === "All") ? true : (qa.categories.split(",").indexOf(this.state.active) !== -1)).map((element, index) => {
                            return (
                                <QA question={element.question} answer={element.answer} id={element.categories + index} key={"qa" + index} />
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}