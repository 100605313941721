import React from "react";
import "./Leaderboard.scss";
import D3Piechart from "../Piechart";
import UserDial from "../UserDial";
import Leadboard from "../Leadboard";
import { leaderboardCopy } from "../../../utils/cms";
import { Why } from "../../../assets/icons/icons";
import moment from "moment";
import { APIContext, httpVerb } from "../../../context/APIProvider";
import { massageBreakdown, massageLeaderboard } from "../../../utils/APIHelper";
import {
    USER_SCORE,
    USER_BREAKDOWN,
    LEADERBOARD,
} from "../../../services/endpoints/endpoints";
import ModuleError from "../../ModuleError";
import {
    isStaticEnvironment,
    postMessage,
} from "../../../utils/reusableFunctions";
import MockUserBreakDown from "../../../mocks/user-breakdown";
import leaderboard from "../../../mocks/leaderboard";
import MockAPI from "../../../mocks/API";
import {
    handleBreakdownResponse,
    handleHttpResponse,
    handleScoreResponse,
} from "./helper";

const leaderboardMock = {
    rank: null,
    name: null,
    score: null,
    last_updated: new Date(),
};

export default class Leaderboard extends React.Component {
    static contextType = APIContext;

    _isMounted = false;

    constructor(props) {
        super(props);

        this.state = {
            showPie: true,
            lastUpdated: moment(leaderboardMock.last_updated).fromNow(),
            current_score: "",
            breakdown: [],
            leaderboard: new Array(7).fill(leaderboardMock),
            userScoreError: false,
            userBreakdownError: false,
            leaderboardError: false,
            overflowAuto: true,
        };
    }

    componentDidMount() {
        this._isMounted = true;

        this.dashboardDetails();
        this.timer = setInterval(() => {
            return this.setState({
                lastUpdated: moment(
                    this.state.leaderboard[0].last_updated,
                ).fromNow(),
            });
        }, 1000);
        this.dashboardTimer = setInterval(() => this.dashboardDetails(), 60000);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.timer);
        clearInterval(this.dashboardTimer);
    }

    handleError = (completeError) => {
        //console.log(completeError, "completeError, Leaderboard");
        return;
    };

    getUserDetails = () => {
        if (isStaticEnvironment()) {
            this.setState(
                {
                    breakdown: massageBreakdown(MockUserBreakDown),
                    userBreakdownError: false,
                    current_score: MockAPI.userScore.current_score,
                    userScoreError: false,
                },
                () => {
                    postMessage("loaded: ready:amp version date(6/15/22)");
                },
            );

            return;
        }

        Promise.all([
            this.context.httpRequest(USER_SCORE, httpVerb.GET),
            this.context.httpRequest(USER_BREAKDOWN, httpVerb.GET),
        ])
            .then(([userScoreResponse, userBreakdownResponse]) => {
                if (this._isMounted) {
                    handleHttpResponse(
                        this,
                        userScoreResponse,
                        { userScoreError: true },
                        handleScoreResponse(this, userScoreResponse),
                    );

                    handleHttpResponse(
                        this,
                        userBreakdownResponse,
                        { userBreakdownError: true },
                        handleBreakdownResponse(this, userBreakdownResponse),
                    );
                }
            })
            .catch((completeError) => {
                this.handleError(completeError);
            });
    };

    getLeaderboardDetails = () => {
        const t = this;

        if (isStaticEnvironment()) {
            t.setState({
                leaderboard: massageLeaderboard(leaderboard),
                leaderboardError: false,
            });

            return;
        }

        t.context.httpRequest(LEADERBOARD, httpVerb.GET).then((res) => {
            if (t._isMounted) {
                if (!res || (res.success && res.success === "false")) {
                    t.setState({ leaderboardError: true });
                    return;
                }

                t.setState({
                    leaderboard: massageLeaderboard(res),
                    leaderboardError: false,
                });
            }
        });
    };

    dashboardDetails = () => {
        this.getUserDetails();
        this.getLeaderboardDetails();
    };

    checkCurrentScore = (score) => {
        if (score !== undefined && score !== this.state.current_score) {
            postMessage(`score: ${score}`);
        }
    };

    animationFlip = () => {
        this.setState({ showPie: !this.state.showPie }, () => {
            setTimeout(() => {
                this.setState({ overflowAuto: !this.state.overflowAuto });
            }, 600);
        });
    };

    render() {
        let {
            breakdown,
            current_score,
            leaderboard,
            userScoreError,
            leaderboardError,
            userBreakdownError,
            overflowAuto,
        } = this.state;

        let { company, campaign } = this.props;

        const issue =
            company === null || company === undefined || current_score === 0;

        return (
            <div
                className={
                    this.state.showPie
                        ? "main--content leaderboard--left"
                        : "main--content leaderboard--left hover"
                }
            >
                <div className="flipper--wrapper">
                    <div
                        className={
                            this.state.overflowAuto
                                ? "flipper overflow-auto"
                                : "flipper"
                        }
                        tabIndex="0"
                    >
                        <div className="front">
                            {current_score > 0 && (
                                <button
                                    aria-label="Current Challenge Behaviors"
                                    className={`flipper--close flipper--close__front selectable ${
                                        issue ? "hidden" : ""
                                    }`}
                                    onClick={this.animationFlip}
                                >
                                    <Why />
                                </button>
                            )}

                            <UserDial
                                userScoreError={userScoreError}
                                onRefresh={this.getUserDetails}
                                onClick={issue ? null : this.animationFlip}
                                company={company}
                                campaign={campaign}
                                currentScore={current_score}
                            />
                            <Leadboard
                                leaderboard={leaderboard}
                                leaderboardError={leaderboardError}
                                onRefresh={this.getLeaderboardDetails}
                            />
                        </div>
                        <div className="back">
                            <span
                                className="flipper--close flipper--close__back selectable"
                                onClick={this.animationFlip}
                            >
                                +
                            </span>
                            {userBreakdownError ? (
                                <div className="dial">
                                    <ModuleError
                                        onRefresh={this.getUserDetails}
                                    />
                                </div>
                            ) : (
                                <D3Piechart
                                    currpts={current_score}
                                    data={breakdown}
                                    overflowAuto={overflowAuto}
                                />
                            )}
                        </div>
                    </div>
                    {!leaderboardError && (
                        <p className="rankingTable--update">
                            {leaderboardCopy.leaderboardUpdate}{" "}
                            {this.state.lastUpdated}
                        </p>
                    )}
                </div>
            </div>
        );
    }
}
