const MASTERURL = "/master/xdp/v1/amplifier";
const SERVICEURL = "/service/xdp/v1/adoptlearn";

const ACCEPT_TERMS = `${SERVICEURL}/user/userUpdateTermsTimestamp`;
const ANALYTICS = `${SERVICEURL}/proxy/analytics/pwc1/reports`;
const ATTRIBUTE_GROUP_TYPES = `${SERVICEURL}/leaderboard/groupTypes`;
const CAMPAIGN = `${SERVICEURL}/campaign`;
const CHALLENGEPERIOD_ALL = `${SERVICEURL}/challengePeriod/all`;
const CHALLENGEPERIOD_PREV = `${SERVICEURL}/challengePeriod/prev`;
const DASHBOARD_DETAILS = `${SERVICEURL}/aggregate/dashboardDetails`;
const GAMERULES = `${SERVICEURL}/challengePeriod/gameRules`;
const GET_NOTIFICATIONS = `${SERVICEURL}/getNotifications`;
const LEADERBOARD = `${SERVICEURL}/leaderboard`;
const MULTICHALLENGES = `${SERVICEURL}/multichallenges`;
const SCORING_RULES = `${SERVICEURL}/scoring-rules`;
const SHORTCUTS = `${SERVICEURL}/shortcuts`;
const SHORTCUTS_MY = `${SERVICEURL}/shortcuts/my`;
const TENANT_DETAILS = `${MASTERURL}/tenant/getTenantDetails`;
const USER = `${SERVICEURL}/user`;
const USER_BREAKDOWN = `${SERVICEURL}/user-score/breakdown`;
const USER_SCORE = `${SERVICEURL}/user-score`;
const WIDGET_DISPLAY = `${SERVICEURL}/widgetDisplay`;


export {
    ACCEPT_TERMS,
    ANALYTICS,
    ATTRIBUTE_GROUP_TYPES,
    CAMPAIGN,
    CHALLENGEPERIOD_ALL,
    CHALLENGEPERIOD_PREV,
    DASHBOARD_DETAILS,
    GAMERULES,
    GET_NOTIFICATIONS,
    LEADERBOARD,
    MULTICHALLENGES,
    SCORING_RULES,
    SHORTCUTS_MY,
    SHORTCUTS,
    TENANT_DETAILS,
    USER_BREAKDOWN,
    USER_SCORE,
    USER,
    WIDGET_DISPLAY
};