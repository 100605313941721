import ENV_CONFIG from '../../config';

const BASE_URL = ENV_CONFIG?.REACT_APP_BASE;
const API_URL = ENV_CONFIG?.REACT_APP_API;
const ADMIN_URL = ENV_CONFIG?.REACT_APP_ADMIN_URL;
const DASHBOARD_URL = ENV_CONFIG?.REACT_APP_DASHBOARD_URL;
const SHOW_SETTINGS = ENV_CONFIG?.REACT_APP_SHOW_SETTINGS;

const HASH = ENV_CONFIG?.REACT_APP_CLEAR_HASH;

const SNOW_URL = ENV_CONFIG?.REACT_APP_SNOW;
const SNOW_FAQ = ENV_CONFIG?.REACT_APP_SNOW_FAQ;
const SNOW_USER = ENV_CONFIG?.REACT_APP_SNOW_USER;

export {
    BASE_URL,
    API_URL,
    ADMIN_URL,
    DASHBOARD_URL,
    SHOW_SETTINGS,
    HASH,
    SNOW_URL,
    SNOW_FAQ,
    SNOW_USER,
};