import React from "react";
import "./MobileLeaderboard.scss";
import MobileHead from "../MobileHead";
import { massageLeaderboard } from "../../utils/APIHelper";
import Leadboard from "../Leaderboard/Leadboard";
import { leaderboardCopy } from "../../utils/cms";
import moment from "moment";
import { LEADERBOARD } from "../../services/endpoints/endpoints";
import { APIContext, httpVerb } from "../../context/APIProvider";
import { isStaticEnvironment } from "../../utils/reusableFunctions";

const leaderboardMock = {
    rank: null,
    name: null,
    score: null,
    last_updated: new Date()
};

class MobileLeaderboard extends React.Component {
    static contextType = APIContext;
    constructor(props) {
        super(props);
        this.timer = null;
        this.dashboardTimer = null;
        this.mount = true;
        this.state = {
            lastUpdated: moment(leaderboardMock.last_updated).fromNow(),
            leaderboard: new Array(7).fill(leaderboardMock),
            leaderboardError: false
        };
    }

    componentDidMount() {
        this.getLeaderboardDetails();
        this.timer = setInterval(() => {
            this.setState({ lastUpdated: moment(this.state.leaderboard[0].last_updated).fromNow() });
        }, 1000);
        this.dashboardTimer = setInterval(() => this.getLeaderboardDetails(), 60000);
    }

    componentWillUnmount() {
        if (this.timer)
            clearInterval(this.timer);
        if (this.dashboardTimer)
            clearInterval(this.dashboardTimer);
        this.mount = false;
    }

    getLeaderboardDetails = () => {
        if (isStaticEnvironment) {
            return;
        }
        
        this.context.httpRequest(LEADERBOARD, httpVerb.GET).then(res => {
            if (!res || (res.success && res.success === 'false')) {
                if (this.mount) this.setState({ leaderboardError: true });
                
                return;
            }
            
            if (this.mount) this.setState({ leaderboard: massageLeaderboard(res), leaderboardError: false });
        })
    }

    render() {
        const { leaderboard, leaderboardError, lastUpdated } = this.state;
        return (
            <div className="mobile-leaderboard">
                <MobileHead />
                <Leadboard leaderboard={leaderboard} leaderboardError={leaderboardError}
                    onRefresh={this.getLeaderboardDetails} />
                {!leaderboardError &&
                    <p className="rankingTable--update">{leaderboardCopy.leaderboardUpdate} {lastUpdated}</p>
                }
            </div>
        );
    }
}

export default MobileLeaderboard;
