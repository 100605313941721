const ENVS = {
    // * LOCAL
    'localhost:3000': {
        REACT_APP_BASE: "https://amplifierdev.pwc.com",
        REACT_APP_DASHBOARD_URL: "https://amplifierdev.pwc.com/dashboard",
        REACT_APP_ADMIN_URL: "https://amplifierdev.pwc.com/admin",
        REACT_APP_API: "https://amplifierdev.pwc.com",
        REACT_APP_SNOW: "https://pwccstest.service-now.com",
        REACT_APP_SNOW_FAQ: "https://pwccstest.service-now.com",
        REACT_APP_SNOW_USER: "c2FfYWlxOkExUVBANTV3MHJk",
        REACT_APP_TENANTID: "4b30ae91-543f-49b3-882e-ee7db9370a9b",
        REACT_APP_CLEAR_HASH: true,
        REACT_APP_SHOW_SETTINGS: true,
    },

    // * DEVELOPMENT
    'amplifierdev.pwc.com': {
        REACT_APP_BASE: "https://amplifierdev.pwc.com",
        REACT_APP_DASHBOARD_URL: "https://amplifierdev.pwc.com/dashboard",
        REACT_APP_ADMIN_URL: "https://amplifierdev.pwc.com/admin",
        REACT_APP_API: "https://amplifierdev.pwc.com",
        REACT_APP_SNOW: "https://pwccstest.service-now.com",
        REACT_APP_SNOW_FAQ: "https://pwccstest.service-now.com",
        REACT_APP_SNOW_USER: "c2FfYWlxOkExUVBANTV3MHJk",
        REACT_APP_TENANTID: "4b30ae91-543f-49b3-882e-ee7db9370a9b",
        REACT_APP_CLEAR_HASH: true,
        REACT_APP_SHOW_SETTINGS: true,
    },

    // * PRODUCTION
    'amplifier.pwc.com': {
        REACT_APP_BASE: "https://amplifier.pwc.com",
        REACT_APP_DASHBOARD_URL: "https://amplifier.pwc.com/dashboard",
        REACT_APP_ADMIN_URL: "https://amplifier.pwc.com/admin",
        REACT_APP_API: "https://amplifier.pwc.com",
        REACT_APP_SNOW: "https://pwcmspcv.service-now.com",
        REACT_APP_SNOW_FAQ: "https://pwcmspcv.service-now.com",
        REACT_APP_SNOW_USER: "c2FfYWlxOkExUVBANTV3MHJk",
        REACT_APP_CLEAR_HASH: true,
        REACT_APP_SHOW_SETTINGS: false,
    },

    // * QA
    'amplifierqa.pwc.com': {
        REACT_APP_BASE: "https://amplifierqa.pwc.com",
        REACT_APP_DASHBOARD_URL: "https://amplifierqa.pwc.com/dashboard",
        REACT_APP_ADMIN_URL: "https://amplifierqa.pwc.com/admin",
        REACT_APP_API: "https://amplifierqa.pwc.com",
        REACT_APP_SNOW: "https://pwccstest.service-now.com",
        REACT_APP_SNOW_FAQ: "https://pwccstest.service-now.com",
        REACT_APP_SNOW_USER: "c2FfYWlxOkExUVBANTV3MHJk",
        REACT_APP_TENANTID: "4b30ae91-543f-49b3-882e-ee7db9370a9c",
        REACT_APP_SHOW_SETTINGS: true,
    },
}

const ENV_ISLOCAL = window?.location?.host === 'localhost' ? true : false;
const ENV_HOST = ENV_ISLOCAL ? 'localhost:3000' : window?.location?.host;
const ENV_URL = process?.env?.REACT_APP_BASE || null;
const ENV_URL_CURRENT  = ENV_HOST || ENV_URL || 'amplifier.pwc.com';
const ENV_CONFIG = ENVS[ENV_URL_CURRENT] || ENVS['amplifier.pwc.com'];

export default ENV_CONFIG;

export {
    ENVS,
    ENV_HOST,
    ENV_URL,
    ENV_URL_CURRENT 
}