import React from "react";


const RuleCard = props => {
    const Icon = props.icon;
    return (
        <div className={props.className}>
            <Icon />
            <h3>{props.title}</h3>
            <p dangerouslySetInnerHTML={{__html: props.copy}}></p>


        </div>
    );
};

export default RuleCard;