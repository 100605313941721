import React from "react";
import "./QA.scss";
import { escape } from "lodash";
import { encodeHTML } from "../../../utils/reusableFunctions";

const QA = (props) => {
    let { question, id, answer } = props;

    if (answer && answer?.indexOf(process.env.REACT_APP_SNOW_FAQ + "/https") > -1) {
      answer = answer.replace(process.env.REACT_APP_SNOW_FAQ + "/", "");
    }

    answer = escape(answer);

    const sanitizedAnswer = encodeHTML(answer).replace(/style="([^"]*)"/gm, "");

    return (
        <div className="helptable--qa">
            <h2 className="h3 mb-10">{question}</h2>
            <label htmlFor={id}>Answer:</label>
            <div>{sanitizedAnswer}</div>
        </div>
    );
};

export default QA;
