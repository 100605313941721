import { BASE_URL } from "../services/process/env";
import { isStaticEnvironment } from "./reusableFunctions";

const jwt = require('jsonwebtoken');

const REDIRECT_AUTH_ENDPOINTS = {
    initiate: 'auth/sso/initiate',
    redirect: 'auth/sso/redirect',
    noredirect: '',
};

const REDIRECT_AUTH_TYPES = {
    INITIATE: 'initiate',
    REDIRECT: 'redirect',
    NOREDIRECT: 'noredirect',
};

/**
 * checkToken
 * @description Checks if the token cookie exists, and performs a redirect if necessary.
 * @param {boolean} doRedirect - True to do a redirection
 * @param {string} redirectType - The redirection type
 * @returns {boolean}
 */
const checkToken = (doRedirect = false, redirectType = 'dashboard') => {
    if(isStaticEnvironment()) return true; 

    const tokenCookie = getTokenFromCookies();

    if (tokenCookie) {
        return true;
    }

    const instance = getInstance();

    if (!instance) {
        return false;
    }

    const tokenUrl = getTokenFromUrl();

    if (tokenUrl) {
        if(setTokenCookie(instance, tokenUrl)) {
            window.history.replaceState(null, null, window.location.pathname);
        }
        return true;
    }

    const redirectEndpoint = REDIRECT_AUTH_TYPES.INITIATE;
    const redirectAuthUrl = getRedirectAuthUrl(redirectEndpoint, redirectType);

    if (redirectAuthUrl) {
        if (doRedirect) {
            window.location.replace(redirectAuthUrl);
        }
        return false;
    }    
    
    return true;
};

/**
 * decodeToken
 * @description Helper function to decode JWT token
 * @param {string} token The JWT token
 * @returns {object|null}
 */
const decodeToken = (token) => {
    if (!token) return null;

    try {
        const decodedToken = jwt.decode(
            token.replace('Hasta-la-vista', '')
        );
        
        if (decodedToken && decodedToken.referrerUrl && !sessionStorage.getItem('referrerUrl')) {
            sessionStorage.setItem('referrerUrl', decodedToken.referrerUrl);
        }
        
        return decodedToken;
    } catch (error) {
        return null;
    }
};

/**
 * getCookieValue
 * @description Retrieves the value of a cookie by its name.
 * @param {string} cookieName - The name of the cookie.
 * @returns {string|null} The value of the cookie, or null if not found.
 */
const getCookieValue = (cookieName) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();

        if (cookie.startsWith(cookieName + '=')) {
            return cookie.substring(cookieName.length + 1);
        }
    }

    return null;
};

/**
 * getInstance 
 * @description Retrieves the instance needed to get token from cookie
 * @returns {string|null} Instance or also called refererUrl / ref
 */
const getInstance = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size >= 1) {
        const queryParamsRef = queryParams.get('referrerUrl') || queryParams.get('ref') || null;
        if (queryParamsRef) {
            sessionStorage.setItem('referrerUrl', queryParamsRef);
            return queryParamsRef;
        }
    }

    const sessionStorageRef = sessionStorage.getItem('referrerUrl');
    if (sessionStorageRef) {
        return sessionStorageRef;
    }

    return null;
};

/**
 * getToken
 * @description Get the token string or null
 * @returns {string|null}
 */
const getToken = () => getTokenFromUrl() || getTokenFromCookies() || null;

/**
 * getTokenFromCookies
 * @description Retrieves the token from cookies using a stored referrer URL.
 * @returns {string|boolean|null} The token value, or false if not found, or null if the referrer URL is missing.
 */
const getTokenFromCookies = () => {
    const instance = getInstance();

    if (instance) {
        return getCookieValue(instance);
    }

    return false;
};

/**
 * getTokenFromUrl
 * @description Get token from url, could be needed for old behavior
 * @returns {boolean}
 */
const getTokenFromUrl = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const queryParamsToken = queryParams.get('t') || queryParams.get('token') || queryParams.get('access_token') || queryParams.get('partial_token') || null;
    return queryParamsToken;
};

/**
 * getRedirectAuthUrl
 * @description Constructs the redirect URL for authentication based on the referrer URL the referrerUrl
 * is needed in order to get the token cookie depending on the instance.
 * @param {string|null} endpoint - The endpoint
 * @param {string|null} redirectType - The redirect type
 * @returns {string|null} The redirect URL for authentication, or null if the referrer URL is missing.
 */
const getRedirectAuthUrl = (endpoint = 'initiate', redirectType = 'dashboard') => {
    const INSTNACE = getInstance();

    let redirectUrl = null;

    if (INSTNACE && endpoint) {   
        redirectUrl = `${BASE_URL}/${REDIRECT_AUTH_ENDPOINTS[endpoint]}?referrerUrl=${INSTNACE}&redirType=${redirectType}`;
    }

    return redirectUrl;
};

/**
 * makeHashObject (deprecated)
 * @param {string} windowHash
 * @returns {object}
 */
const makeHashObject = (windowHash) => {
    const hash = windowHash.startsWith('#?')
        ? windowHash.substring(2)
        : windowHash.startsWith('#') || windowHash.startsWith('?')
        ? windowHash.substring(1)
        : windowHash;

    const params = {};

    hash.split("&").map(hk => {
        const temp = hk.split("=");
        const key = decodeURIComponent(temp[0]);
        const value = decodeURIComponent(temp[1]);

        if (key) {
            params[key] = value;
        }

        return hk;
    });

    return params;
};

/**
 * setTokenCookie
 * @description Function to set the token in the cookies if its not present
 * @param {string} instance 
 * @param {string} token 
 * @returns 
 */
const setTokenCookie = (instance, token) => {
    if (!instance || !token) return false;
    
    try {
        const maxAge = "1800"; // Expires in 30 minutes (1800 seconds)
        const cookieOptions = `max-age=${maxAge}; path=/; SameSite=Strict; Secure`;
                
        document.cookie = `${instance}=${token}; ${cookieOptions}`;

        return true;
    } catch (error) {
        return false;
    }
};

export {
    REDIRECT_AUTH_TYPES,
    REDIRECT_AUTH_ENDPOINTS,
    checkToken,
    decodeToken,
    getCookieValue,
    getInstance,
    getRedirectAuthUrl,
    getToken,
    getTokenFromCookies,
    getTokenFromUrl,
    makeHashObject,
    setTokenCookie,
};