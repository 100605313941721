import "./MyShortcuts.scss";

import React from "react";
import { isMobile } from "react-device-detect";

import { APIContext, httpVerb } from "../../../context/APIProvider";
import { createShortcuts } from "../../../utils/shortcutFunctions";
import { isStaticEnvironment } from "../../../utils/reusableFunctions";
import { loadGuide } from "../../../utils/guideFunctions";
import { SHORTCUTS_MY } from "../../../services/endpoints/endpoints";
import { shortcutsCopy } from "../../../utils/cms";
import MockShortcutsMy from "../../../mocks/shortcuts-my";
import ModalWindow from "../../ModalWindow";
import ModuleError from "../../ModuleError";
import ShortcutsComingSoon from "../ShortcutsComingSoon";

export default class MyShortcuts extends React.Component {
    static contextType = APIContext;

    constructor(props) {
        super(props);

        this.state = {
            data: new Array(6).fill({
                name: "",
                points: 0,
            }),
            error: false,
            modalOpen: false,
            modalTitle: "",
        };

        this.triggerModal = this.triggerModal.bind(this);
    }

    componentDidMount() {
        this.getMyShortcuts();
    }

    getMyShortcuts = () => {
        const t = this;

        if (isStaticEnvironment()) {
            t.setState({ error: false, data: MockShortcutsMy });

            return;
        }

        t.context
            .httpRequest(SHORTCUTS_MY, httpVerb.GET)
            .then((res) => {
                if (!res || (res.success && res.success === "false")) {
                    t.setState({ error: true });

                    return;
                }

                t.setState({ error: false, data: res });
            })
            .catch((err) => {
                t.setState({ error: true });

                return err;
            });
    };

    triggerModal = (displayValue = "") => {
        this.setState({
            modalShortcutName: displayValue,
            modalOpen: !this.state.modalOpen,
        });
    };

    infoGuide = (props) => {
        props.parent = this;
        loadGuide(props);
    };

    render() {
        return (
            <div className="myshortcuts">
                {this.state.modalOpen && (
                    <ModalWindow
                        onEscapeModal={() => {
                            this.setState({ modalOpen: false });
                        }}
                    >
                        <ShortcutsComingSoon
                            percentage={this.state.loadpercent}
                            onClick={this.triggerModal}
                            shortcutName={this.state.modalTitle}
                        />
                    </ModalWindow>
                )}
                <div
                    className={
                        isMobile ? "myshortcuts-m" : "shortcuts--myshortcuts"
                    }
                >
                    <h2>{shortcutsCopy.header}</h2>
                    {this.state.error ? (
                        <ModuleError onRefresh={this.getMyShortcuts} />
                    ) : (
                        <>
                            {Array.isArray(this.state.data) &&
                            this.state.data.length > 0 ? (
                                <table>
                                    <tbody>
                                        {createShortcuts(
                                            this.state.data,
                                            this.triggerModal,
                                            this.infoGuide
                                        )}
                                    </tbody>
                                </table>
                            ) : (
                                <>{/* Placeholder for no data*/}</>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}
