import React from "react";
import "./MobileCookie.scss";
import MobileHead from "../MobileHead";
import Cookie from "../../cms/Cookie.js";

class MobileCookie extends React.Component {
    render() {
        return (
            <>
                <MobileHead />
                <div className="m-legal-wrapper legal-wrapper">
                    <Cookie />
                </div>
            </>
        );
    }
}

export default MobileCookie;
