import "./GuidedLearningSelect.scss";

import React from "react";

import { postMessage } from "../../../utils/reusableFunctions";

export default class GuidedLearningSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMessage: "please make selection from list above",
            class: "padd_1",
        };
    }

    onSet = () => {
        const t = this;
        let radios = document.getElementsByName("learning_guide");
        let value = null;

        for (let i = 0; i < radios.length; i++) {
            if (radios[i].checked) {
                value = radios[i].value;
                t.setState({
                    showMessage: `setting ${value}`,
                    class: "padd_3",
                });
                switch (value) {
                    case "default":
                        postMessage("guided_learning:delete_cookie");
                        break;
                    case "oracle":
                        postMessage("guided_learning:oracle");
                        break;
                    case "jumpseat":
                        postMessage("guided_learning:jumpseat");
                        break;
                    default:
                        postMessage("guided_learning:delete_cookie");
                        break;
                }
                if (value) {
                    t.handleTimeout(value);
                }
            }
        }
        if (value == null) {
            t.setState({
                showMessage: "no guide option has been selected",
                class: "padd_1_alert",
            });
        }
    };

    handleTimeout = (value) => {
        if (value) {
            this.setState({
                showMessage: `${value} guided learning has been set`,
                class: "padd_4",
            });
        }
    };

    onClick = () => {
        this.setState({
            showMessage: "Set Guided Learning Tool",
            class: "padd_1",
        });
    };

    render() {
        let t = this;

        return (
            <React.Fragment>
                <div className="settings">
                    <h2>Select From Available Guided Learning Setups</h2>
                    <div>
                        <div className="guided_l">
                            <input
                                tabIndex="0"
                                id="default"
                                name="learning_guide"
                                type="radio"
                                value="default"
                                onClick={() => {
                                    t.onClick();
                                }}
                            />
                            <label>default database configuration</label>
                        </div>
                        <div className="guided_l">
                            <input
                                tabIndex="1"
                                id="oracle"
                                name="learning_guide"
                                type="radio"
                                value="oracle"
                                onClick={() => {
                                    t.onClick();
                                }}
                            />
                            <label>oracle</label>
                        </div>
                        <div className="guided_l">
                            <input
                                tabIndex="2"
                                id="jumpseat"
                                name="learning_guide"
                                type="radio"
                                value="jumpseat"
                                onClick={() => {
                                    t.onClick();
                                }}
                            />
                            <label>jumpseat</label>
                        </div>
                    </div>
                    <div className={t.state.class}>
                        <button
                            className="btn btn__light btn__mt"
                            onClick={() => {
                                t.onSet();
                            }}
                        >
                            <span>{t.state.showMessage}</span>
                        </button>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
