import "./Tooltip.scss";

import React from "react";

import { colorRange } from "../../../utils/cms";

const Tooltip = (props) => {
    const {
        tooltip_left,
        tooltip_top,
        current_selected,
        tooltip_display,
        tooltip_text,
        tooltip_type,
    } = props;

    return (
        <div
            id="amplifier-tooltip"
            className="tooltip"
            style={{
                left: `${
                    tooltip_left?.left < 170
                        ? tooltip_left?.left
                        : tooltip_left?.left - 90
                }px`,
                top: `${
                    tooltip_top?.top - 10 < -5
                        ? tooltip_top?.top + 60
                        : tooltip_top?.top - 10
                }px`,
                display: tooltip_display,
            }}
        >
            <div className="key arrow_box">
                <span
                    className="dot"
                    style={{ backgroundColor: colorRange[current_selected] }}
                />
                <span className="key-label" id="amplifier-tooltipTitle">
                    {tooltip_type?.toUpperCase()}
                </span>
                <p id="amplifier-tooltipValues">{tooltip_text}</p>
                <span
                    className={"tip tip--bottom"}
                    style={{
                        left: `${
                            tooltip_left?.left < 170
                                ? "5"
                                : tooltip_left?.xoffset - 5
                        }px`,
                        top: tooltip_top?.top - 10 < -5 ? "-5px" : null,
                        transform:
                            tooltip_top?.top - 10 < -5 ? "rotate(135deg)" : null,
                    }}
                />
            </div>
        </div>
    );
};

export default Tooltip;
