import React, { Component } from "react";

import { CheckActive, CheckInactive } from "../../assets/icons/icons";

class Checkbox extends Component {
    render() {
        const { selected = false, onClick = () => {} } = this.props;

        return (
            <div className="m-r-5 inline-b sub" onClick={onClick}>
                {selected ? <CheckActive /> : <CheckInactive />}
            </div>
        );
    }
}

export default Checkbox;
