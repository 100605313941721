import moment from "moment";
import { getStorage } from "../services/window/localStorage";
import { hasPermissions } from "./Permissions";

/**
 * capitalizeFirstLetter
 * @description Capitlize the first letter of a string
 * @param {string} string
 * @returns {string}
 */
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * encodeHTML
 * @description Function to encode HTML string
 * @param {string} string
 * @returns {string}
 */
const encodeHTML = (string) => {
    return string
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#x27;")
        .replace(/\//g, "&#x2F;");
};

/**
 * formatChallengeDates
 * @description Format the dates for challenges
 * @param {string} startDate
 * @param {string} endDate
 * @returns {object}
 */
const formatChallengeDates = (startDate, endDate, todayDate = new Date()) => {
    if (!startDate || !endDate) return {};

    startDate = moment(startDate, "DD-MM-YYYY");
    endDate = moment(endDate, "DD-MM-YYYY");
    todayDate = moment(todayDate, "DD-MM-YYYY");

    const startDateYear = startDate.get("year");
    const endDateYear = endDate.get("year");
    const startDateFormat = startDate.format(
        startDateYear === endDateYear ? "MMM D" : "MMM D YYYY",
    );
    const endDateFormat = endDate.format(
        startDateYear === endDateYear ? "MMM D" : "MMM D YYYY",
    );
    const today = moment(todayDate);
    const dateDiff = endDate.diff(today, "hours");
    const daysLeft =
        dateDiff < 0
            ? dateDiff > -24
                ? `LESS THAN 1 DAY LEFT`
                : `0 DAY LEFT`
            : `${Math.ceil((dateDiff + 24) / 24)} DAYS LEFT`;

    return {
        startDateFormat,
        endDateFormat,
        daysLeft,
    };
};

/**
 * guideTrigger
 * @description Used to send a postMessage to trigger a guide
 * @param {object} guideObject
 * @returns {window.top.message}
 */
const guideTrigger = (guideObject) => {
    postMessage(guideObject);
    return postMessage("back");
};

/**
 * isAdmin
 * @description Check the permissions of the user
 * @param {array} permissions
 * @returns {boolean}
 */
const isAdmin = (permissions = null) => {
    let storagePermissions = null;

    if (getStorage("UserInfo")) {
        storagePermissions =
            JSON.parse(getStorage("UserInfo"))?.permissions || null;
    }

    const userPermissions = permissions || storagePermissions;

    return (
        userPermissions &&
        (hasPermissions(userPermissions, "adminPortal") ||
            hasPermissions(userPermissions, "adminAnalytics") ||
            hasPermissions(userPermissions, "adminLeaderboard"))
    );
};

/**
 * isAmpDebug
 * @description Checker to see amp debug var
 * @returns {boolean}
 */
const isAmpDebug = (param = "static_data") => {
    try {
        const urlParams = new URLSearchParams(window.location.search);
        const ampDebug = urlParams.get("_amp_debug");
        const value = ampDebug.indexOf(param) > -1 ? true : false;

        return value;
    } catch (e) {
        return false;
    }
};

/**
 * isAmpHelloWorld
 * @description Checker to see amp hello world var
 * @returns {boolean}
 */
const isAmpHelloWorld = () => {
    return isAmpDebug("hello_world");
};

/**
 * isInIframe
 * @description Checks if the app is in an iframe
 * @returns {boolean}
 */
const isInIframe = () => {
    return window.self !== window.top;
};

/**
 * isStaticEnvironment
 * @description Used for local testing using mock data
 * @returns {boolean}
 */
const isStaticEnvironment = () => {
    return (
        isAmpHelloWorld() ||
        isAmpDebug() ||
        window.location.hostname.indexOf("local") > -1
    );
};

/**
 * postMessage
 * @description A wrapper to window.top.postMessage with an origin
 * @param {Any} message
 * @returns {boolean}
 */
const postMessage = (message) => {
    try {
        const targetOrigin = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;

        // Note: Implement a better way to do this with env variables that get the parent
        // domain as document.referrer can be different when login in and coming from auth-service domain
        window.top.postMessage(message, "*" || targetOrigin);
        
        return true;
    } catch (error) {
        return false;
    }
};

export {
    capitalizeFirstLetter,
    encodeHTML,
    formatChallengeDates,
    guideTrigger,
    isAdmin,
    isAmpDebug,
    isAmpHelloWorld,
    isInIframe,
    isStaticEnvironment,
    postMessage,
};
