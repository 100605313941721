import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { AnimatedSwitch } from "react-router-transition";
import { ToastContainer, toast } from "react-toastify";
import cssVars from "css-vars-ponyfill";

import { GET_NOTIFICATIONS } from "./services/endpoints/endpoints";
import Legal from "./views/Legal/Legal";
import { Rules, Close, Link } from "./assets/icons/icons";
import SidebarMenu from "./components/SidebarMenu";
import ModalWindow from "./components/ModalWindow/ModalWindow";
import NewUser from "./components/NewUser/ModalContent/ModalContent";
import NewCampaign from "./components/NewCampaign/NewCampaign";
import { APIContext, httpVerb } from "./context/APIProvider";
import MobileHome from "./components/MobileHome";
import MobileMyActions from "./components/MobileMyActions";
import MobileAllActions from "./components/MobileAllActions";
import MobileLeaderboard from "./components/MobileLeaderboard";
import MobileScoringRules from "./components/MobileScoringRules";
import MobileHelp from "./components/MobileHelp";
import MobileLegal from "./components/MobileLegal";
import ScrollToTop from "./components/ScrollToTop";
import MobilePrivacyPolicy from "./components/MobilePrivacyPolicy";
import MobileCookie from "./components/MobileCookie";
import MobileTerms from "./components/MobileTerms";
import MobileAbout from "./components/MobileAbout";
import MobileVersion from "./components/MobileVersion";
import Notification from "./components/Notification";
import { checkIsNoscoreRedirect } from "./utils/Permissions";
import { isStaticEnvironment } from "./utils/reusableFunctions";

class AppContent extends Component {
    static contextType = APIContext;
    constructor(props) {
        super(props);

        this.context = {};
        this.notiPing = null;
        this.notifications = {};
        this.state = {
            guidedLearningValue: "",
            legalSection: 0,
            selection: 0,
            showError: true,
            showGuidedLearning: false,
            showLegal: false,
        };
    }

    componentDidMount() {
        cssVars(); // Call polyfill using defaults

        if (isMobile) document.body.style.overflow = "auto";

        const timeOut = setTimeout(() => {
            this.checkNotification();
        }, 1500);

        if (!this.notiPing) {
            this.notiPing = setInterval(() => {
                clearTimeout(timeOut);
                this.checkNotification();
            }, 15000);
        }
    }

    componentWillUnmount() {
        if (this.notiPing) {
            clearInterval(this.notiPing);
        }
    }

    // Helper functions
    //#region
    onSelectionChange = (selection) => {
        this.setState({ selection });
    };

    guidedLearningSelection = () => {
        this.setState({ showGuidedLearning: true });
    };

    changeSelection = (newSelection) => {
        this.setState({
            selection: newSelection,
        });
    };

    checkNotification = () => {
        if (isStaticEnvironment()) return;

        const t = this;
        const { isNewUser = false } = t.context || {};

        if (isNewUser) return;

        try {
            t.context
                .httpRequest(GET_NOTIFICATIONS, httpVerb.GET)
                .then((res) => {
                    if (!res || (res.success && res.success === "false")) {
                        return;
                    }

                    const notiString = localStorage.getItem("notifications");
                    const notifications = notiString
                        ? JSON.parse(notiString)
                        : {};
                    const { userId } = t.context;

                    res.forEach((noti) => {
                        if (
                            !notifications[`${noti.Id}_${userId}`] &&
                            !t.notifications[`${noti.Id}_${userId}`]
                        ) {
                            t.notifications[`${noti.Id}_${userId}`] =
                                noti.message;
                            toast(<Notification message={noti.message} />, {
                                className: "notify-area",
                                onClose: () => {
                                    notifications[`${noti.Id}_${userId}`] =
                                        noti.message;
                                    localStorage.setItem(
                                        "notifications",
                                        JSON.stringify(notifications)
                                    );
                                },
                            });
                        }
                    });
                })
                .catch((error) => {
                    // console.log(error)
                });
        } catch (error) {
            // console.log(error);
        }
    };

    showLegal = () => {
        this.setState({ showLegal: true });
    };

    setLegalSection = (number) => {
        this.setState({ legalSection: number });
    };

    closeError = () => {
        this.setState({ showError: false });
    };
    //#endregion

    render() {
        const { showLegal, selection } = this.state;

        const {
            isNoscore,
            isNewUser = false,
            isNewCampaign = false,
            prevCampaign = {},
            campaign = {},
            resetCampaignEndDate,
            isTermsAndConditionsAccepted = true,
            appVersion = "",
            appVersionDate = "",
        } = this.context || {};

        const route = window.location.pathname;

        if (checkIsNoscoreRedirect(isNoscore, route)) {
            return <Redirect to="/" />;
        }

        return (
            <>
                {(isNewCampaign || !isTermsAndConditionsAccepted) && (
                    <NewCampaign
                        isTermsAndConditionsAccepted={
                            isTermsAndConditionsAccepted
                        }
                        showLegal={this.showLegal}
                        setTermsAndConditions={
                            this.context.setTermsAndConditions
                        }
                        setLegalSection={this.setLegalSection}
                        prevCampaign={prevCampaign}
                        currCampaign={campaign}
                        close={resetCampaignEndDate}
                    />
                )}

                {showLegal && (
                    <Legal
                        setLegalSection={this.state.legalSection}
                        supportClick={this.changeSelection}
                        version={appVersion}
                        versionDate={appVersionDate}
                        close={() => this.setState({ showLegal: false })}
                    />
                )}

                {(!showLegal || !isNewCampaign) && (
                    <ToastContainer
                        position="top-right"
                        autoClose={false}
                        hideProgressBar={true}
                        newestOnTop={false}
                        closeOnClick={true}
                        closeButton={<Close />}
                        rtl={false}
                        pauseOnVisibilityChange={true}
                        pauseOnHover={true}
                        className="notify-container"
                    />
                )}

                {isNewUser && (
                    <ModalWindow>
                        <NewUser />
                    </ModalWindow>
                )}

                {!showLegal && isTermsAndConditionsAccepted && (
                    <BrowserView>
                        <SidebarMenu
                            admin={{
                                adminPortal: true,
                                adminElement: {
                                    icon: Rules,
                                    navText: "Behavior Activator",
                                    navRoute: "/admin",
                                },
                                guidedLearningElement: {
                                    icon: Link,
                                    navText: "Settings",
                                    navRoute: "/guided_learning",
                                },
                                guidedLearningSelection:
                                    this.guidedLearningSelection,
                            }}
                            showLegal={this.showLegal}
                            selection={selection}
                            onSelectionChange={this.onSelectionChange}
                        />
                    </BrowserView>
                )}

                <MobileView className="mobile-view">
                    <ScrollToTop />
                    <AnimatedSwitch
                        atEnter={{ opacity: 0 }}
                        atLeave={{ opacity: 0 }}
                        atActive={{ opacity: 1 }}
                        className="switch-wrapper"
                    >
                        <Route
                            path="/"
                            exact={true}
                            name="Home"
                            component={MobileHome}
                        />
                        <Route
                            path="/my-actions"
                            exact={true}
                            name="My Actions"
                            component={MobileMyActions}
                        />
                        <Route
                            path="/all-actions"
                            exact={true}
                            name="All Actions"
                            component={MobileAllActions}
                        />
                        <Route
                            path="/leaderboard"
                            exact={true}
                            name="Leaderboard"
                            component={MobileLeaderboard}
                        />
                        <Route
                            path="/scroing-rules"
                            exact={true}
                            name="Scroing Rules"
                            component={MobileScoringRules}
                        />
                        <Route
                            path="/amplifier-help"
                            exact={true}
                            name="Amplifier Help"
                            component={MobileHelp}
                        />
                        <Route
                            path="/legal"
                            exact={true}
                            name="Legal"
                            component={MobileLegal}
                        />
                        <Route
                            path="/legal/privacy-policy"
                            exact={true}
                            name="Privacy Policy"
                            component={MobilePrivacyPolicy}
                        />
                        <Route
                            path="/legal/cookie-statement"
                            exact={true}
                            name="Cookie Statement"
                            component={MobileCookie}
                        />
                        <Route
                            path="/legal/terms-of-service"
                            exact={true}
                            name="Terms of Service"
                            component={MobileTerms}
                        />
                        <Route
                            path="/legal/about-us"
                            exact={true}
                            name="About Us"
                            component={MobileAbout}
                        />
                        <Route
                            path="/legal/version"
                            exact={true}
                            name="Version"
                            component={MobileVersion}
                        />
                    </AnimatedSwitch>
                </MobileView>
            </>
        );
    }
}

export default AppContent;