import "./SidebarMenu.scss";

import React from "react";
import { Redirect } from "react-router-dom";

import Navbar from "../Navigation/Navbar";
import { Why, SetSquare, SpeechBubble } from "../../assets/icons/icons";
import { navbarCopy } from "../../utils/cms";
import NavResult from "../Navigation/NavResult";
import { ADMIN_URL } from "../../services/process/env";
import { APIContext } from "../../context/APIProvider";
import { isAdmin } from "../../utils/reusableFunctions";
import { getStorage } from "../../services/window/localStorage";
import ErrorModal from "../Error/ErrorModal";
import { getInstance } from "../../utils/Cookies";

class SidebarMenu extends React.Component {
    static contextType = APIContext;

    constructor(props) {
        super(props);
        this.state = {
            selection: 0,
            initial: false,
            showError: true,
        };
        this.changeSelection = this.changeSelection.bind(this);
        this.resetSelection = this.resetSelection.bind(this);
        this.messagecb = this.messagecb.bind(this);
    }

    componentDidMount() {
        this.setState({ selection: this.props.selection });
        window.addEventListener("message", this.messagecb);
    }

    // Helper functions
    //#region
    messagecb = (event) => {
        if (event.data === "back") {
            this.resetSelection();
        }
    };

    isNoscore = () => {
        let isNoscore = false;
        const userInfo = getStorage("UserInfo");
        if (userInfo) {
            const userInfoObj = JSON.parse(userInfo);
            isNoscore =
                userInfoObj &&
                userInfoObj.isNoscore &&
                userInfoObj.isNoscore === "Y"
                    ? true
                    : false;
        }
        return isNoscore;
    };

    closeError = () => {
        this.setState({ showError: false });
    };

    changeSelection = (newSelection) => {
        this.props.onSelectionChange();
        let win;

        if (newSelection === "admin") {
            const instance = getInstance();
            win = window.open(`${ADMIN_URL}?ref=${instance}`, "_blank");
            win.focus();
            return;
        }

        this.setState({
            selection: newSelection,
            initial: false,
        });

        this.props.onSelectionChange(newSelection);
    };

    resetSelection = () => {
        this.setState({
            selection: 0,
            initial: false,
        });
    };
    //#endregion

    render() {
        return (
            <APIContext.Consumer>
                {(datacontext) => (
                    <div
                        className={
                            this.props.className
                                ? this.props.className + " sidebar"
                                : "sidebar"
                        }
                    >
                        <Navbar
                            isAdmin={isAdmin()}
                            title={navbarCopy.title}
                            canGoBack={true}
                            navigateback={this.resetSelection}
                            navarray={
                                datacontext?.isNoscore === "N"
                                    ? [
                                          {
                                              icon: SetSquare,
                                              navText: navbarCopy.button1,
                                              navRoute: navbarCopy.route1,
                                              analyticsTag: "home-analytics",
                                          },
                                          {
                                              icon: Why,
                                              navText: navbarCopy.button2,
                                              navRoute: navbarCopy.route2,
                                              analyticsTag:
                                                  "scoringrules-analytics",
                                          },
                                          ...(datacontext?.enterprisePlatformName !==
                                          "Oracle HCM"
                                              ? [
                                                    {
                                                        icon: SpeechBubble,
                                                        navText:
                                                            navbarCopy.button3,
                                                        navRoute:
                                                            navbarCopy.route3,
                                                        analyticsTag:
                                                            "amplifierhelp-analytics",
                                                    },
                                                ]
                                              : []),
                                      ]
                                    : [
                                          {
                                              icon: Why,
                                              navText: navbarCopy.button2,
                                              navRoute: navbarCopy.route2,
                                              analyticsTag:
                                                  "scoringrules-analytics",
                                          },
                                          ...(datacontext?.enterprisePlatformName !==
                                          "Oracle HCM"
                                              ? [
                                                    {
                                                        icon: SpeechBubble,
                                                        navText:
                                                            navbarCopy.button3,
                                                        navRoute:
                                                            navbarCopy.route3,
                                                        analyticsTag:
                                                            "amplifierhelp-analytics",
                                                    },
                                                ]
                                              : []),
                                      ]
                            }
                            selection={this.state.selection}
                            changeselection={this.changeSelection}
                            adminPortal={this.props.admin.adminPortal}
                            adminElement={this.props.admin.adminElement}
                            guidedLearningElement={
                                this.props.admin.guidedLearningElement
                            }
                            guidedLearningSelection={
                                this.props.admin.guidedLearningSelection
                            }
                            showLegal={this.props.showLegal}
                        />

                        <NavResult selection={this.state.selection} />

                        {datacontext?.isNoscore === "Y" && (
                            <>
                                <Redirect to="/rules" />
                                <ErrorModal
                                    error={this.state.showError}
                                    errorMessage="You are on the 'Do Not Score' list for this Challenge. Contact your Amplifier Admin for more information."
                                    errorReset={this.closeError}
                                />
                            </>
                        )}
                    </div>
                )}
            </APIContext.Consumer>
        );
    }
}

export default SidebarMenu;
