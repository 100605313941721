export default [
    {
        id: 3,
        name: "Create Absence Record",
        description: `When an employee creates absence records, they should be rewarded with points`,
        points: 2,
        frequency: "24:00:00",
        guideId: 3,
        guideValue: "rua8s8ha",
        guideType: "Oracle Guided Learning",
    },
    {
        id: 4,
        name: "Create Feedback Note",
        description: `When the user creates a new person note requesting feedback from a peer`,
        points: 10,
        frequency: null,
        guideId: 3,
        guideValue: null,
        guideType: "Oracle Guided Learning",
    },
    {
        id: 14,
        name: "Register for Learning Course",
        description: `When the  users register to a learning course they should be rewarded with points`,
        points: 5,
        frequency: null,
        guideId: 3,
        guideValue: null,
        guideType: "Oracle Guided Learning",
    },
    {
        id: 29,
        name: "Apply to Internal Job Posting",
        description: `When the employee applies to a job through the internal posting site they should be rewarded with points.`,
        points: 10,
        frequency: null,
        guideId: 3,
        guideValue: null,
        guideType: "Oracle Guided Learning",
    },
    {
        id: 22,
        name: "Approve Absence within 48 Hours",
        description: `Encourage managers to approve absence requests quickly. Award is given only when the approver is the employee's manager.`,
        points: 10,
        frequency: null,
        guideId: 3,
        guideValue: null,
        guideType: "Oracle Guided Learning",
    },
    {
        id: 81,
        name: "Approve AP Invoices",
        description: `When the User approves a AP Invoice`,
        points: 5,
        frequency: null,
        guideId: null,
        guideValue: null,
        guideType: null,
    },
];
