import React from "react";
import "./MobileMyActions.scss";
import MobileHead from "../MobileHead";
import MyShortcuts from "../../components/Shortcuts/MyShortcuts";

class MobileMyActions extends React.Component {
    render() {
        return (
            <>
                <MobileHead />
                <div className='mobile-view shortcuts-m'>
                    <MyShortcuts></MyShortcuts>
                </div>
            </>
        );
    }
}

export default MobileMyActions;
