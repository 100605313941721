//Color Range used by Piechart/key
const colorRange = [
    "#42A5F5",
    "#1565C0",
    "#26C6DA",
    "#00838F",
    "#66BB6A",
    "#2E7D32",
    "#FB8C00",
    "#E65100",
    "#795548",
    "#4E342E",
    "#78909C",
    "#37474F",
    "#757575",
    "#212121",
    "#EC407A",
    "#AD1457",
    "#AB47BC",
    "#6A1B9A",
    "#5C6BC0",
    "#283593"
];

//Copy for navbar
const navbarCopy = {
    title: "Amplifier",
    button1: "Home",
    route1: "/",
    button2: "Current Challenge",
    route2: "/rules",
    button3: "Amplifier Help",
    route3: "/help",
    button4: "Why Salesforce",
    route4: "/whyanalytics",
    button5: "Active Analytics",
    route5: "/analytics",
    button6: "Leaderboard",
    route6: "/leaderboard",
    button7: "Settings",
    route7: "/guided_learning",
    persistantTitle: "Amplifier"
};

//Copy for navbar
const navbarAdmin = {
    title: "Behavior Activator",
    button1: "Challenge Dynamics",
    route1: "/",
    button2: "Role Actions",
    route2: "/actions",
    button3: "Rules Description",
    route3: "/rules-description",
    button4: "Access Settings",
    route4: "/settings",
    button5: "No Score List",
    route5: "/no-score",
    button6: "Active Analytics",
    route6: "/analytics",
    button7: "Leaderboard",
    route7: "/leaderboard",
    persistantTitle: "Amplifier"
};

//Copy for Dial, Leaderboard, and Piechart
const leaderboardCopy = {
    pointsSubHeader: "Smart Points",
    challengePointsSubHeader: "Current Challenge",
    leaderboardTitle: "Leaderboard",
    leaderboardSubheader: "Based on your role",
    leaderboardUsers: "Users",
    leaderboardUpdate: "Last updated:",
    pieHeader: "Behaviors"
};

//Copy for Myshortcuts and Shortcuts
const shortcutsCopy = {
    header: "My Actions",
    inputPlaceholder: "Search All Challenge Actions..."
};

//Copy for Rules Headers
const rulesCopy = {
    ruleTitle: "Current Challenge",
    ruleDesc: "Description of Current Challenge",
    // ruleInstruction1Header: "Challenge period duration",
    ruleInstruction1Header: "Challenge period & goals",
    // ruleInstruction1Copy: "The game length is a <> day challenge period. For this challenge period, your goal is to reach [] points by completing the Challenge Actions listed below. Your points will reset on {}.",
    ruleInstruction1Copy: "The challenge duration is <span class='bold'><days></span> days, your goal is to reach <span class='bold'>[score]</span> points by completing the actions listed below. Your points will reset at the end of the challenge <span class='bold'>{end}</span>", //{duration}
    ruleInstruction2Header: "Key activities",
    ruleInstruction2Copy: "Each behavior listed below has a point value which is based upon your role in the company. In addition to the point value, the frequency which you can receive points for each action is also specified below.",
    ruleInstruction3Header: "Leaderboard rules",
    ruleInstruction3Copy: "The leaderboard shows your position in relation to other people in the company that share your role. The arrow shows if you have trended up or down since your last login. The leaderboard will be reset at the end of each challenge period."
};

//Copy for Rules Table
const rulesTableCopy = {
    rulesTableHeader: "How to Earn Points",
    rulesTableCol1: "Challenge Actions",
    rulesTableCol2: "score",
    rulesTableCol3: "frequency"
};

//Copy for Help page
const helpCopy = {
    inputPlaceholder: "What can we help you with?...",
    inputClear: "Clear",
    searchHeader: "results",
    searchItems: "items",
    helpTableHeader: "FAQs",
    submitQuestion: "Submit your question",
    submitText: "Don't see what you are looking for? "
};

export {
    colorRange,
    navbarCopy,
    leaderboardCopy,
    shortcutsCopy,
    rulesCopy,
    rulesTableCopy,
    helpCopy,
    navbarAdmin
};