import React from "react";
/**
 * ChatPlaceholder
 * @description Component helper
 * @param {object} props 
 * @returns {JSX.Element}
 */
const ChatPlaceholder = (props) => (
    <div className={props.className}>
        <div style={{ height: "100%", width: "100%", position: "relative" }}>
            <span
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            >
                {props.userName}
            </span>
        </div>
    </div>
);

/**
 * getNameInitials
 * @description Takes a string as input and returns the initials of the words in the string.
 * @param {string} inputString - The input string from which to extract initials.
 * @returns {string} - A string containing the initials of the words in the input string, in uppercase.
 */
const getNameInitials = (string) => {
    try {
        let initials = string.match(/\b\w/g) || [];
        initials = (
            (initials.shift() || "") + (initials.pop() || "")
        ).toUpperCase();
        return initials;
    } catch {
        return "";
    }
};

export {
    ChatPlaceholder,
    getNameInitials,
}